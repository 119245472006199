import { HelperService } from '../helper.service';
import { Attachment } from './attachment';
import { LicensePlan } from './license-plan';
import { LoadFactorType } from './load-factor-type';
import { State } from './state';
import { SupplierClass } from './supplier-class';
import { SupplierCommissionRule } from './supplier-commission-rules';
import { SupplierEsignFont } from './supplier-esign-font';
import { SupplierMarketAuctionCompetitiveReport } from './supplier-market-auction-competitive-report';
import { SupplierMarketAuctionRankReport } from './supplier-market-auction-rank-report';
import { SupplierMonthlyAuctionPerformanceStats } from './supplier-monthly-auction-performance-stats';
import { SupplierRateSetting } from './supplier-rate-setting';
import { SupplierReinstatementType } from './supplier-reinstatement-type';
import { SupplierSecurityQuestion } from './supplier-security-question';
import { SupplierServiceType } from './supplier-service-type';
import { SwitchType } from './switch-type';
import { User } from './user';

export class Supplier {

	public supplierId: string;
	public contactFname: string;
	public contactLname: string;
	public contactMiddle: string;
	public hasAPI: boolean;
	public name: string;
	public serviceTypeId: string;
	public isActive: boolean;
	public stateId: string;
	public id: string;
	public logo: string;
	public address1: string;
	public address2: string;
	public city: string;
	public zipCode: string;
	public phone: string;
	public phone2: string;
	public phone3: string;
	public fax: string;
	public mobile: string;
	public email: string;
	public website: string;
	public puct: string;
	public underwriterId: string;
	public bbbRating: number;
	public parentId: string;
	public maxNumLocations: number;
	public supplierReinstatementTypeId: number;
	public reinstatementFormAttachmentId: string;
	public gasBufferMargin: number;
	public electricBufferMargin: number;
	public personalGuaranteeAttachmentId: string;
	public businessGuaranteeAttachmentId: string;
	public logoAttachmentId: string;
	public supplierClassId: number;
	public loadFactorTypeId: number;
	public hasConfirmationMapping: boolean;
	public hasFlowCheckMapping: boolean;
	public allowCommercialMatrixRenewal: boolean;
	public showIndicativeHedgedPrices: boolean;

	public user: User;
	public underwriter: User;
	public switchTypes: SwitchType[];
	public commissionRules: SupplierCommissionRule[];
	public serviceTypes: SupplierServiceType[];
	public attachments: Attachment[];
	public logins: User[];
	public state: State;
	public supplierRateSettings: SupplierRateSetting[];
	public monthlyAuctionPerformanceStats: SupplierMonthlyAuctionPerformanceStats[];
	public marketAuctionRankingStats: SupplierMarketAuctionRankReport[];
	public marketAuctionCompetitiveStats: SupplierMarketAuctionCompetitiveReport[];
	public parent: Supplier;
	public supplierReinstatementType: SupplierReinstatementType;
	public licensePlans: LicensePlan[];
	public supplierClass: SupplierClass;
	public securityQuestions: SupplierSecurityQuestion[];
	public esignFonts: SupplierEsignFont[];
	public loadFactorType: LoadFactorType;

	public hasCommissionMapping: boolean;
	public hasChildren: boolean;

	constructor(supplier?: Supplier) {
		Object.assign(this, supplier);

		this.logins = this.logins ? this.logins.map(l => new User(l)) : this.logins;
		this.commissionRules = this.commissionRules ? this.commissionRules.map(c => new SupplierCommissionRule(c)) : this.commissionRules;
		this.logo = this.logo ? HelperService.getPath(this.logo, true) : '';
		this.monthlyAuctionPerformanceStats = this.monthlyAuctionPerformanceStats
			? this.monthlyAuctionPerformanceStats.map(s => new SupplierMonthlyAuctionPerformanceStats(s))
			: this.monthlyAuctionPerformanceStats;
		this.marketAuctionRankingStats = this.marketAuctionRankingStats
			? this.marketAuctionRankingStats.map(s => new SupplierMarketAuctionRankReport(s))
			: this.marketAuctionRankingStats;
	}

	public getFullAddressLine(): string {
		let addressLine = '';

		addressLine += this.address1 + ' ';
		addressLine += this.address2 ? this.address2 + ' ' : '';
		addressLine += this.city + ', ';
		addressLine += this.state ? this.state.stateShort + ' ' : ' ';
		addressLine += this.zipCode;

		return addressLine;
	}

	public getContactFullName(): string {
		const contactMiddle = this.contactMiddle && this.contactMiddle.trim() ? this.contactMiddle.trim() + ' ' : '';
		return `${this.contactFname} ${contactMiddle}${this.contactLname}`;
	}

	public getContactFullNameLastFirst(): string {
		return `${this.contactLname}, ${this.contactFname} ${(this.contactMiddle && this.contactMiddle.trim()
			? ' ' + this.contactMiddle.trim() : '')}`;
	}
}
