import gql from 'graphql-tag';

import * as fragments from './graphql.fragments';

export const getCurrentUserSurvey = gql`
query GetCurrentUserSurvey($criteria: UserSurveyInput) {
	currentUserSurvey(criteria: $criteria) {
		id
		recommendScore
		scoreReason
		improveReason
		canContact
		bestEmail
		isComplete
		ticketId
		contractId
		userId
		addDate
	}
}
`;

export const getUserSurveyHistoricData = gql`
query GetUserSurveyHistoricData {
	userSurveyHistoricData {
		month
		score
	}
}
`;

export const getFileTransferDirectoryListing = gql`
query GetFileTransferDirectoryListing($serviceName: String!) {
	fileTransferDirectoryListing(serviceName: $serviceName) {
		name
		type
	}
}
`;

export const getFileTransferDownload = gql`
query GetFileTransferDownload($serviceName: String!, $fileName: String!) {
	fileTransferDownload(serviceName: $serviceName, fileName: $fileName) {
		response
	}
}
`;

export const deleteFileTransfer = gql`
query DeleteFileTransfer($serviceName: String!, $fileName: String!) {
	fileTransferDelete(serviceName: $serviceName, fileName: $fileName) {
		response
	}
}
`;

export const getReportCommissionAuditContractData = gql`
query getReportCommissionAuditContractData($contractId: String!) {
	contract(contractId: $contractId) {
		contractDate
		expirationDate
	}
}
`;

export const getGlobalUserGridConfigsByGridId = gql`
query GetGlobalUserGridConfigsByGridId($userId: String!, $gridId: String) {
	user(userId: $userId) {
		userId
		agentId
		roleId
		defaultUserGridConfigId
		isAdmin
		isSaas
		role {
			name
			level
			permissions {
				name
			}
		}
	}
	userGridConfigs(criteria: { isGlobal: true, gridId: $gridId }) {
		message {
			id
			name
			gridId
			isGlobal
			isDefault
			states
			sortOrder
		}
	}
	suppliers(criteria: { isActive: true }, sort: "name", max: 0) {
		message {
			supplierId
			name
		}
	}
	states(criteria: { isActive: true }, sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
}
`;

export const getGlobalGridConfigByGridId = gql`
query GetGlobalGridConfigByGridId($gridId: String!) {
	userGridConfigs(criteria: { isGlobal: true, gridId: $gridId }) {
		message {
			id
			name
			gridId
			isGlobal
			isDefault
			states
			sortOrder
		}
	}
}
`;

export const getGlobalUserGridConfigs = gql`
query GetGlobalUserGridConfigs {
	userGridConfigs(criteria: { isGlobal: true }) {
		message {
			gridId
		}
	}
}
`;

export const getRateMatrixBenchmarks = gql`
query GetRateMatrixBenchmarks($criteria: RateMatrixBenchmarkInput, $sort: String, $max: Int, $offset: Int) {
	rateMatrixBenchmark(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			effectiveDate
			serviceTypeId
			avgRate
			minRate
			maxRate
		}
	}
}
`;

export const getOctipowerConfig = gql`
query GetOctipowerConfig {
	octipowerConfig
}
`;

export const getAllHolidays = gql`
query GetAllHolidays {
	holidays {
		message {
			id
			date
			holidayTypeId
			type {
				name
			}
		}
	}
}
`;

export const getHolidayEditPageData = gql`
query GetHolidayEditPageData {
	holidayTypes {
		message {
			id
			name
		}
	}
}
`;

export const getHoliday = gql`
query GetHoliday($id: Int!) {
	holiday(id: $id) {
		id
		date
		holidayTypeId
		type {
			name
		}
	}
}
`;

export const getCommissionAuditReport = gql`
query GetCommissionAuditReport($reportId: String!, $criteria: CommissionAuditReportInput!) {
	commissionAuditReport(reportId: $reportId, criteria: $criteria)
}
`;

export const getViewContractLogChanges = gql`
query GetViewContractLogChanges($criteria: ViewContractLogChangeInput, $sort: String, $max: Int, $offset: Int) {
	viewContractLogChanges(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			addDate
			status
			displayStatus
			agentName
			userFullName
			rejectReason
			externalStatus
			isResidential
			externalId
			rate
			margin
			bufferMargin
			commissionSplitBufferMargin
			matrixAgentBufferMargin
			term
			annualUsage
			agentName
			userFullName
			salesTax
			stateId
			supplierId
			confirmationDate
			awardDate
		}
		total
	}
}
`;

export const getCustomerEditPageData = gql`
query GetCustomerEditPageData($customerId: String!) {
	customer(customerId: $customerId) {
		dbaName
		dbaName2
		contactFname
		contactMiddle
		contactLname
		contactTitle
		address1
		address2
		city
		stateId
		zipCode
		mobile
		phone
		phoneExt
		phone2
		phoneExt2
		fax
		email
		dob
		taxId
		ssn
		authorizedUser
		billingAddress1
		billingAddress2
		billingCity
		billingStateId
		billingZipCode
		previousAddress1
		previousAddress2
		previousCity
		previousStateId
		previousZipCode
		phoneCountryId
		phone2CountryId
		mobileCountryId
		faxCountryId
		customerAvailabilityId
		state {
			countryId
		}
		billingState {
			countryId
		}
		contracts(sort: "addDate desc") {
			contractId
			contractNum
			addDate
			status
			isNewBrokerAuction
			isResidential
		}
		emailPreferences {
			id
		}
		customerAvailability {
			mondayFrom
			mondayTo
			tuesdayFrom
			tuesdayTo
			wednesdayFrom
			wednesdayTo
			thursdayFrom
			thursdayTo
			fridayFrom
			fridayTo
			timezone {
				id
				name
				abbreviation
			}
		}
		residentialMarketRequirement {
			showSsn
			showDob
		}
	}
	states(sort: "stateLong desc") {
		message {
			stateShort
			stateId
			stateLong
			isActive
		}
	}
	emailPreferences {
		message {
			id
			name
			slug
			description
			sortOrder
		}
	}
	timezones(sort: "name") {
		message {
			id
			name
			abbreviation
		}
	}
	countries(sort: "name") {
		message {
			id
			name
			zipCodeFormat
			isActive
			code
		}
	}
}
`;

export const getCustomer = gql`
query GetCustomer($customerId: String!) {
	customer(customerId: $customerId) {
		dbaName
		contactFname
		contactMiddle
		contactLname
		contactTitle
		address1
		address2
		city
		stateId
		zipCode
		mobile
		phone
		phoneExt
		phone2
		fax
		email
		dob
		taxId
		ssn
		maritalStatus
		moveType
		movePlan
		moverType
		mailForwardDate
		wasResidenceBuiltInLast6Months
		authorizedUser
		billingAddress1
		billingAddress2
		billingCity
		billingStateId
		billingZipCode
		previousAddress1
		previousAddress2
		previousCity
		previousStateId
		previousZipCode
		cardNumber
		cardCvv
		cardExpires
	}
}
`;

export const getSupplierRateSettingsData = gql`
query GetSupplierRateSettingsData($criteria: SupplierRateSettingInput, $utilityId: String!, $stateId: String!) {
	supplierRateSettings(criteria: $criteria) {
		message {
			id
			supplierId
			utilityId
			serviceTypeId
			stateId
			loadFactor
			loadFactorNote
			billingMethod
			taxInfo
			cancellationPolicy
			addDate
			showTaxId
			tosPath
			disclaimerPath
			eflPath
			yracPath
			showDepositDisclaimer
			depositDisclaimerNote
			depositPhone
			restrictionsNote
			showOhepNotice
			captureSignatureAtRSEnrollment
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	utility(utilityId: $utilityId) {
		name
		abbreviation
	}
	state(stateId: $stateId) {
		stateId
		stateLong
	}
}
`;

export const getCustomerInfoData = gql`
query GetCustomerInfoData($customerId: String!) {
	customer(customerId: $customerId) {
		customerId
		dbaName
		contactFname
		contactMiddle
		contactLname
		city
		state {
			stateShort
			stateLong
		}
		zipCode
		address1
		address2
		billingCity
		billingState {
			stateShort
			stateLong
		}
		billingZipCode
		billingAddress1
		billingAddress2
		previousAddress1
		previousAddress2
		previousZipCode
		previousCity
		previousState {
			stateShort
			stateLong
		}
		email
		phone
		phone2
		fax
		mobile
		phoneCountryId
		phone2CountryId
		mobileCountryId
		faxCountryId
		contracts {
			contractId
			contractNum
			status
			displayStatus
			effectiveDate
			expirationDate
			addDate
			isResidential
			isFraudulent
			depositRequiredAmount
			isSupplierApiSubmitted
			externalId
			isNewBrokerAuction
			contractDate
		}
	}
}
`;

export const getReportBillingConsumptionData = gql`
query GetReportBillingConsumptionmessage {
	serviceTypes(criteria: { isActive: true }) {
		message {
			serviceTypeId
			name
		}
	}
}
`;

export const getAuctionSendMessageSuppliers = gql`
query GetAuctionSendMessageSuppliers($rfqSessionId: String!, $supplierId: String) {
	rfqSession(rfqSessionId: $rfqSessionId) {
		contract {
			status
			contractNum
			isNewBrokerAuction
			customer {
				dbaName
			}
		}
	}
	suppliers(criteria: { supplierId: $supplierId }, sort: "name") {
		message {
			supplierId
			name
		}
	}
}
`;

export const getAuctionListAuctionMonitorReportModalData = gql`
query GetAuctionListAuctionMonitorReportModalData($rfqSessionId: String) {
	rfqSessionSuppliers(criteria: { rfqSessionId: $rfqSessionId }) {
		message {
			supplierId
			supplier {
				name
			}
		}
	}
}
`;

export const getAuctionReportSupplierEventData = gql`
query GetAuctionReportSupplierEventData($rfqSessionId: String!) {
	rfqSessionSupplierAuctionEvents(rfqSessionId: $rfqSessionId) {
		timeToTracking
		rfqSessionId
		supplierId
		releasedDate
		notVisited {
			supplierId
			supplier {
				name
			}
		}
		needsRefreshedPricing {
			supplierId
			supplier {
				name
			}
		}
		notDownloadedRFP {
			supplierId
			supplier {
				name
			}
		}
		waitingOnDocs {
			supplierId
			supplier {
				name
			}
		}
		waitingOnLOAs {
			supplierId
			supplier {
				name
			}
		}
		waitingOnLOEs {
			supplierId
			supplier {
				name
			}
		}
		waitingOnSampleBills {
			supplierId
			supplier {
				name
			}
		}
		openedBrokerInfo {
			supplierId
			supplier {
				name
			}
		}
		noBid {
			supplierId
			supplier {
				name
			}
		}
		lastBidTimes {
			supplier {
				supplierId
				supplier {
					name
				}
			}
			lastBidTime
		}
		requestedDocuments {
			supplier {
				supplierId
				supplier {
					name
				}
			}
			document
			requestedDate
		}
	}
	rfqSessionSuppliers(criteria: { rfqSessionId: $rfqSessionId, isOptOut: false }) {
		message {
			supplierId
			sampleBillRequestDate
			loaRequestDate
			loeRequestDate
			clickedRFPEmailLink
			clickedRFPDashboardLink
			openedBrokerInfo
			downloadedSampleBill
			downloadedLOA
			downloadedLOE
			hasVisitedAuction
			supplier {
				name
			}
			bids {
				addDate
				supplierId
			}
		}
	}
}
`;

export const getAuctionReportData = gql`
query GetAuctionReportData($reportId: String!, $criteria: AuctionReportInput, $sort: String, $max: Int, $offset: Int) {
	auctionReport(reportId: $reportId, criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			ranking
			startDate
			lastBidTime
			serviceTypeId
			commodity
			supplierId
			rfqSessionId
			supplierLastBidTime
			annualUsage
			dbaName
			endDate
			contractId
			status
			customerId
			supplierName
			contractNum
			sampleBillAttachmentIdList
			loaAttachmentIdList
			loeAttachmentIdList
			isResidential
			externalId
			displayStatus
			stateId
			units
			releasedDate
			lastSampleBillAddDate
			lastLOAAddDate
			lastLOEAddDate
			agentId
			agentName
			agentEmail
			parentName
			parentEmail
			parentBrotherName
			utilityIdList
			isNewBrokerAuction
			agentIsNewBroker
			notes(sort: "addDate") {
				noteId
				noteTypeId
				subject
				body
				addDate
			}
		}
		total
		start
		end
	}
}
`;


export const getAppListData = gql`
query GetAppListmessage {
	apps(sort: "name") {
		message {
			id
			name
		}
	}
}`;

export const getAppEditData = gql`
query GetAppEditData($id: Int!) {
	app(id: $id) {
		id
		name
	}
}`;

export const sendAllAccountsEmail = gql`
query SendAllAccountsEmail($criteria: AllAccountsEmailInput!) {
	sendAllAccountsEmail(criteria: $criteria) {
		response
	}
}
`;

export const sendAccountsInactiveUtilityDistribution = gql`
query SendAccountsInactiveUtilityDistribution {
	sendAccountsInactiveUtilityDistribution {
		response
	}
}
`;

export const sendUtilityUsageComparedToReceivableUsage = gql`
query SendUtilityUsageComparedToReceivableUsage {
	sendUtilityUsageComparedToReceivableUsage {
		response
	}
}
`;

export const sendAccountsMissingUsage = gql`
query SendAccountsMissingUsage($criteria: AccountsMissingUsageInput!) {
	sendAccountsMissingUsage(criteria: $criteria) {
		response
	}
}
`;

export const getAccountUsageReportAccountUsage = gql`
query GetAccountUsageReportAccountUsage($criteria: AccountUsageInput, $sort: String) {
	accountUsages(criteria: $criteria, sort: $sort) {
		message {
			utilityAccountNum
			utilityReferenceNum
			utilityId
			serviceTypeId
			period
			usage
			meterStartDate
			meterEndDate
		}
	}
}
`;

export const getAccountUsageReportData = gql`
query GetAccountUsageReportData($reportId: String!, $criteria: AccountUsageReportInput!) {
	accountUsageReport(reportId: $reportId, criteria: $criteria) {
		message {
			contractId
			contractNum
			commodity
			term
			effectiveDate
			dbaName
			annualUsage
			units
			utilityAccountNum
			utilityReferenceNum
			contractLocationId
			firstConfirmationDate
			address1
			address2
			isActive
			utilityId
			serviceTypeId
		}
	}
}
`;

export const getAccountUsageReportPageData = gql`
query GetAccountUsageReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getAuctionReportPageData = gql`
query GetAuctionReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	suppliers(criteria: { isActive: true }, sort: "name", max: 0) {
		message {
			supplierId
			name
		}
	}
	utilities(criteria: { isActive: true }, sort: "name", max: 0) {
		message {
			utilityId
			name
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getContractCommissionPayPlanEditPageData = gql`
query GetContractCommissionPayPlanEditPageData($contractId: String!) {
	contract(contractId: $contractId) {
		contractId
		contractNum
		commissionPayPlanId
		units
		term
		annualUsage
		agentId
		isNewBrokerAuction
		state {
			country {
				symbol
				isoCode
			}
		}
		commissionPayPlan {
			commissionPayPlanTypeId
		}
		agent {
			parent {
				commissionSplitTypeId
			}
		}
	}
}`;

export const getRedisKeys = gql`
query GetRedisKeys($pattern: String) {
	redisKeys(pattern: $pattern)
}
`;

export const getRedisKey = gql`
query GetRedisKey($name: String!) {
	redisKey(name: $name)
}
`;

export const getTicketReportPageData = gql`
query GetTicketReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
	users(criteria: { roleId: "297ed5063d288ec7013d290e2dab003e,297ed5063d288ec7013d290e2dc7003f" }, sort: "fname,lname") {
		message {
			userId
			fname
			lname
			emails {
				email
			}
		}
	}
	ticketCategories(sort: "name") {
		message {
			id
			name
		}
	}
	ticketStatuses(sort: "name") {
		message {
			id
			name
		}
	}
}
`;

export const getBillingReports = gql`
query GetBillingReports($reportId: String!, $criteria: BillingReportInput!) {
	billingReports(reportId: $reportId, criteria: $criteria) {
		billPeriodStartDate
		billPeriodEndDate
		dueDate
		usage
		amount
		isPaid
		contractLocation {
			utilityAccountNum
			contract {
				contractId
				contractNum
				units
				isNewBrokerAuction
				customer {
					dbaName
				}
			}
		}
	}
}
`;

export const getSystemLogsData = gql`
query GetSystemLogsData($reportId: String!, $criteria: SystemLogInput, $sort: String, $max: Int, $offset: Int) {
	systemLogsReport(reportId: $reportId, criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			severity
			category
			addDate
			appendername
			message
			extrainfo
		}
		total
		start
		end
	}
}
`;

export const getSystemLogsReportData = gql`
query GetSystemLogsReportData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getAllAgentDiscountPrograms = gql`
query GetAllAgentDiscountPrograms {
	agentDiscountPrograms {
		message {
			id
			name
			minVolume
			maxVolume
			rate
			addDate
		}
	}
}
`;

export const getSingleAgentDiscountProgram = gql`
query GetSingleAgentDiscountProgram($id: Int!) {
	agentDiscountProgram(id: $id) {
		name
		minVolume
		maxVolume
		rate
	}
}
`;

export const importRatesFromSupplierApi = gql`
query ImportRatesFromSupplierApi($assemblyName: String!, $companyId: String!) {
	importRatesFromSupplierApi(assemblyName: $assemblyName, companyId: $companyId)
}
`;

export const supplierRates = gql`
query SupplierRate($assemblyName: String!, $criteria: SupplierRatesInput!) {
	supplierRates(assemblyName: $assemblyName, criteria: $criteria)
}
`;

export const getSupplierRatesPageData = gql`
query GetSupplierRatesPageData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getSupplierRatesCompanyData = gql`
query GetSupplierRatesCompanyData($companyId: String) {
	companyRateMatrixLoaders(criteria: { companyId: $companyId, isActive: true, isAPI: true }) {
		message {
			loader {
				assemblyName
			}
		}
	}

}
`;

export const getDirectMatrixPriceData = gql`
query GetDirectMatrixPriceData($contractId: String!) {
	contract(contractId: $contractId) {
		agentId
		rfqSession {
			id
		}
	}
}
`;

export const forgotUsername = gql`
query ForgotUsername($email: String!) {
	forgotUsername(email: $email) {
		userId
		isActive
	}
}
`;

export const forgotPassword = gql`
query ForgotPassword($username: String!, $email: String!) {
	forgotPassword(username: $username, email: $email) {
		userId
		isActive
	}
}
`;

export const getRateMatrixEditPageData = gql`
query GetRateMatrixEditPageData {
	suppliers(sort: "name", max: 0) {
		message {
			supplierId
			name
		}
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
}
`;

export const getContractAccountAdjustmentData = gql`
query GetContractAccountAdjustmentData($contractId: String!) {
	contract(contractId: $contractId) {
		contractNum
		isNewBrokerAuction
		agent {
			agentId
			name
			isNewBroker
			parent {
				agentId
				name
				isNewBroker
				logins {
					userId
					fname
					middle
					lname
					isPrimary
					addDate
					emails {
						email
					}
				}
			}
			brother {
				agentId
				name
				isNewBroker
				logins {
					userId
					fname
					middle
					lname
					isPrimary
					addDate
					emails {
						email
					}
				}
			}
			logins {
				userId
				fname
				middle
				lname
				isPrimary
				addDate
				emails {
					email
				}
			}
		}
	}
}
`;

export const getSingleAccountAdjustment = gql`
query GetSingleAccountAdjustment($id: Int!) {
	accountAdjustment(id: $id) {
		amount
		agentId
		contractId
		description
		isOnHold
		contract {
			contractNum
			isNewBrokerAuction
		}
		agent {
			name
		}
	}
}
`;

export const getSecurityRuleEditPageData = gql`
query GetSecurityRuleEditPageData {
	roles(sort: "name") {
		message {
			roleId
			name
		}
	}
	permissions(sort: "name") {
		message {
			permissionId
			name
		}
	}
}
`;

export const getAllAccountAdjustments = gql`
query GetAllAccountAdjustments($criteria: AccountAdjustmentInput) {
	accountAdjustments(criteria: $criteria, sort: "addDate desc") {
		message {
			id
			amount
			description
			userId
			contractId
			addDate
			isOnHold
			agent {
				name
				isNewBroker
			}
			contract {
				contractNum
				isNewBrokerAuction
			}
			user {
				fname
				lname
				fullName
			}
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getImportResidentialRatesCompanyData = gql`
query GetImportResidentialRatesCompanyData($companyId: String) {
	companyRateMatrixLoaders(criteria: { companyId: $companyId, isActive: true, isAPI: true }) {
		message {
			loader {
				assemblyName
			}
		}
	}
}
`;

export const getImportResidentialRatesPageData = gql`
query GetImportResidentialRatesPageData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getUserShowPageData = gql`
query GetUserShowPageData($userId: String!) {
	user(userId: $userId) {
		userId
		supplierId
		agentId
		fname
		lname
		phone
		phone2
		fax
		mobile
		fullName
		isAdmin
		isSaas
		emails {
			email
		}
		role {
			name
		}
	}
}
`;

export const getUserShowPageApiKeyData = gql`
query GetUserShowPageApiKeyData($userId: String!) {
	user(userId: $userId) {
		apiKeys {
			id
			key
			referrer
			description
			addDate
			limit
			allowsGraphQL
			allowsLogin
		}
	}
}
`;

export const getUtilityRateClassEditPageData = gql`
query GetUtilityRateClassEditPageData {
	utilities(max: 0, sort: "name") {
		message {
			utilityId
			name
		}
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
}`;

export const getSupplierRateSettingsEditPageData = gql`
query GetSupplierRateClassEditPageData($criteria: UtilityRateClassMapInput, $utilityId: String!, $stateId: String!) {
	utility(utilityId: $utilityId) {
		rateClasses(stateId: $stateId, isActive: true) {
			name
		}
		zones(stateId: $stateId, serviceTypeId: "297ed5063d424e7b013d429edf0d0006", isActive: true) {
			name
		}
	}
	utilityRateClasses(criteria: { utilityId: $utilityId }, sort: "name") {
		message {
			name
		}
	}
	utilityRateClassMaps(criteria: $criteria) {
		message {
			id
			supplierId
			utilityId
			stateId
			zone
			oldRateClass
			newRateClass
			description
			isActive
		}
	}
}
`;

export const getUtilitySupplierEditPageData = gql`
query GetUtilitySupplierEditPageData(
	$criteria: UtilitySupplierMapInput, $utilityId: String!, $stateId: String!, $supplierNeedZipCode: String
) {
	brands: suppliers(criteria: { parentId: $supplierNeedZipCode }, sort: "name") {
		message {
			supplierId
		}
	}
	utility(utilityId: $utilityId) {
		name
		abbreviation
		zones(stateId: $stateId, serviceTypeId: "297ed5063d424e7b013d429edf0d0006", isActive: true) {
			name
		}
	}
	utilitySupplierMaps(criteria: $criteria) {
		message {
			id
			oldName
			oldName2
			supplierId
			serviceTypeId
			zone
			utilityId
			stateId
			billingMethod
			unit
			isActive
			isResidential
			zipCode
		}
	}
}`;

export const getSupplierRateClassEditPageData = gql`
query GetSupplierRateClassEditPageData($criteria: UtilityRateClassMapInput, $utilityId: String!, $stateId: String!) {
	utility(utilityId: $utilityId) {
		name
		abbreviation
		zones(stateId: $stateId, serviceTypeId: "297ed5063d424e7b013d429edf0d0006", isActive: true) {
			name
		}
	}
	utilityRateClasses(criteria: { utilityId: $utilityId, stateId: $stateId }, sort: "name") {
		message {
			name
		}
	}
	utilityRateClassMaps(criteria: $criteria) {
		message {
			id
			supplierId
			utilityId
			stateId
			zone
			oldRateClass
			newRateClass
			description
			isActive
		}
	}
	state(stateId: $stateId) {
		stateId
		stateLong
	}
}`;

export const getCustomerSignupPageData = gql`
query GetCustomerSignupPageData {
	suppliers(criteria: { hasAPI: true }, sort: "name") {
		message {
			supplierId
			name
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
}
`;

export const getContractNotes = gql`
query GetContractNotes($contractId: String!, $noteTypeIds: String) {
	notes(criteria: { contractId: $contractId, noteTypeId: $noteTypeIds }, sort: "addDate desc") {
		message {
			addDate
			subject
			body
			isInternal
			user {
				fname
				lname
				middle
				fullName
			}
			noteType {
				name
			}
		}
	}
}
`;

export const listPendingCommissionsQueue = gql`
query ListPendingCommissionsQueue($criteria: PendingCommissionInput) {
	pendingCommissions(criteria: $criteria) {
		message {
			isProcessed
			status
		}
	}
}
`;

export const listMarkAsPaidCommissionsQueue = gql`
query ListMarkAsPaidCommissionsQueue {
	commissions(criteria: { hasQueueStatus: true }) {
		message {
			contractId
			contractLocationId
			cycleStartDate
			cycleEndDate
			commissionTypeId
			commissionDate
			queueStatus
		}
	}
	accountAdjustments(criteria: { hasQueueStatus: true }) {
		message {
			queueStatus
		}
	}
}
`;

export const getPendingCommissionStats = gql`
query GetPendingCommissionStats($criteria: ViewCommissionDashboardInput) {
	pendingCommissionStats(criteria: $criteria) {
		supplierTotalPayments {
			supplierName
			totalPayment
		}
		numTotal
		numIsArchived
		numHasCriticalPriority
		numHasHighPriority
		numHasMediumPriority
		numHasLowPriority
		numHasNoIssue
		attachments {
			attachmentId
			originalName
			addDate
		}
		fileAddDates
	}
}
`;

export const getPostProcessingRefreshProgress = gql`
	query PostProcessingCommissionAuditProgress($companyId: String) {
		postProcessingCommissionAuditProgress(companyId: $companyId) {
			response
		}
	}
`;

export const getPostProcessingCommissionAuditEditData = gql`
query GetPostProcessingCommissionAuditEditData($contractId: String!) {
	contract(contractId: $contractId) {
		units
		contractId
		serviceTypeId
		contractDate
		isResidential
		margin
		commissionSplitBufferMargin
		bufferMargin
		matrixAgentBufferMargin
		pendingCommissions(criteria: { isArchived: false }, sort: "reportDate") {
			usage
		}
		supplier {
			commissionRules {
				commissionCutoff
				serviceTypeId
				supplierId
				upfrontPercentage
				upfrontLag
				monthlyLag
				upfrontDateType
				hasAnniversaryUpfront
				anniversaryUpfrontDateType
				minUpfrontTermFactor
				maxUpfrontTermFactor
				discountFactor
				hasResidentialUpfront
				residentialPayLag
				residentialPayAmount
				residentialMargin
				marginCap
				marginSplit
				hasCommercial
				hasResidential
				residentialDescription
				commercialDescription
				contractStartDate
				contractEndDate
			}
		}
	}
	commissionTypes {
		message {
			id
			name
		}
	}
	receivableAuditIssueTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	postProcessingCommissionAuditStatusTypes {
		message {
			id
			name
		}
	}
	postProcessingCommissionAuditStatuses {
		message {
			id
			pendingCommissionId
			description
		}
	}
}
`;

export const listPostProcessingCommissionAudits = gql`
query ListPostProcessingCommissionAudits($criteria: ViewPostProcessingCommissionAuditInput) {
	viewPostProcessingCommissionAudits(criteria: $criteria) {
		message {
			id
			reportDate
			customerId
			customerName
			contractId
			contractNum
			isResidential
			externalId
			utilityAccountNum
			supplierName
			forecasted
			forecastedUsage
			actual
			actualUsage
			lateActual
			actualTotal
			issue
			status
			deviation
			forecastedDate
			postProcessingStatus
			originalDate
			receivableAuditIssueTypeId
			receivableOperationStatusTypeId
			description
			meterStartDate
			meterEndDate
			forecastedMeterStartDate
			forecastedMeterEndDate
			postProcessingCommissionAuditStatusId
			postProcessingCommissionAuditStatusTypeId
			minPendingCommissionId
			commissionTypeId
			contractLocationId
		}
		total
		start
		end
	}
}
`;

export const listGroupedPostProcessingCommissionAudits = gql`
query ListGroupedPostProcessingCommissionAudits($criteria: ViewPostProcessingCommissionAuditInput, $sort: String, $max: Int, $offset: Int) {
	viewGroupedPostProcessingCommissionAudits(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			customerId
			customerName
			contractId
			contractNum
			isResidential
			supplierName
			deviation
			actual
			forecasted
			isReviewed
			flagReason
		}
		total
		start
		end
	}
	viewPostProcessingStats(criteria: $criteria) {
		forecasted
		forecastedMonthToDate
		actual
		currentDeviationMonthToDate
		addDate
		numFilesNotInAudits
		forecastedAssets
		forecastedLiability
		issueDeviation
		noIssueDeviation
		issueDeviationTotals {
			issue
			deviation
		}
	}
}
`;

export const exportViewPostProcessingCommissionAudits = gql`
query ExportViewPostProcessingCommissionAudits($criteria: ViewPostProcessingCommissionAuditInput) {
	exportViewPostProcessingCommissionAudits(criteria: $criteria) {
		response
	}
}
`;

export const exportViewPostProcessingCommissionSupplierReport = gql`
query ExportViewPostProcessingCommissionSupplierReport($criteria: ViewPostProcessingCommissionAuditInput) {
	exportViewPostProcessingCommissionSupplierReport(criteria: $criteria) {
		response
	}
}
`;

export const exportHistoricalViewPostProcessingCommissionAudits = gql`
query ExportHistoricalViewPostProcessingCommissionAudits($criteria: ViewPostProcessingCommissionAuditInput) {
	exportHistoricalViewPostProcessingCommissionAudits(criteria: $criteria) {
		response
	}
}
`;

export const exportTicketReport = gql`
query ExportTicketReport($criteria: TicketReportInput) {
	exportTicketReport(criteria: $criteria) {
		response
	}
}
`;

export const listPendingCommissionDashboard = gql`
query ListPendingCommissionsDashboard($criteria: ViewCommissionDashboardInput, $sort: String, $max: Int, $offset: Int) {
	viewCommissionDashboard(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			description
			customerName
			contractId
			contractLocationId
			supplierName
			commodity
			accountNumber
			meterStartDate
			meterEndDate
			usage
			margin
			supplierPayment
			isDirectSale
			isProcessed
			isArchived
			subjects
			subjectIds
			totalIssues
			totalPendingIssues
			totalNotes
			hasCriticalPriority
			hasHighPriority
			hasMediumPriority
			hasLowPriority
			sentToOps
			commissionTypeId
			processTimeType
			commissionPayPlanName
			commissionTypeName
			contractNum
			contractUnits
			addDate
			archiveDate
			hasMatchedAccounts
			externalId
		}
		total
		start
		end
	}
}
`;

export const getPendingCommissionMatchedAccounts = gql`
query GetPendingCommissionMatchedAccounts($pendingCommissionId: IntString) {
	pendingCommissionMatchedAccounts(criteria: { pendingCommissionId: $pendingCommissionId }, sort: "partialMatchSimilarity desc") {
		message {
			contractLocation {
				contractId
				utilityAccountNum
				contract {
					contractNum
					displayStatus
					supplier {
						name
					}
					customer {
						dbaName
					}
				}
			}
			partialMatchSimilarity
		}
	}
}
`;

export const listPendingConfirmationsQueue = gql`
query ListPendingConfirmations($criteria: PendingConfirmationInput) {
	pendingConfirmations(criteria: $criteria) {
		message {
			isProcessed
			queueStatus
		}
	}
}
`;

export const listPendingConfirmationsFilterOptions = gql`
query ListPendingConfirmationsFilterOptions($criteria: PendingConfirmationInput) {
	pendingConfirmationsFilterOptions(criteria: $criteria) {
		filterOptions {
			suppliers {
				supplierId
				name
			}
			contractStatuses
			rejectReasons
			errorMessages
			addDates
		}
	}
}
`;

export const listPendingConfirmations = gql`
query ListPendingConfirmations($criteria: PendingConfirmationInput, $sort: String, $max: Int, $offset: Int) {
	pendingConfirmations(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			contractId
			supplierId
			externalId
			supplierStatus
			supplierStatus2
			status
			displayStatus
			rejectReason
			contactFname
			contactLname
			address1
			address2
			city
			state
			zipCode
			email
			utilityAccountNum
			isProcessed
			warnings
			errors
			dbaName
			dropDate
			addDate
			isResidential
			contract {
				contractId
				contractNum
				effectiveDate
			}
			supplier {
				name
			}
		}
		total
		start
		end
	}
}
`;

export const listPendingInvoices = gql`
query ListPendingInvoices($criteria: PendingInvoiceInput, $sort: String, $max: Int, $offset: Int) {
	pendingInvoices(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			contractLocationId
			accountNumber
			billPeriodStartDate
			billPeriodEndDate
			invoiceDate
			dueDate
			amount
			usage
			warnings
			errors
			isPaid
			isProcessed
			isReviewed
			contractLocation {
				contract {
					contractId
					contractNum
				}
			}
		}
		total
		start
		end
	}
}
`;

export const getSingleSecurityRule = gql`
query GetSingleSecurityRule($securityRuleId: String!) {
	securityRule(securityRuleId: $securityRuleId) {
		secureList
		whiteList
		roles
		permissions
		match
		redirect
		sortOrder
		isActive
	}
	roles(sort: "name") {
		message {
			roleId
			name
		}
	}
	permissions(sort: "name") {
		message {
			permissionId
			name
		}
	}
}
`;

export const getNavbarData = gql`
query GetNavbarData($userId: String!, $menuTypeId: String) {
	userMenus(criteria: { menuTypeId: $menuTypeId, isActive: true }, sort: "sortOrder") {
		message {
			menuId
			menuTypeId
			permissions
			eventName
			queryString
			className
			name
			idName
			sortOrder
			parentId
			iconClassName
		}
	}
	agentDiscountPrograms(sort:"minVolume") {
		message {
			id
			name
			rate
			className1
			className2
			className3
			minVolume
			maxVolume
		}
	}
	user(userId: $userId) {
		userId
		fname
		lname
		middle
		phone
		showTermsOfService
		showCompliance
		showIdDocument
		agentId
		isActive
		lastLogin
		fullName
		supplierId
		isPasswordExpired
		isAdmin
		isSaas
		surveySettings {
			id
			userSurveyCategoryId
			show
		}
		emails {
			email
		}
		role {
			name
			permissions {
				name
			}
		}
		supplier {
			licensePlans {
				id
			}
		}
		agent {
			parentId
			phone
			customerCompany
			isActive
			isEnterprise
			commissionRate
			commissionRate2
			commissionRate3
			volume
			discountFactor
			useReports
			futureCommissionAmount
			totalUpfrontCommissionPaidAmount
			flowedTermValue
			maxReversibleCommission
			maxReversibleCommissionOverride
			negativeBalance
			reversibleCommission
			currMonthEstimatedCommission
			lastMonthPaidCommission
			showTrainingVideos
			showEducation
			showFinancialProfile
			useSupportSystem
			marketOpportunitiesAlertEffectiveDate
			dropRatio
			hasConfirmedContracts
			isEmployee
			useAgentDiscountProgram
			isInHouseIndirectSale
			darkDisplayLogo: displayLogo(criteria: { defaultAgentLogoType: DARK, defaultCompanyLogoType: NAVBAR }) {
				attachmentId
			}
			bankAccounts {
				id
			}
			licensePlans {
				id
				years
				parents {
					id
				}
			}
			discountProgram {
				name
				rate
				className1
				className2
				className3
			}
			parent {
				useReports
				discountFactor
				customerCompany
				showTrainingVideos
				showEducation
				showFinancialProfile
				useSupportSystem
				supportEmail
				useAgentDiscountProgram
				dropRatio
			}
			commissionPayPlanOptionStats {
				description
				maxRate
			}
			logins {
				fname
				middle
				lname
				isPrimary
				addDate
				emails {
					email
				}
			}
		}
		company {
			isSaas
			isDecoupled
			licensePlans {
				id
			}
			defaultNavbarLogo {
				attachmentId
				attachment {
					attachmentId
					originalName
					path
				}
			}
			defaultFooterLogo {
				attachmentId
				attachment {
					attachmentId
					originalName
					path
				}
			}
		}
	}
}
`;

export const getSystemClock = gql`
query GetSystemClock {
	systemClock
}
`;

export const getEdiUtilityUsageSettingsAddEditData = gql`
query GetEdiUtilityUsageSettingsAddEditData($id: Int!) {
	ediUtility(id: $id) {
		id
		utilityId
		stateId
		serviceTypeId
		ldcDUNSNumber
		ldcName
		ldcN1Qualifier
		espDUNSNumber
		espName
		espN1Qualifier
		requestKey
		espAccountNumber
	}
}
`;

export const getUtilityWebPortalUsageSettingsAddEditData = gql`
query GetUtilityWebPortalUsageSettingsAddEditData($id: Int!) {
	utilityWebPortal(id: $id) {
		id
		utilityId
		serviceTypeId
		stateId
		isActive
		isResidential
		isCommercial
		username
		password
	}
}
`;

export const getUtilityUsageSettingsAddEditPageData = gql`
query GetUtilityUsageSettingsAddEditPageData {
	serviceTypes(sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	utilities(sort: "name") {
		message {
			utilityId
			name
		}
	}
	states(sort: "stateShort") {
		message {
			stateId
			stateShort
		}
	}
}
`;

export const getUtilityUsageSettingsListPageData = gql`
query GetUtilityUsageSettingsListPageData {
	ediUtilities {
		message {
			id
			requestKey
			utility {
				name
			}
			serviceType {
				name
			}
			state {
				stateShort
			}
		}
	}
	utilityWebPortals {
		message {
			id
			isActive
			utility {
				name
			}
			serviceType {
				name
			}
			state {
				stateShort
			}
		}
	}
}
`;

export const getRateMatrixLoaderDetails = gql`
query GetRateMatrixLoaderDetails($companyRateMatrixLoaderId: Int!, $finishedAtGTE: String) {
	emailAttachments(
		criteria: { companyRateMatrixLoaderId: $companyRateMatrixLoaderId, finishedAtGTE: $finishedAtGTE, isProcessed: true }
		sort: "finishedAt"
	) {
		message {
			amount
			finishedAt
		}
	}
}
`;

export const getRateMatrixLoaderAuditDetails = gql`
query GetRateMatrixLoaderAuditDetails($companyRateMatrixLoaderId: Int, $finishedAtGTE: String) {
	companyRateMatrixLoaderAudits(
		criteria: { companyRateMatrixLoaderId: $companyRateMatrixLoaderId, finishedAtGTE: $finishedAtGTE, isProcessed: true }
		sort: "finishedAt"
	) {
		message {
			amount
			finishedAt
		}
	}
}
`;

export const getContractLocationStatusReasonTypesAddEditData = gql`
query GetContractLocationStatusReasonTypesAddEditData($id: Int!) {
	contractLocationStatusReasonType(id: $id) {
		id
		name
		sortOrder
		contractLocationStatus
		isActive
	}
}
`;

export const getContractLocationStatusReasonTypesListData = gql`
query GetContractLocationStatusReasonTypesListData {
	contractLocationStatusReasonTypes {
		message {
			id
			name
			sortOrder
			contractLocationStatus
			isActive
		}
	}
}
`;

export const getPopUpListPageData = gql`
query GetPopUpListPageData {
	popUps {
		message {
			id
			headline
			imageAttachmentId
			content
			callToActionButtonText
			callToActionDestinationUrl
			priority
			isActive
			startDate
			runIndefinitely
			endDate
		}
	}
}
`;

export const getPopUpAddEditPageData = gql`
query GetPopUpAddEditPageData {
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}

	companies(sort: "name") {
		message {
			companyId
			name
		}
	}

	utilities(criteria: { isActive: true }, sort: "abbreviation") {
		message {
			utilityId
			name
			abbreviation
		}
	}
}
`;

export const getPopUpAddEditData = gql`
query GetPopUpAddEditData($id: Int!) {
	popUp(id: $id) {
		id
		headline
		image {
			attachmentId
			name
			attachmentTypeId
		}
		content
		callToActionButtonText
		callToActionDestinationUrl
		priority
		isActive
		startDate
		runIndefinitely
		endDate
		displayFrequencyDays
		showToParentAgents
		showToSubAgents
		showToSpecificAgents
		specificAgents {
			agentId
			name
		}
		showToAllUtilities
		specificUtilities {
			utilityId
			name
		}
		specificStates {
			stateId
			stateLong
		}
		specificCompanies {
			companyId
			name
		}
	}
}
`;

export const getNextPopUp = gql`
query GetNextPopUp {
	nextPopUp {
		headline
		imageAttachmentId
		content
		callToActionButtonText
		callToActionDestinationUrl
	}
}
`;


export const getFeatureTourListPageData = gql`
query GetFeatureTourListPageData {
	featureTours {
		message {
			id
			isActive
			name
			headline
			description
			locations {
				id
				featureTourId
				name
				route
			}
			slides {
				id
				featureTourId
				sortOrder
				imageUrl
				videoUrl
				caption
			}
		}
	}
}
`;

export const getFeatureTourAddEditPageData = gql`
query GetFeatureTourAddEditPageData {
	featureTourLocations {
		message {
			id
			featureTourId
			name
			route
		}
	}
}
`;

export const getFeatureTourAddEditData = gql`
query GetFeatureTourAddEditData($id: Int!) {
	featureTour(id: $id) {
		id
		isActive
		name
		headline
		description
		locations {
			id
			featureTourId
			name
			route
		}
		slides {
			id
			featureTourId
			sortOrder
			imageUrl
			videoUrl
			caption
		}
	}
}
`;

export const getNextFeatureTour = gql`
query GetNextFeatureTour($featureTourLocationId: Int!) {
	nextFeatureTour(featureTourLocationId: $featureTourLocationId) {
		headline
		slides {
			sortOrder
			imageUrl
			videoUrl
			caption
		}
	}
}
`;

export const getContractDropSettingsData = gql`
query GetContractDropSettingsData {
	contractLocationStatusReasonTypes(criteria: { isActive: true }, sort: "sortOrder asc") {
		message {
			id
			name
			contractLocationStatus
		}
	}
}
`;

export const getDisclaimer = gql`
query GetDisclaimer($disclaimerId: Int!) {
	disclaimer(disclaimerId: $disclaimerId) {
		serviceTypeId
		supplierId
		utilityId
		stateId
		showDob
		showSsn
		heading
		body
		showTaxExemptReasons
		switchTypeCode
		rateClass
		letterOfAuthorization {
			letterOfAuthorizationId
			heading
			body
		}
		ohepNotice {
			id
			heading
			body
		}
		customerDisclosureStatement {
			customerDisclosureStatementId
			heading
			body
			acknowledgements {
				acknowledgementId
				requiresAcknowledgement
				acknowledgedMessage
				unacknowledgedMessage
				consumerBillOfRights
				heading
				body
				footer
				sortOrder
			}
		}
		specialNotice {
			specialNoticeId
			heading
			body
			acknowledgements {
				acknowledgementId
				requiresAcknowledgement
				acknowledgedMessage
				unacknowledgedMessage
				consumerBillOfRights
				heading
				body
				footer
				sortOrder
			}
		}
		acknowledgements {
			acknowledgementId
			requiresAcknowledgement
			acknowledgedMessage
			unacknowledgedMessage
			consumerBillOfRights
			heading
			body
			footer
			sortOrder
		}
	}
}
`;

export const getDisclaimerListData = gql`
query GetDisclaimerListData($criteria: DisclaimerInput) {
	disclaimers(criteria: $criteria) {
		message {
			disclaimerId
			supplier {
				name
			}
			state {
				stateLong
			}
			serviceType {
				name
			}
			utility {
				abbreviation
			}
		}
	}
}
`;

export const getDisclaimerListPageData = gql`
query GetDisclaimerListPageData {
	suppliers(sort: "name") {
		message {
			supplierId
			name
		}
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
			stateShort
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	utilities(sort: "abbreviation") {
		message {
			utilityId
			name
			abbreviation
		}
	}
}
`;

export const getPurchasingLayerTypeListData = gql`
query GetPurchasingLayerTypeListData {
	purchasingLayerTypes {
		message {
			id
			name
			description
			sortOrder
		}
	}
}
`;

export const getCommissionPayPlanTypeListData = gql`
query GetCommissionPayPlanTypeListData {
	commissionPayPlanTypes {
		message {
			id
			description
			header
		}
	}
}
`;

export const getCommissionTypeListData = gql`
query GetCommissionTypeListData {
	commissionTypes {
		message {
			id
			name
			sortOrder
		}
	}
}
`;

export const getPendingCommissionRollupData = gql`
query GetPendingCommissionRollupData($contractId: String!) {
	contract(contractId: $contractId) {
		contractId
		contractNum
		confirmationDate
		units
		expirationDate
		isResidential
		serviceTypeId
		contractDate
		isGuaranteePayment
		commissionPayPlanId
		agentId
		agent {
			agentId
			name
			parentId
			brotherId
			parent {
				agentId
				name
			}
			brother {
				agentId
				name
			}
		}
		locations(sort: "sortOrder") {
			dropDate
		}
		commissionPayablesSchedules {
			agentId
		}
		state {
			country {
				isoCode
			}
		}
	}
}
`;

export const getPendingCommissionIssues = gql`
	query GetPendingCommissionIssues($pendingCommissionId: Int!) {
		pendingCommission(id: $pendingCommissionId) {
			pendingCommissionIssues{
				id
				subject
			}
		}
	}
`;

export const getPendingCommissionData = gql`
query GetPendingCommissionData($pendingCommissionId: Int!) {
	commissionTypes {
		message {
			id
			name
			sortOrder
		}
	}
	pendingCommissionRelatedContracts(pendingCommissionId: $pendingCommissionId) {
		contractId
		contractNum
		units
	}
}
`;

export const getPendingCommissionEditContractData = gql`
query GetPendingCommissionEditContractData($contractId: String!) {
	contract(contractId: $contractId) {
		contractId
		contractNum
		units
	}
}
`;

export const getPayableEditContractData = gql`
query GetPayableEditContractData($contractId: String!) {
	contract(contractId: $contractId) {
		contractId
		contractNum
		units
		customer {
			dbaName
		}
	}
}
`;

export const getCommissionPayPlanListData = gql`
query GetCommissionPayPlanListData {
	commissionPayPlanTypes {
		message {
			id
			description
			header
		}
	}
}
`;

export const getDisclaimerAddEditPageData = gql`
query GetDisclaimerAddEditPageData {
	suppliers(criteria: { isActive: true }, sort: "name") {
		message {
			supplierId
			name
		}
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
			stateShort
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	utilities(criteria: { isActive: true }, sort: "name") {
		message {
			utilityId
			name
			abbreviation
		}
	}
}
`;

export const getPurchasingLayerTypeData = gql`
query GetPurchasingLayerTypeData($id: Int!) {
	purchasingLayerType(id: $id) {
		id
		name
		description
		sortOrder
	}
}
`;

export const getCommissionPayPlanTypeData = gql`
query GetCommissionPayPlanTypeData($id: Int!) {
	commissionPayPlanType(id: $id) {
		id
		name
		description
		header
		details
	}
}
`;

export const getCommissionTypeData = gql`
query GetCommissionTypeData($id: Int!) {
	commissionType(id: $id) {
		id
		name
		sortOrder
	}
}
`;

export const getCommissionPayPlansData = gql`
query GetCommissionPayPlansData($commissionPayPlanTypeId: Int!, $criteria: CommissionPayPlanInput) {
	commissionPayPlanType(id: $commissionPayPlanTypeId) {
		description
	}
	commissionPayPlans(criteria: $criteria, sort: "contractEndDate desc") {
		message {
			id
			name
			description
			upfrontPercentage
			upfrontLag
			monthlyLag
			upfrontDateType
			hasAnniversaryUpfront
			anniversaryUpfrontDateType
			anniversaryLag
			contractStartDate
			contractEndDate
			sortOrder
			isActive
			commissionPayPlanTypeId
			interval
		}
	}
}
`;

export const getSupplierPricingAnalyticsPageData = gql`
query GetSupplierPricingAnalyticsPageData {
	suppliers(criteria: { isActive: true }, sort: "name") {
		message {
			supplierId
			name
			serviceTypes {
				serviceType {
					serviceTypeId
					name
					units
					units2
					multiplier
				}
			}
			licensePlans {
				id
			}
		}
	}
}
`;

export const getSupplierBiddingSessionStateData = gql`
query GetSupplierBiddingSessionStateData(
	$supplierId: String!,
	$performanceCriteria: SupplierAuctionMonthlyPerformanceStatsInput!,
	$rankingCriteria: SupplierAuctionRankingStatsInput!,
	$competitiveCriteria: SupplierAuctionCompetitiveStatsInput!,
	$sort: String) {
	supplier(supplierId: $supplierId) {
		monthlyAuctionPerformanceStats(criteria: $performanceCriteria) {
			date
			numAuctionsInvited
			numAuctionsParticipated
			numAuctionsWon
		}
		marketAuctionRankingStats(criteria: $rankingCriteria, sort: $sort) {
			stateLong
			stateId
			totalBidsSubmitted
			averageDelta
			firstPlace
			secondPlace
			thirdPlace
			fourthPlace
		}
		marketAuctionCompetitiveStats(criteria: $competitiveCriteria) {
			stateShort
			utilityName
		}
	}
}
`;

export const getSupplierResidentialMarketMatrixPageData = gql`
query GetSupplierResidentialMarketMatrixPageData(
	$supplierId: String!, $serviceTypeId: String!, $isResidential: Boolean!, $addDate: String!
) {
	marketMatrixFormFieldStates(criteria: {
		supplierId: $supplierId, serviceTypeId: $serviceTypeId, isResidential: $isResidential, addDate: $addDate
	}) {
		stateIds
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	products(criteria: {isActive: true}) {
		message {
			id
			name
		}
	}
}
`;

export const getSupplierMarketMatrixMaxAddDate = gql`
query GetSupplierMarketMatrixMaxAddDate {
	marketMatrixMaxAddDate {
		maxAddDate
	}
}
`;

export const getSupplierMarketMatrixPageData = gql`
query GetSupplierMarketMatrixPageData($supplierId: String!, $serviceTypeId: String!, $isResidential: Boolean!, $addDate: String!) {
	marketMatrixFormFieldStates(criteria: {
		supplierId: $supplierId, serviceTypeId: $serviceTypeId, isResidential: $isResidential, addDate: $addDate
	}) {
		stateIds
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	marketMatrixAdjustmentOptions(criteria: { supplierId: $supplierId, serviceTypeId: $serviceTypeId }) {
		selections {
			text
			min
			minInclusive
			max
			minInclusive
		}
	}
}
`;

export const getSupplierMarketMatrixUtilities = gql`
query GetSupplierMarketMatrixUtilities($supplierId: String!, $serviceTypeId: String!, $isResidential: Boolean!,
	$addDate: String!, $stateId: String) {
	marketMatrixFormFieldUtilities(criteria: {
		supplierId: $supplierId,
		serviceTypeId: $serviceTypeId,
		isResidential: $isResidential,
		addDate: $addDate,
		stateId: $stateId
	}) {
		utilityIds
	}
	utilities(sort: "name") {
		message {
			utilityId
			name
		}
	}
}
`;

export const getSupplierMarketMatrixEffectiveDates = gql`
query GetSupplierMarketMatrixEffectiveDates(
	$supplierId: String!, $serviceTypeId: String!, $isResidential: Boolean!, $addDate: String!, $stateId: String, $utilityId: String) {
	marketMatrixFormFieldEffectiveDates(criteria: {
		supplierId: $supplierId,
		serviceTypeId: $serviceTypeId,
		isResidential: $isResidential,
		addDate: $addDate,
		stateId: $stateId,
		utilityId: $utilityId
	}) {
		effectiveDates
	}
}
`;

export const getSupplierMarketMatrixStates = gql`
query GetSupplierMarketMatrixStates(
	$supplierId: String!, $serviceTypeId: String!, $isResidential: Boolean!, $addDate: String! ) {
	marketMatrixFormFieldStates(criteria: {
		supplierId: $supplierId,
		serviceTypeId: $serviceTypeId,
		isResidential: $isResidential,
		addDate: $addDate
	}) {
		stateIds
	}
	states {
		message {
			stateId
			stateLong
		}
	}
}
`;

export const getSupplierMarketMatrixTerms = gql`
query GetSupplierMarketMatrixTerms(
	$supplierId: String!, $serviceTypeId: String!, $isResidential: Boolean!, $addDate: String!, $stateId: String,
	$utilityId: String, $effectiveDate: String) {
	marketMatrixFormFieldTerms(criteria: {
		supplierId: $supplierId,
		serviceTypeId: $serviceTypeId,
		isResidential: $isResidential,
		addDate: $addDate,
		stateId: $stateId,
		utilityId: $utilityId,
		effectiveDate: $effectiveDate
	}) {
		terms
	}
}
`;

export const getSupplierMarketMatrixUsages = gql`
query GetSupplierMarketMatrixUsages(
	$supplierId: String!, $serviceTypeId: String!, $isResidential: Boolean!, $addDate: String!, $stateId: String,
	$utilityId: String, $effectiveDate: String, $term: IntString) {
	marketMatrixFormFieldUsages(criteria: {
		supplierId: $supplierId,
		serviceTypeId: $serviceTypeId,
		isResidential: $isResidential,
		addDate: $addDate,
		stateId: $stateId,
		utilityId: $utilityId,
		effectiveDate: $effectiveDate,
		term: $term
	}) {
		usages
	}
}
`;

export const getSupplierMarketMatrixRankingStats = gql`
query GetSupplierMarketMatrixRankingStats($criteria: SupplierMatrixRankingStatsInput!) {
	marketMatrixRankingStats(criteria: $criteria) {
		latestStatsReportStates {
			stateLong
			stateId
			avgAdjustment
			maxAdjustment
			minAdjustment
		}
		priceAdjustmentSummaryReport {
			numberOfCases {
				date
				increaseInMarginMoreThan5MarginNumCases
				increaseInMarginUpTo5MarginNumCases
				decreaseInMarginUpTo5MarginNumCases
				decreaseInMarginMoreThan5MarginNumCases
			}
			dates {
				date
				increaseInMarginMoreThan5MarginNumCases
				increaseInMarginMoreThan5MarginImpactSize
				increaseInMarginUpTo5MarginNumCases
				increaseInMarginUpTo5MarginImpactSize
				decreaseInMarginUpTo5MarginNumCases
				decreaseInMarginUpTo5MarginImpactSize
				decreaseInMarginMoreThan5MarginNumCases
				decreaseInMarginMoreThan5MarginImpactSize
			}
		}
	}
}
`;

export const getChaseAchFileAttachmentsData = gql`
query GetChaseAchFileAttachmentsData($sort: String, $max: Int, $offset: Int) {
	attachments(criteria: { attachmentTypeId: "1d46047f4c19416aa3903e76a8d7aed2" }, sort: $sort, max: $max, offset: $offset) {
		message {
			attachmentId
			originalName
			addDate
			userId
			user {
				fname
				lname
			}
		}
		total
		start
		end
	}
}
`;

export const getSupplierMarketMatrixRankingResidentialCompetitivePlans = gql`
query GetSupplierMarketMatrixRankingResidentialCompetitivePlans(
	$criteria: SupplierMarketMatrixRankingCompetitivePlansInput!
) {
	marketMatrixRankingResidentialCompetitivePlans(criteria: $criteria) {
		mostCompetitivePlans {
			utilityName
			term
			ptcDelta
			bestDelta
			nextDelta
			productName
			rank
		}
		leastCompetitivePlans {
			utilityName
			term
			ptcDelta
			bestDelta
			productName
		}
	}
}
`;

export const getSupplierMarketMatrixRankingStatsResidential = gql`
query GetSupplierMarketMatrixRankingStatsResidentialByState(
	$criteria: SupplierMatrixRankingStatsByStateInput!, $sort: String, $max: Int, $offset: Int
) {
	marketMatrixResidentialRankingStatsByState(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		latestStatsReport {
			id
			term
			rate
			bestRate
			bestDelta
			productName
			ptc
			ptcDelta
			bestRateOverallDelta
			bestRateOverall
		}
		latestStatsReportTotal
		latestStatsReportFields {
			terms
			productIds
		}
	}
}
`;

export const getSupplierMarketMatrixRankingStatsByState = gql`
query GetSupplierMarketMatrixRankingStatsByState(
	$criteria: SupplierMatrixRankingStatsByStateInput!, $sort: String, $max: Int, $offset: Int
) {
	marketMatrixRankingStatsByState(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		latestStatsReport {
			id
			supplierId
			utilityId
			stateId
			effectiveDate
			rateClass
			zone
			term
			usage
			rate
			rank
			bestRate
			nextRate
			bestDelta
			nextDelta
			optimizedPrice
			clusterScore
			adjustment
			utilityName
			clusterOptimizedPrice
			clusterSelectionChance
			clusterSuggestedAdjustment
		}
		latestStatsReportTotal
		latestStatsReportFields {
			effectiveDates
			terms
			usages
			utilities {
				name
				utilityId
			}
			rateClasses
			zones
		}
		impactAnalysisReport {
			dates {
				date
				increaseInMarginMoreThan5MarginNumCases
				increaseInMarginMoreThan5MarginImpactSize
				increaseInMarginUpTo5MarginNumCases
				increaseInMarginUpTo5MarginImpactSize
				decreaseInMarginUpTo5MarginNumCases
				decreaseInMarginUpTo5MarginImpactSize
				decreaseInMarginMoreThan5MarginNumCases
				decreaseInMarginMoreThan5MarginImpactSize
			}
		}
		avgRank
	}
}
`;

export const getSupplierMarketMatrixRankingStatsBreakdown = gql`
query GetSupplierMarketMatrixRankingStatsBreakdown($criteria: SupplierMatrixRankingStatsBreakdownInput!) {
	marketMatrixRankingStatsBreakdown(criteria: $criteria) {
		priceAdjustmentDistributionReportGrouped {
			groupedLabel
			averageAdjustment
		}
	}
}
`;

export const getSupplierBiddingSessionUtilityData = gql`
query GetSupplierBiddingSessionUtilityData(
	$supplierId: String!,
	$criteria: SupplierAuctionRankingStatsInput!,
	$sort: String) {
	supplier(supplierId: $supplierId) {
		marketAuctionRankingStats(criteria: $criteria, sort: $sort) {
			stateLong
			utilityName
			utilityId
			totalBidsSubmitted
			averageDelta
			firstPlace
			secondPlace
			thirdPlace
			fourthPlace
		}
	}
}
`;

export const getFeedbackTypeAddEditData = gql`
query GetFeedbackTypeAddEditData($id: Int!) {
	feedbackType(id: $id) {
		id
		name
		addDate
	}
}
`;

export const getFeedbackTypesListData = gql`
query GetFeedbackTypesListData {
	feedbackTypes(sort: "id") {
		message {
			id
			name
			addDate
		}
	}
}
`;

export const getFeedbackModalData = gql`
query GetFeedbackModalData {
	feedbackTypes {
		message {
			id
			name
		}
	}
}
`;

export const getPeakDemandData = gql`
query GetPeakDemandData {
	utilities(criteria: { isActive: true, serviceTypeId: "297ed5063d424e7b013d429edf0d0006" }, sort: "abbreviation") {
		message {
			name
			abbreviation
			utilityId
			isoId
			utilityStates {
				stateId
				utilityId
			}
		}
	}
	states(criteria: { isActive: true, countryId: 230 }, sort: "stateLong") {
		message {
			stateLong
			stateId
		}
	}
	isos(sort: "name") {
		message {
			id
			name
		}
	}
}
`;

export const getRfqSessionOptOutReasonTypesData = gql`
query GetRfqSessionOptOutReasonTypesData {
	rfqSessionOptOutReasonTypes(sort: "id") {
		message {
			id
			name
			addDate
		}
	}
}`;

export const getAttachmentBase64Data = gql`
query GetAttachmentBase64Data($attachmentId: String!) {
	attachment(attachmentId: $attachmentId) {
		name
		contentType
		contentSubType
		extension
		attachmentBase64 {
			response
		}
	}
}
`;

export const getExtendAuctionData = gql`
query GetExtendAuctionData {
	rfqSessionExtendAuctionReasonTypes(sort: "id") {
		message {
			id
			name
		}
	}
}
`;

export const getAllSecurityRules = gql`
query GetAllSecurityRules {
	securityRules {
		message {
			securityRuleId
			secureList
			whiteList
			permissions
			redirect
			match
			isActive
			role {
				name
			}
		}
	}
}
`;

export const getAgentListPageData = gql`
query GetAgentListPageData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getUserListPageData = gql`
query GetUserListPageData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getUserListData = gql`
query GetUserListData($criteria: UserInput, $sort: String, $max: Int, $offset: Int) {
	users(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			userId
			agentId
			supplierId
			fname
			lname
			username
			lastLogin
			addDate
			isActive
			roleId
			fullName
			isAdmin
			isSaas
			emails {
				email
			}
			role {
				name
			}
			agent {
				name
			}
		}
		total
		start
		end
	}
}
`;

export const getUserAgentsListData = gql`
query GetUserAgentsListData($criteria: AgentsAdvancedInput!, $sort: String, $max: Int, $offset: Int) {
	agentsAdvanced(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			agentId
			name
			isActive
			logins {
				userId
				emails {
					email
				}
			}
		}
		total
	}
}
`;

export const getContractDocuments = gql`
query ContractDocuments($criteria: DocumentInput) {
	documents(criteria: $criteria) {
		message {
			documentId
			name
			isEsign
			isActive
			isInkSign
			addDate
			attachment {
				attachmentId
				attachmentTypeId
				pageCount
			}
		}
	}
}
`;

export const getAllDocuments = gql`
query GetAllDocuments($criteria: ViewDocumentInput) {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	viewDocuments(criteria: $criteria, sort: "name") {
		message {
			documentId
			name
			description
			isEsign
			isInkSign
			isActive
			modifiedDate
			usageCount
			attachmentId
			attachmentName
		}
	}
}
`;

export const getRoleEditPageData = gql`
query GetRoleEditPageData {
	noteTypes(sort: "name") {
		message {
			noteTypeId
			name
		}
	}
	permissions(sort: "name") {
		message {
			permissionId
			name
		}
	}
	dashboards(sort: "name") {
		message {
			id
			name
		}
	}
}
`;

export const getCustomerBeginData = gql`
query GetCustomerBeginData($rfqSessionId: String!) {
	rfqSession(rfqSessionId: $rfqSessionId) {
		contract {
			contractId
			agentId
		}
	}
}
`;

export const getRatesQuotesAgentData = gql`
query GetRatesQuotesAgentData($contractId: String!) {
	contract(contractId: $contractId) {
		contractId
		agentId
	}
}
`;

export const getSingleUtilityRateClass = gql`
query GetSingleUtilityRateClass($utilityRateClassId: String!) {
	utilityRateClass(utilityRateClassId: $utilityRateClassId) {
		name
		description
		isDefault
		isActive
		stateId
		utilityId
	}
	utilities(max: 0, sort: "name") {
		message {
			utilityId
			name
		}
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
}`;

export const getDocumentEditPageData = gql`
query GetDocumentEditPageData($attachmentTypeId: String) {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	attachmentTypes(criteria: { attachmentTypeId: $attachmentTypeId }, sort: "name") {
		message {
			attachmentTypeId
			name
		}
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	suppliers(criteria: { isActive: true }, sort: "name", max: 0) {
		message {
			supplierId
			name
		}
	}
	utilities(sort: "name", max: 0) {
		message {
			utilityId
			name
		}
	}
	products(criteria: { isActive: true }, sort: "name") {
		message {
			id
			name
			serviceType {
				isActive
				name
			}
		}
	}
}
`;

export const getAllUtilityRateClasses = gql`
query GetAllUtilityRateClasses {
	utilityRateClasses(max: 0, sort: "name") {
		message {
			utilityRateClassId
			name
			description
			utilityId
			stateId
			isActive
			state {
				stateShort
			}
			utility {
				abbreviation
			}
		}
	}
}`;

export const getSingleRole = gql`
query GetSingleRole($roleId: String!) {
	role(roleId: $roleId) {
		name
		description
		level
		isActive
		permissions {
			permissionId
			name
		}
		noteTypes {
			noteTypeId
			name
		}
		dashboards {
			id
			name
		}
		dashboardsShownByDefault {
			id
			name
		}
		dashboardSelectedByDefault {
			id
			name
		}
	}
}
`;

export const getAllRoles = gql`
query GetAllRoles {
	roles(sort: "name") {
		message {
			name
			description
			isActive
			roleId
		}
	}
}`;

export const getSupplierCreateBidsUserData = gql`
query GetSupplierCreateBidsUserData($userId: String!) {
	user(userId: $userId) {
		defaultElectricityUnit
		defaultGasUnit
		isAdmin
		isSaas
		role {
			name
		}
	}
}
`;

export const getSupplierCreateBidsData = gql`
query GetSupplierCreateBidsData($rfqSessionId: String!) {
	rfqSession(rfqSessionId: $rfqSessionId) {
		id
		startDate
		startTime
		endDate
		endTime
		maxBids
		startDate2
		endDate2
		maxExtends
		instructions
		instructionsAdmin
		isCustomerInvited
		contract {
			contractId
			contractNum
			serviceTypeId
			stateId
			customerId
			supplierId
			annualUsage
			agentId
			margin
			term
			bufferMargin
			commissionSplitBufferMargin
			effectiveDate
			awardDate
			status
			units
			loadFactor
			networkLoadFactor
			customLoadFactor
			hasUsage
			isSaas
			isNewBrokerAuction
			customer {
				dbaName
			}
			serviceType {
				name
				units2
				multiplier
				products(criteria: { isActive: true }, sort: "sortOrder") {
					id
					name
					description
				}
			}
			state {
				stateShort
				countryId
				country {
					symbol
					isoCode
					symbol2
				}
			}
			supplier {
				name
			}
			attachments(attachmentTypeId: "588f51ee45ec8fe50145eddabe4a0002,588f51ee45ec8fe50145eddaf83c0003") {
				name
				attachmentTypeId
			}
			locations {
				utilityId
				annualUsage
				utility {
					name
				}
			}
		}
		suppliers {
			id
			note
			isOptOut
			supplierId
			supplier {
				name
			}
		}
		products {
			id
			productId
			term
			lag
			blockType
			blockSize
			greenPercentage
			initialFixedUsage
			product {
				id
				name
				sortOrder
			}
		}
		bids(sort: "addDate2 desc,amount") {
			id
			bandwidthPercentage
			salesTax
			supplierId
			ipAddress
			billingMethod
			rfqSessionId
			debug
			addDate
			amount
			bufferMargin
			addDate2
			units
			rfqSessionProductId
			supplier {
				name
			}
		}
		bestBids {
			rfqSessionSupplierId
			bidCount
			bandwidthPercentage
			supplierName
			salesTax
			billingMethod
			rfqSessionId
			lastBid
			lastBidDate
			rfqSessionProductId
			bestBid
			isGRTIncluded
			rank
			units
			greenPercentage
		}
	}
	blockTypes(criteria: { isActive: true }) {
		message {
			id
			name
		}
	}
}`;

export const viewSupplierDashboard = gql`
query ViewSupplierDashboard($criteria: CustomRfqSessionInput, $sort: String, $max: Int, $offset: Int) {
	viewSupplierDashboard(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			rfqSessionId
			startDate
			endDate
			supplierId
			note
			contractId
			contractNum
			contractStatus
			annualUsage
			customerId
			dbaName
			utilityAccountNumList
			utilityIdList
			utilityNameList
			serviceTypeId
			serviceTypeName
			sampleBillAttachmentIdList
			loaAttachmentIdList
			hudAttachmentIdList
			loaEsignIdList
			loaHasEsignList
			loeAttachmentIdList
			loeEsignIdList
			loeHasEsignList
			effectiveDate
			uploadDate
			awardDate
			units
			rfqSessionSupplierId
			isOptOut
			sampleBillRequestDate
			loeRequestDate
			loaRequestDate
			isSaas
		}
		total
		start
		end
	}
}`;

export const viewCompanySupplierQuickBookDeposits = gql`
query ViewCompanySupplierQuickBookDeposits($criteria: ViewCompanySupplierQuickBookDepositInput, $sort: String, $max: Int, $offset: Int) {
	viewCompanySupplierQuickBookDeposits(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			depositId
			amount
			depositDate
			balance
		}
		total
		start
		end
	}
}`;

export const getBrokerBidInfo = gql`
query GetBrokerBidInfo($agentId: String!) {
	agent(agentId: $agentId) {
		name
		isActive
		isNewBroker
		parent {
			name
			isNewBroker
		}
		stats {
			closingRatio
			auctionsClosed {
				numAuctionsClosed
				serviceTypeId
			}
			termValue
			netProfit
			contractsAwardedNotClosed
			contractsDroppedRatio
			timeSinceAddedYears
			timeSinceAddedMonths
		}
	}
}
`;

export const getDirectAuctionSupplierDashboardData = gql`
query GetDirectAuctionSupplierDashboardData($criteria: CustomRfqSessionInput) {
	viewSupplierDashboard(criteria: $criteria, max: 1, offset: 0, sort: "endDate desc") {
		message {
			sampleBillAttachmentIdList
			loaAttachmentIdList
			hudAttachmentIdList
			loeAttachmentIdList
			isSaas
		}
	}
}
`;

export const getSupplierDashboardData = gql`
query GetSupplierDashboardData($criteria: CustomRfqSessionInput, $recentCriteria: CustomRfqSessionInput) {
	viewSupplierDashboard(criteria: $criteria, max: 100, offset: 0, sort: "endDate desc") {
		message {
			rfqSessionId
			startDate
			endDate
			supplierId
			note
			contractId
			contractNum
			contractStatus
			annualUsage
			customerId
			dbaName
			utilityAccountNumList
			utilityIdList
			utilityNameList
			serviceTypeId
			serviceTypeName
			sampleBillAttachmentIdList
			loaAttachmentIdList
			hudAttachmentIdList
			loaEsignIdList
			loaHasEsignList
			loeAttachmentIdList
			loeEsignIdList
			loeHasEsignList
			effectiveDate
			uploadDate
			awardDate
			units
			rfqSessionSupplierId
			isOptOut
			stateId
			agentId
			sampleBillRequestDate
			loeRequestDate
			loaRequestDate
			loadFactor
			networkLoadFactor
			hasUsage
			displayAnnualUsage
			isSaas
		}
	}
	recentViewSupplierDashboard: viewSupplierDashboard(criteria: $recentCriteria, max: 100, offset: 0, sort: "endDate asc") {
		message {
			rfqSessionId
			startDate
			endDate
			supplierId
			note
			contractId
			contractNum
			contractStatus
			annualUsage
			customerId
			dbaName
			utilityAccountNumList
			utilityIdList
			utilityNameList
			serviceTypeId
			serviceTypeName
			sampleBillAttachmentIdList
			loaAttachmentIdList
			hudAttachmentIdList
			loaEsignIdList
			loaHasEsignList
			loeAttachmentIdList
			loeEsignIdList
			loeHasEsignList
			effectiveDate
			uploadDate
			awardDate
			units
			rfqSessionSupplierId
			isOptOut
			stateId
			agentId
			sampleBillRequestDate
			loeRequestDate
			loaRequestDate
			loadFactor
			networkLoadFactor
			hasUsage
			displayAnnualUsage
			isSaas
		}
	}
	states(criteria: { isActive: true }, sort: "stateShort, countryId desc") {
		message {
			stateId
			stateShort
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	utilities(criteria: { isActive: true }, sort: "name", max: 0) {
		message {
			utilityId
			name
		}
	}
	ediUtilities {
		message {
			utilityId
			stateId
			serviceTypeId
		}
	}
}`;

export const getSupplierArchivePageData = gql`
query GetSupplierArchivePageData {
	states(criteria: { isActive: true }, sort: "stateShort, countryId desc") {
		message {
			stateId
			stateShort
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	utilities(criteria: { isActive: true }, sort: "name", max: 0) {
		message {
			utilityId
			name
		}
	}
}
`;

export const getSupplierArchiveDataSub = gql`
query GetSupplierArchiveDataSub($criteria: CustomRfqSessionInput, $sort: String, $max: Int, $offset: Int) {
	viewSupplierDashboard(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			rfqSessionId
			startDate
			endDate
			supplierId
			note
			contractId
			contractNum
			contractStatus
			annualUsage
			customerId
			dbaName
			utilityAccountNumList
			utilityIdList
			utilityNameList
			serviceTypeId
			serviceTypeName
			sampleBillAttachmentIdList
			loaAttachmentIdList
			hudAttachmentIdList
			loaEsignIdList
			loaHasEsignList
			loeAttachmentIdList
			loeEsignIdList
			loeHasEsignList
			effectiveDate
			uploadDate
			awardDate
			units
			rfqSessionSupplierId
			isOptOut
			stateId
			agentId
			isSaas
		}
		total
		start
		end
	}
}`;

export const getSupplierAuctionData = gql`
query GetSupplierAuctionData($criteria: CustomRfqSessionInput) {
	viewSupplierDashboard(criteria: $criteria, max: 100, offset: 0, sort: "endDate asc") {
		message {
			rfqSessionId
			startDate
			endDate
			supplierId
			note
			contractId
			contractNum
			contractStatus
			annualUsage
			customerId
			dbaName
			utilityAccountNumList
			utilityIdList
			utilityNameList
			serviceTypeId
			serviceTypeName
			sampleBillAttachmentIdList
			loaAttachmentIdList
			hudAttachmentIdList
			loaEsignIdList
			loaHasEsignList
			loeAttachmentIdList
			loeEsignIdList
			loeHasEsignList
			effectiveDate
			uploadDate
			awardDate
			units
			rfqSessionSupplierId
			isOptOut
			stateId
			agentId
			isSaas
		}
	}
}`;

export const getPreviousContract = gql`
	query GetPreviousContract($contractId: String) {
		contracts(criteria: { renewalId: $contractId }) {
			message {
				contractId
			}
		}
	}
`;

export const getEsignDocumentData = gql`
query GetEsignBeginData($esignId: String!, $isCustomer: Boolean) {
  esign(esignId: $esignId) {
		esignId
		isComplete
		documents(isCustomer: $isCustomer) {
			documentId
			document {
				name
				isEsign
			}
		}
		contract {
			contractId
			agent {
				logins {
					userId
					fname
					middle
					lname
					isPrimary
					addDate
					emails {
						email
					}
				}
				fax
				phone
			}
			agentId
			attachments {
				attachmentTypeId
			}
		}
	}
}`;

export const getCustomerContractPageData = gql`
query GetCustomerContractPageData($criteria: ContractInput) {
	contracts(criteria: $criteria) {
		message {
			contractId
			agentId
			contractNum
			term
			expirationDate
			serviceTypeId
			usePurchasingLayerStartDate
			customer {
				customerId
				dbaName
			}
		}
	}
}`;

export const getCustomerContractDashboardData = gql`
query GetCustomerContractDashboardData($customerId: String!) {
	customerContractDashboard(customerId: $customerId) {
		agentId
		layeredPurchasingContracts {
			contractId
			agentId
			contractNum
			term
			expirationDate
			serviceTypeId
			customer {
				customerId
				dbaName
			}
		}
		contractPerformanceContracts {
			contractId
			agentId
			contractNum
			term
			expirationDate
			serviceTypeId
			customer {
				customerId
				dbaName
			}
		}
	}
}`;

export const getSingleFaq = gql`
query GetSingleFaq($id: Int!) {
	faq(id: $id) {
		id
		question
		answer
		brokerRoute
		adminRoute
		isDefault
		faqCategoryId
		isParentOnly
		isSubAgentOnly
		isActive
		keywords {
			name
		}
	}
}
`;

export const getFaqData = gql`
query GetFaqData($id: Int!) {
	faq(id: $id) {
		id
		question
		answer
		brokerRoute
		adminRoute
		isDefault
		faqCategoryId
		keywords {
			name
		}
	}
}
`;

export const getEducationData = gql`
query GetEducationData($id: Int!) {
	education(id: $id) {
		id
		subTitle
		educationTypeId
		thumbnailAttachmentId
		path
		agentId
		addDate
		topics {
			category {
				id
				name
			}
		}
	}
}
`;

export const getEducationAddEditData = gql`
query GetEducationAddEditData($id: Int!) {
	education(id: $id) {
		id
		subTitle
		educationTypeId
		thumbnailAttachmentId
		path
		isDefault
		isActive
		utilityId
		topics {
			educationCategoryId
		}
		keywords {
			name
		}
		thumbnail {
			attachmentId
			attachmentTypeId
			name
			originalName
		}
	}
}
`;

export const getFaqEditPageData = gql`
query GetFaqEditPageData {
	faqCategories(sort: "name") {
		message {
			id
			name
		}
	}
}
`;

export const getEducationEditPageData = gql`
query GetEducationEditPageData {
	educationCategories(sort: "name") {
		message {
			id
			name
		}
	}
	educationTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	utilities(criteria: { isActive: true }, sort: "name") {
		message {
			utilityId
			name
			abbreviation
			sampleBillAttachment {
				attachmentId
				path
			}
		}
	}
}
`;

export const getDocumentEditPageDocumentData = gql`
query GetDocumentEditPageDocumentData($documentId: String!) {
	document(documentId: $documentId) {
		attachmentId
		name
		description
		minUsage
		maxUsage
		isEsign
		isInkSign
		isActive
		companyId
		suppliers {
			supplierId
			name
		}
		utilities {
			utilityId
			name
		}
		attachment {
			attachmentId
			attachmentTypeId
			name
		}
		states {
			stateId
			stateLong
		}
		products {
			id
			name
			serviceType {
				name
			}
		}
	}
}`;

export const searchTinyUris = gql`
query SearchTinyUris($slug: String) {
	tinyUris(criteria: { slug: $slug }, sort: "addDate desc") {
		message {
			slug
			longUri
		}
	}
}`;

export const getNewPaymentData = gql`
query GetNewPaymentData($contractId: String!) {
	contract(contractId: $contractId) {
		depositRequiredAmount
		depositPaidAmount
	}
}`;

export const getMenuEditPageData = gql`
query GetMenuEditPageData {
	menus(sort: "name") {
		message {
			menuId
			name
		}
	}
	menuTypes(sort: "name") {
		message {
			menuTypeId
			name
		}
	}
	permissions(sort: "name") {
		message {
			permissionId
			name
		}
	}
}
`;

export const getSupplierEnrollmentSettings = gql`
query GetSupplierEnrollmentSettings(
	$supplierId: String!,
	$supplierRateSettingsCriteria: SupplierRateSettingInput,
	$securityQuestionsCriteria: SupplierSecurityQuestionInput,
	$esignFontsCriteria: SupplierEsignFontInput
) {
	supplierRateSettings(criteria: $supplierRateSettingsCriteria) {
		message {
			showDepositDisclaimer
			depositDisclaimerNote
			depositPhone
			captureSignatureAtRSEnrollment
			showOhepNotice
		}
	}
	supplier(supplierId: $supplierId) {
		securityQuestions(criteria: $securityQuestionsCriteria) {
			id
			text
		}
		esignFonts(criteria: $esignFontsCriteria) {
			value
			fontFamily
			fontStyle
		}
	}
}
`;

export const residentialEnrollData = gql`
query ResidentialEnrollData($contractId: String!) {
	ipAddress
	contract(contractId: $contractId) {
		contractId
		planName
		stateId
		rate
		status
		serviceTypeId
		annualUsage
		disclaimerPath
		eflPath
		tosPath
		yracPath
		summaryPath
		edlPath
		term
		greenPercentage
		agentId
		margin
		bufferMargin
		isResidential
		externalId
		supplierId
		productId
		previousRate
		units
		switchTypeCode
		cancellationPenaltyDesc
		productHeader
		productDescription
		previousAnnualUsage
		monthlyServiceFee
		dailyServiceFee
		bandwidthPercentage
		billingMethod
		tdspKwhCharge
		tdspBaseCharge
		baseFee1Dollars
		energyRate
		residentialSupplierEnrollmentProperties
		customer {
			preferredContactMethod
			isEBill
		}
		locations {
			utilityId
			rateClass
			zone
			utility {
				baseRates(sort: "effectiveDate desc") {
					effectiveDate
					baseRate
					ptc
					zone
					rateClass
					stateId
					serviceTypeId
				}
			}
		}
		supplier {
			name
			puct
			logo
			hasAPI
		}
		agent {
			electricityResidentialThankYouLink
			gasResidentialThankYouLink
			logins(sort: "lastLogin desc") {
				ipAddress
			}
		}
		state {
			country {
				isoCode
			}
		}
	}
}`;

export const getSupplierUploadEsignAttachmentData = gql`
query GetSupplierUploadEsignAttachmentData($esignId: String!, $isCustomer: Boolean) {
	esign(esignId: $esignId) {
		esignId
		isComplete
		contractId
		documents(isCustomer: $isCustomer) {
			documentId
			document {
				name
				isEsign
				attachment {
					attachmentTypeId
				}
			}
		}
		contract {
			contractId
			supplierId
			contractNum
			term
			greenPercentage
			effectiveDate
			rate
			agentId
			status
			isNewBrokerAuction
			companyId
			customer {
				dbaName
			}
			serviceType {
				name
			}
		}
	}
}`;

export const getSupplierUploadContractAttachmentData = gql`
query GetSupplierUploadContractAttachmentData($contractId: String!) {
	contract(contractId: $contractId) {
		supplierId
		contractNum
		term
		greenPercentage
		effectiveDate
		rate
		agentId
		status
		isNewBrokerAuction
		companyId
		customer {
			dbaName
		}
		serviceType {
			name
		}
	}
}`;

export const getSingleMenu = gql`
query GetSingleMenu($menuId: String!) {
	menu(menuId: $menuId) {
		name
		parentId
		menuTypeId
		permissions
		eventName
		queryString
		idName
		className
		iconClassName
		thumbnail
		sortOrder
		description
		isActive
	}
	menus(criteria: { isActive: true }, sort: "name") {
		message {
			menuId
			name
			menuType {
				name
			}
		}
	}
	menuTypes(sort: "name") {
		message {
			menuTypeId
			name
		}
	}
	permissions(sort: "name") {
		message {
			permissionId
			name
		}
	}
}
`;

export const getAllMenus = gql`
query GetAllMenus {
	menus(sort: "name") {
		message {
			menuId
			name
			eventName
			isActive
			parent {
				name
			}
			menuType {
				name
			}
		}
	}
}
`;

export const getTicketCategoryEditPageData = gql`
query GetTicketCategoryEditPageData {
	roles {
		message {
			roleId
			name
		}
	}
	users(criteria: { roleId: "297ed5063d288ec7013d290e2dab003e,297ed5063d288ec7013d290e2dc7003f", isActive: true }, sort: "fname") {
		message {
			userId
			fname
			lname
			fullName
		}
	}
}
`;

export const getSingleTicketCategory = gql`
query GetSingleTicketCategory($id: Int!) {
	ticketCategory(id: $id) {
		name
		description
		assignedToUserId
		isActive
		priority
		roles {
			roleId
			name
		}
	}
	roles {
		message {
			roleId
			name
		}
	}
	users(criteria: { roleId: "297ed5063d288ec7013d290e2dab003e,297ed5063d288ec7013d290e2dc7003f" }, sort: "fname") {
		message {
			userId
			fname
			lname
			fullName
		}
	}
}
`;

export const getAllTicketCategories = gql`
query GetAllTicketCategories {
	ticketCategories(sort: "name") {
		message {
			id
			name
			description
			isActive
			assignedToUser {
				fname
				lname
				fullName
			}
		}
	}
}
`;

export const getAllTimezones = gql`
query GetAllTimezones {
	timezones(sort: "name") {
		message {
			id
			name
			abbreviation
		}
	}
}
`;

export const getEmailCriteriaEditPageData = gql`
query GetEmailCriteriaEditPageData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	suppliers(sort: "name", max: 0) {
		message {
			supplierId
			name
			parentId
		}
	}
	emailCriteriaTypes(sort: "sortOrder") {
		message {
			id
			description
		}
	}
}
`;

export const getEmailCriteriaEditEmailAccountsData = gql`
query GetEmailCriteriaEditEmailAccountsData($companyId: String) {
	emailAccounts(criteria: { companyId: $companyId }, sort: "username") {
		message {
			emailAccountId
			username
		}
	}
}`;

export const getEmailCriteria = gql`
query GetEmailCriteria($emailCriteriaId: String!) {
	emailCriteria(emailCriteriaId: $emailCriteriaId) {
		emailCriteriaId
		name
		folderName
		emailAccountId
		from
		subject
		attachment
		isActive
		emailCriteriaTypeId
		supplierId
		emailAccount {
			companyId
		}
	}
}
`;

export const listEmailCriterias = gql`
query ListEmailCriterias($criteria: EmailCriteriaInput, $sort: String, $max: Int, $offset: Int) {
	emailCriterias(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			emailCriteriaId
			name
			emailAccountId
			folderName
			from
			subject
			attachment
			isActive
			emailCriteriaTypeId
			emailCriteriaType {
				description
			}
		}
		total
	}
}
`;

export const getSingleEmailAccount = gql`
query GetSingleEmailAccount($emailAccountId: String!) {
	emailAccount(emailAccountId: $emailAccountId) {
		port
		password
		server
		secure
		username
		emailAccountId
		isActive
		companyId
	}
}
`;

export const getEmailAccountListPageData = gql`
query GetEmailAccountListPageData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const listEmailAccounts = gql`
query ListEmailAccounts($criteria: EmailAccountInput, $sort: String, $max: Int, $offset: Int) {
	emailAccounts(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			emailAccountId
			port
			password
			server
			secure
			username
			isActive
		}
		total
	}
}
`;

export const getUtilityEditData = gql`
query GetUtilityEditData($utilityId: String!) {
	utility(utilityId: $utilityId) {
		utilityId
		name
		parentId
		abbreviation
		email
		margin
		margin2
		accountNumLabel
		accountNumFormat
		accountNumFormatHelp
		showNameKey
		nameKeyFormat
		nameKeyLabel
		nameKeyFormatHelp
		showReferenceNum
		referenceNumFormat
		referenceNumLabel
		referenceNumFormatHelp
		showMeterNum
		meterNumFormat
		meterNumLabel
		meterNumFormatHelp
		leadTime
		isActive
		isoId
		defaultDaysUntilAuctionEnd
		utilityStates {
			stateId
		}
		utilityServiceTypes {
			serviceTypeId
		}
		sampleBillAttachment {
			attachmentId
			name
		}
	}
}
`;

export const getUtilityGeneralEditPageData = gql`
query GetUtilityGeneralEditPageData {
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	serviceTypes(sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	parentUtilities: utilities(max: 0, sort: "name") {
		message {
			utilityId
			name
		}
	}
	isos(sort: "name") {
		message {
			id
			name
		}
	}
}
`;

export const getProductsGroupedByServiceType = gql`
query GetProductsGroupedByServiceType {
	serviceTypes(sort: "name") {
		message {
			name
			serviceTypeId
			products(sort: "sortOrder") {
				name
				description
				isActive
				id
				sortOrder
			}
		}
	}
}
`;

export const getAllUtilities = gql`
query GetAllUtilities {
	utilities(max: 0, sort: "name") {
		message {
			utilityId
			name
			parentId
			abbreviation
			isActive
		}
	}
}
`;

export const getAllISOs = gql`
query GetAllISOs {
	isos(max: 0, sort: "name") {
		message {
			name
			id
		}
	}
}
`;

export const getSingleISO = gql`
query GetSingleISO($id: Int!) {
	iso(id: $id) {
		name
		id
	}
}
`;

export const getAllSuppliers = gql`
query getAllSuppliers($criteria: SupplierInput, $sort: String, $max: Int, $offset: Int) {
	suppliers(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			supplierId
			name
			phone
			email
			website
			isActive
			parentId
			parent {
				name
			}
		}
		total
		start
		end
	}
}
`;

export const getSupplierInfoPageData = gql`
query GetSupplierInfoPageData($supplierId: String!) {
	supplier(supplierId: $supplierId) {
		name
		contactFname
		contactMiddle
		contactLname
		address1
		address2
		city
		zipCode
		email
		phone
		phone2
		fax
		mobile
		state {
			stateShort
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getSupplierInfoPageLoginsData = gql`
query GetSupplierInfoPageLoginsData($supplierId: String, $companyId: String) {
	users(criteria: { supplierId: $supplierId, companyId: $companyId }) {
		message {
			userId
			username
			lastLogin
			addDate
			isActive
			agentId
			supplierId
			phone
			emails {
				email
			}
		}
	}
}
`;

export const getSupplierDashboardSupplierData = gql`
query GetSupplierDashboardSupplierData($supplierId: String!) {
	supplier(supplierId: $supplierId) {
		name
	}
}
`;

export const getCompanySupplierEditData = gql`
query GetCompanySupplierEditData($supplierId: String, $companyId: String) {
	companySuppliers(criteria: { supplierId: $supplierId, companyId: $companyId }) {
		message {
			id
			companyId
			contactFname
			contactMiddle
			contactLname
			phone
			phone2
			phone3
			fax
			mobile
			email
			contractSubmissionEmail
			contractSubmissionEmail2
			commercialElectricCommissionEmail
			commercialGasCommissionEmail
			residentialCommissionEmail
			residentialContractSubmissionEmail
			shouldCommissionAutoImport
			commissionIsDirectSale
			shouldCommissionAutoErrorCheck
			flowCheckEmail
			reinstatementEmail
			purchasingLayerIndicativeHedgedPricingEmail
			commissionRules {
				id
				companyId
				supplierId
				serviceTypeId
				contractStartDate
				contractEndDate
				hasCommercial
				upfrontPercentage
				upfrontLag
				monthlyLag
				upfrontDateType
				showUpfrontRequest
				hasAnniversaryUpfront
				anniversaryUpfrontDateType
				minUpfrontTermFactor
				maxUpfrontTermFactor
				hasResidential
				hasResidentialUpfront
				residentialPayLag
				residentialResidualLag
				residentialPayAmount
				residentialResidualAmount
				residentialMargin
				marginCap
				marginSplit
				commissionCutoff
				residentialDescription
				commercialDescription
				discountFactor
				maxAdvanceMonths
				maxUpfrontAmount
				maxUpfrontAnnualUsage
				lagCutOffDay
				anniversaryLag
				clawbackLag
				reinstatementLag
				residualFactor
				paymentInterval
				acceptsResidentialRenewals
				residentialRenewalPayLag
				residentialRenewalResidualLag
				residentialRenewalUpfrontAmount
				residentialRenewalResidualAmount
				residentialRenewalMargin
				residentialRenewalWindow

				states {
					stateId
				}
			}
			communitySolarSetting {
				id
				repId
				repFullName
				repPhone
				repEmail
				signupPortalUrl
				signupPortalPromoCode
				signupConfirmationText
				statusCheckPortalUrl
				statusCheckPortalUsername
				statusCheckPortalPassword
			}
			portals {
				id
				companySupplierPortalTypeId
				url
				username
				password
			}
		}
	}
}
`;

export const getSupplierEditGeneralData = gql`
query GetSupplierEditGeneralData($supplierId: String!) {
	supplier(supplierId: $supplierId) {
		supplierId
		name
		address1
		address2
		city
		stateId
		zipCode
		website
		puct
		electricBufferMargin
		gasBufferMargin
		logo
		underwriterId
		hasAPI
		isActive
		parentId
		maxNumLocations
		supplierReinstatementTypeId
		reinstatementFormAttachmentId
		personalGuaranteeAttachmentId
		businessGuaranteeAttachmentId
		logoAttachmentId
		supplierClassId
		loadFactorTypeId
		hasConfirmationMapping
		hasFlowCheckMapping
		allowCommercialMatrixRenewal
		licensePlans {
			id
			name
		}
		supplierReinstatementType {
			name
		}
		supplierGuaranteeProgram {
			name
		}
		supplierClass {
			name
		}
		loadFactorType {
			name
		}
		underwriter {
			fname
			lname
		}
		state {
			stateShort
		}
		parent {
			name
		}
		attachments {
			supplierId
			attachmentId
			attachmentTypeId
			description
			size
			name
			originalName
		}
		serviceTypes {
			serviceType {
				serviceTypeId
				name
			}
		}
	}
}
`;

export const getSupplierEditData = gql`
query GetSupplierEditData($supplierId: String!) {
	supplier(supplierId: $supplierId) {
		name
		parent {
			name
		}
	}
}
`;

export const getSupplierAttachmentData = gql`
query getSupplierAttachmentData($attachmentId: String!) {
	attachment(attachmentId: $attachmentId) {
		attachmentId
		name
		description
		size
		attachmentTypeId
		signatureDate
	}
}
`;

export const sendMissingSupplierEmail = gql`
	query sendMissingSupplierEmail($suppliers: [SupplierInput]) {
		sendMissingSupplierEmail(suppliers: $suppliers) {
			response
		}
	}
`;

export const getSupplierEditDocumentsData = gql`
query GetSupplierEditDocumentsData($supplierId: String, $companyId: String) {
	attachments(criteria: { supplierId: $supplierId, companyId: $companyId }) {
		message {
			originalName
			description
			attachmentId
			attachmentTypeId
			attachmentType {
				name
			}
			addDate
			signatureDate
		}
	}
}
`;

export const getSupplierEditDocumentsPageData = gql`
query GetSupplierEditDocumentsPageData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getSupplierEditCompanySettingsPageData = gql`
query GetSupplierEditCompanySettingsPageData($supplierId: String!) {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	supplier(supplierId: $supplierId) {
		serviceTypes {
			serviceType {
				serviceTypeId
				name
			}
		}
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	companySupplierPortalTypes(sort: "name") {
		message {
			id
			name
		}
	}
}
`;

export const getSupplierEditGeneralPageData = gql`
query GetSupplierEditGeneralPageData {
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	licensePlans(criteria: { licensePlanTypeId: 2 }) {
		message {
			id
			name
		}
	}
}
`;

export const getSupplierGeneralEditPageData = gql`
query GetSupplierGeneralEditPageData {
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	users(criteria: { roleId: "8a258005641ce83201641cef2db30000"}, sort: "fname") {
		message {
			lname
			fname
			userId
		}
	}
	suppliers(sort: "name") {
		message {
			supplierId
			name
		}
	}
	supplierReinstatementTypes(sort: "name") {
		message {
			id
			name
		}
	}
	supplierClasses(sort: "name") {
		message {
			id
			name
		}
	}
	loadFactorTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
}
`;

export const getAllProducts = gql`
query GetAllProducts {
	products {
		message {
			id
			isActive
			description
			serviceTypeId
			name
			term
		}
	}
}
`;

export const getProductEditPageData = gql`
query GetProductEditPageData {
	serviceTypes(sort: "name") {
		message {
			name
			serviceTypeId
		}
	}
	states(sort: "stateLong") {
		message {
			stateLong
			stateId
		}
	}
	suppliers(sort: "name") {
		message {
			name
			supplierId
		}
	}
	productTypes(sort: "name") {
		message {
			id
			name
		}
	}
}
`;

export const getProduct = gql`
query GetProduct($productId: String!) {
	product(id: $productId) {
		name
		description
		term
		isActive
		serviceTypeId
		sortOrder
		productTypeId
		suppliers {
			supplierId
		}
		states {
			stateId
		}
	}
}
`;

export const getSingleNoteType = gql`
query GetSingleNoteType($noteTypeId: String!) {
	noteType(noteTypeId: $noteTypeId) {
		name
		description
		emailToSupervisor
		contractRemoveUnderwriter
		contractSetUnderwriterToSupervisor
		isForPendingCommission
		noteTypeCategoryId
		imageIconPrefix
		imageIcon
		details
	}
}
`;

export const getPendingCommissionNotes = gql`
	query GetPendingCommissionNotes($pendingCommissionId: Int!) {
		notes(criteria: { pendingCommissionId: $pendingCommissionId }, sort: "addDate asc") {
			message {
				noteId
				noteTypeId
				subject
				body
			}
		}
		noteTypes(criteria: { isForPendingCommission: true }) {
			message {
				noteTypeId
				name
			}
		}
		noteTypeCategories {
			message {
				id
				name
			}
		}
	}
`;

export const getSupplierCommissionAttachmentNotes = gql`
	query GetSupplierCommissionAttachmentNotes($supplierCommissionAttachmentId: Int!) {
		notes(criteria: { supplierCommissionAttachmentId: $supplierCommissionAttachmentId }, sort: "addDate asc") {
			message {
				noteId
				noteTypeId
				subject
				body
			}
		}
		noteTypes(criteria: { isForPendingCommission: true }) {
			message {
				noteTypeId
				name
			}
		}
		noteTypeCategories {
			message {
				id
				name
			}
		}
	}
`;

export const getInvoiceNotes = gql`
	query GetSupplierCommissionAttachmentNotes($invoiceId: Int!, $noteTypeCategoryId: Int!) {
		notes(criteria: { invoiceId: $invoiceId }, sort: "addDate asc") {
			message {
				noteId
				noteTypeId
				subject
				body
			}
		}
		noteTypes(criteria: { noteTypeCategoryId: $noteTypeCategoryId }) {
			message {
				noteTypeId
				name
			}
		}
		noteTypeCategories {
			message {
				id
				name
			}
		}
	}
`;

export const getNoteTypes = gql`
query GetNoteTypes($criteria: NoteTypeInput, $sort: String) {
	noteTypes(criteria: $criteria, sort: $sort) {
		message {
			noteTypeId
			name
			description
			noteTypeCategoryId
		}
	}
	noteTypeCategories {
		message {
			id
			name
		}
	}
}
`;

export const getAttachmentData = gql`
query GetAttachmentData($attachmentId: String!) {
	attachment(attachmentId: $attachmentId) {
		attachmentId
		addDate
		name
		description
		attachmentType {
			name
		}
		user {
			fname
			lname
			middle
		}
	}
}
`;

export const getAttachmentTypeEditData = gql`
query GetAttachmentTypeEditData($attachmentTypeId: String!) {
	attachmentType(attachmentTypeId: $attachmentTypeId) {
		attachmentTypeId
		name
		description
		slug
		isActive
	}
}
`;

export const getContractAttachmentAddModalData = gql`
query GetContractAttachmentAddModalData($attachmentCriteria: AttachmentTypeInput, $documentCriteria: DocumentInput) {
	attachmentTypes(criteria: $attachmentCriteria) {
		message {
			attachmentTypeId
			name
			description
			slug
			isActive
		}
	}
	documents(criteria: $documentCriteria) {
		message {
			attachmentId
		}
	}
}
`;

export const getAttachmentTypesListData = gql`
query GetAttachmentTypesListData($criteria: AttachmentTypeInput) {
	attachmentTypes(criteria: $criteria) {
		message {
			attachmentTypeId
			name
			description
			slug
			isActive
		}
	}
}
`;

export const getCompanyAddEditPageData = gql`
query GetCompanyAddEditPageData {
	states(criteria: { isActive: true }, sort: "stateShort asc") {
		message {
			stateId
			stateShort
			stateLong
		}
	}
	countries(sort: "name") {
		message {
			id
			name
			code
		}
	}
	emailAddressTypes(sort: "description") {
		message {
			id
			description
		}
	}
	licensePlans(criteria: { licensePlanTypeId: 3 }) {
		message {
			id
			name
		}
	}
	users(criteria: { isActive: true }, sort: "lname,fname") {
		message {
			userId
			roleId
			fullName
			isAccountManager
		}
	}
}
`;

export const getDefaultCompanyEditData = gql`
query GetDefaultCompanyEditData($companyId: String!) {
	company(companyId: $companyId) {
		emailAddresses {
			email
			emailAddressTypeId
		}
	}
}
`;

export const getCompanyEditData = gql`
query GetCompanyEditData($companyId: String!) {
	company(companyId: $companyId) {
		companyId
		modifiedDate
		name
		contactFname
		contactMiddle
		contactLname
		address1
		address2
		city
		zipCode
		stateId
		phone
		phone2
		fax
		mobile
		email
		supportEmail
		isActive
		addDate
		termsOfServiceAttachmentId
		defaultNavbarLogoId
		defaultFooterLogoId
		defaultEmailLogoId
		countryId
	  phoneCountryId
	  phone2CountryId
	  mobileCountryId
	  faxCountryId
	  phoneExt
	  phone2Ext
	  faxExt
	  commissionRate
	  commissionRate2
	  commissionRate3
	  discountFactor
	  isDecoupled
	  domainAlias
		supplierDomainAlias
		automatedUserId
		defaultAccountManagerId
		defaultAgentComplianceDropPredictionThreshold
		defaultQuickPayRate
		coverPageAttachmentId
		licensePlans {
			id
			name
		}
		attachments(sort: "addDate desc") {
			attachmentId
			attachmentTypeId
			size
			name
			originalName
			companyId
		}
		defaultNavbarLogo {
			attachmentId
			attachment {
				attachmentId
				originalName
				path
			}
		}
		defaultFooterLogo {
			attachmentId
			attachment {
				attachmentId
				originalName
				path
			}
		}
		defaultEmailLogo {
			attachmentId
			attachment {
				attachmentId
				originalName
				path
			}
		}
		emailAddresses {
			id
			email
			emailAddressTypeId
		}
		calendlySettings {
			id
			name
			link
			sortOrder
		}
		lendingModuleSetting {
			id
			borrowingRate
			baseFee
			minRate
			maxRate
			maxUpfrontDropRatio
			maxUpfrontPayment
			maxAdvanceMonths
			defaultBrokerDropRatio
			newBrokerMaxFlowedTermValue
			newBrokerMaxTotalTermValue
			newBrokerMaxActiveMonths
			maxQuickPayUpfrontPayment
		}
	}
}
`;

export const getCompanyListData = gql`
query GetCompanyListData($sort: String) {
	companies(sort: $sort) {
		message {
			companyId
			name
			city
			stateId
			phone
			email
			isActive
			state {
				stateShort
				stateLong
			}
		}
	}
}
`;

export const getCompanyEmailData = gql`
query GetCompanyEmailData($companyId: String!) {
	company(companyId: $companyId) {
		emailAddresses {
			id
			email
			emailAddressTypeId
		}
	}
}
`;

export const getCompanyCalendlySettingsData = gql`
query GetCompanyCalendlySettingsData($companyId: String!) {
	company(companyId: $companyId) {
		calendlySettings {
      name
      link
      sortOrder
    }
	}
}
`;

export const getFaqListData = gql`
query GetFaqListData {
	faqs {
		message {
			id
			faqCategoryId
			question
			answer
			isDefault
			isActive
			category {
				name
			}
		}
	}
}`;

export const getEducationListData = gql`
query GetEducationListData {
	educationCategories(criteria: { idNE: "9" }, sort: "name") {
		message {
			name
			topics {
				education {
					id
					subTitle
					isDefault
					isActive
					agentId
					type {
						name
					}
				}
			}
		}
	}
}`;

export const getFaqPageData = gql`
query GetFaqPageData {
	faqs(criteria: { faqCategoryIdNE: 1, isDefault: true }) {
		message {
			id
			question
			answer
			brokerRoute
			adminRoute
			faqCategoryId
			category {
				name
			}
		}
	}
	faqCategories(sort: "sortOrder") {
		message {
			id
			name
			iconClassName
		}
	}
}`;

export const getEducationPageData = gql`
query GetEducationPageData {
	educations(criteria: { isDefault: true, isActive: true }) {
		message {
			id
			subTitle
			thumbnailAttachmentId
			path
			addDate
			pageId
			topics {
				category {
					name
				}
			}
		}
	}
	educationCategories(sort: "sortOrder") {
		message {
			id
			name
			iconClassName
		}
	}
	educationTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
}`;

export const getContractSupplierCompensationReportData = gql`
query GetContractSupplierCompensationReportData($reportId: String!, $criteria: SupplierCommissionRulesReportInput) {
	supplierCommissionRulesReport(reportId: $reportId, criteria: $criteria) {
		commercial {
			upfrontPercentage
			paymentRule
			description
			rating
			commodity
			marginSplitRule
			supplier
			marginCapRule
			paymentAmount
			paymentTimeLag
			residualPaymentRule
			upfrontPaymentRule
			upfront50PaymentRule
			quickPayUpfrontPaymentRule
			quickPayUpfront50PaymentRule
			quickPayDescription
		}
		residential {
			paymentRule
			description
			rating
			commodity
			marginSplitRule
			supplier
			marginCapRule
			paymentAmount
			paymentTimeLag
		}
	}
}
`;

export const getContractCommissionForecastedPayables = gql`
	query GetContractCommissionForecastedPayables($criteria: ViewPayableForecastInput, $sort: String, $max: Int, $offset: Int) {
		viewPayableForecasts(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
			message {
				id
				utilityAccountNum
				amount
				cycleStartDate
				cycleEndDate
				usage
				releaseDate
				date
				dateOffset
			}
			total
			start
			end
		}
	}
`;

export const getContractCommissionForecastedReceivables = gql`
	query GetContractCommissionForecastedReceivables($criteria: ViewReceivableForecastInput, $sort: String, $max: Int, $offset: Int) {
		viewReceivableForecasts(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
			message {
				id
				utilityAccountNum
				amount
				meterStartDate
				meterEndDate
				usage
				originalDate
				date
				dateOffset

			}
			total
			start
			end
		}
	}
`;

export const exportViewCommissionReports = gql`
query ExportViewCommissionReports($criteria: ViewCommissionReportDashboardInput) {
	exportViewCommissionReports(criteria: $criteria) {
		response
	}
}
`;

export const getViewCommissionReportDashboard = gql`
	query GetViewCommissionReportDashboard($criteria: ViewCommissionReportDashboardInput) {
		viewCommissionReportDashboard(criteria: $criteria) {
			data {
				companySupplierId
				supplierId
				supplierName
				isoCode
				totalReports
				hasExchangeRate
				hasFlaggedReport
				hasPendingReport
				hasHighReceivableDeviation
				forecastedAmount
				reportDeviation
				totalActuals
				receivableDeviation
				received
			}
			stats {
				forecasted
				actuals
				reportDeviation
				received
				receivableDeviation
			}
		}
	}
`;

export const getReceivedMappingSupplierCommissionAttachments = gql`
	query GetReceivedMappingSupplierCommissionAttachments($companySupplierId: Int!) {
		companySupplier(id: $companySupplierId) {
			deposits {
				id
				amount
				date
			}
		}
	}
`;

/* eslint-disable */
export const getViewSupplierCommissionAttachmentDeposits = gql`
	query GetViewSupplierCommissionAttachmentDeposits($criteria: ViewSupplierCommissionAttachmentDepositInput, $sort: String, $max: Int, $offset: Int) {
		viewSupplierCommissionAttachmentDeposits(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
			message {
				id
				supplierCommissionAttachmentId
				depositId
				isApproved
				paymentAmount
				depositAmount
				depositDate
				reportAmount
				reportDate
			}
			total
			start
			end
		}
	}
`;
/* eslint-enable */

export const getCompanySupplierCommissionAttachments = gql`
	query GetCompanySupplierCommissionAttachments($criteria: ViewCommissionReportInput, $sort: String, $max: Int, $offset: Int) {
		viewCommissionReports(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
			message {
				id
				description
				status
				statusId
				uploadDate
				reportDate
				amount
				exchangeRate
				isoCode
				sender
				email
				flaggedReason
				companyId
				totalNotes
				showHighReceivableDeviation
				parentCompanySupplierId
				supplierName
				attachmentId
			}
			total
			start
			end
		}
	}
`;

export const getViewPricingAnalyticsUtilityPerformances = gql`
	query GetViewPricingAnalyticsUtilityPerformances(
		$criteria: ViewPricingAnalyticsUtilityPerformanceInput, $sort: String, $max: Int, $offset: Int
	) {
		viewPricingAnalyticsUtilityPerformanceList(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
			filterOptions {
				states {
					stateShort
					stateId
				}
				utilityNames
			}
			message {
				utilityRanking
				stateShort
				stateId
				utilityNames
				optimalScoreWholePercentage
				lostMargin
				marketShareWholePercentage
			}
			total
			start
			end
		}
	}
`;

export const getPricingAnalyticsMatrixCompetitiveUtilityPerformances = gql`
	query GetPricingAnalyticsMatrixCompetitiveUtilityPerformances($criteria: PricingAnalyticsMatrixCompetitiveUtilityPerformancesInput!) {
		pricingAnalyticsMatrixCompetitiveUtilityPerformances(criteria: $criteria) {
			businessPerformances {
				optimalScore
				optimalScoreDiff
				marketShare
				marketShareDiff
				lostMargin
				lostMarginDiff
				clusterScore
				clusterScoreDiff
			}
			businessPerformancesCharts {
				name
				businessPerformancesChart {
					minX
					dataPoints {
						date
						data
						hideTooltip
					}
				}
			}
			mostOptimalUtilities {
				utilityNames
				stateShort
				optimalScoreWholePercentage
				lostMargin
				marketShareWholePercentage
			}
			leastOptimalUtilities {
				utilityNames
				stateShort
				optimalScoreWholePercentage
				lostMargin
				marketShareWholePercentage
			}
		}
	}
`;

export const getPricingAnalyticsMatrixBusinessPerformancesChart = gql`
	query GetPricingAnalyticsMatrixBusinessPerformancesChart($criteria: PricingAnalyticsMatrixBusinessPerformancesChartInput) {
		pricingAnalyticsMatrixBusinessPerformancesChart(criteria: $criteria) {
			minX
			dataPoints {
				date
				data
				hideTooltip
			}
		}
	}
`;

export const getPricingAnalyticsSupplierSettingDashboardUtilityOptions = gql`
	query GetPricingAnalyticsSupplierSettingDashboardUtilityOptions($supplierId: String!, $stateId: String!) {
		pricingAnalyticsSupplierSettingDashboardUtilityOptions(supplierId: $supplierId, stateId: $stateId) {
			id
			oldName
			stateId
			utilityId
			zone
		}
	}
`;

/* eslint-disable */
export const getPricingAnalyticsSupplierSettingDashboardRateClassOptions = gql`
	query GetPricingAnalyticsSupplierSettingDashboardRateClassOptions($supplierId: String!, $stateId: String!, $utilityId: String!, $zone: String) {
		pricingAnalyticsSupplierSettingDashboardRateClassOptions(supplierId: $supplierId, stateId: $stateId, utilityId: $utilityId, zone: $zone) {
			stateId
			utilityId
			zone
			oldRateClass
			newRateClass
		}
	}
`;
/* eslint-enable */

export const getPricingAnalyticsSupplierSettingDashboard = gql`
	query GetPricingAnalyticsSupplierSettingDashboard($supplierId: String!, $serviceTypeId: String!) {
		pricingAnalyticsSupplierSettingDashboard(supplierId: $supplierId, serviceTypeId: $serviceTypeId) {
			setting {
				id
				supplierId
				serviceTypeId
				settings
				emailRecipients
			}
		}
		supplier(supplierId: $supplierId) {
			commissionRules {
				serviceTypeId
				contractStartDate
				contractEndDate
				upfrontPercentage
			}
		}
	}
`;

export const getCommissionPayablesDashboardData = gql`
	query getCommissionPayablesDashboardData($criteria: ViewPayableForecastDashboardInput) {
		viewPayableForecastsDashboard(criteria: $criteria) {
			message {
				id
				contractId
				contractNum
				dbaName
				customerId
				utilityAccountNum
				contractLocationId
				date
				releaseDate
				confirmationDate
				effectiveDate
				commissionTypeName
				commissionTypeId
				amount
				agentId
				agentName
				margin
				usage
				confirmedAnnualUsage
				rate
				commissionPayableStatusTypeId
				commissionPayableStatusTypeName
				description
				cycleStartDate
				cycleEndDate
				commissionPayableIssueTypeId
				commissionPayableIssueTypeName
				payableIssues {
					id
					name
					description
					payableIssueTypeId
					payableIssueType {
						id
						name
						description
					}
				}

				units
				symbol
				commissionPayPlanTypeId
				commissionPayPlanTypeName
				upfrontPercentage
				receivableUsage
				accountUsage
				totalAgentPaymentAmount
				totalReceivableUpfrontPaymentNum
				totalReceivableResidualPaymentNum
				totalPayableUpfrontPaymentNum
				totalPayableResidualPaymentNum
				ruleId
				ruleTierId
				accountUsageCheckId
				receivableUsageCheckId
				issues
			}
		}
	}
`;


export const getUnit = gql`
query GetUnit($serviceTypeId: String!, $stateId: String!, $utilityId: String!) {
	unit(serviceTypeId: $serviceTypeId, stateId: $stateId, utilityId: $utilityId)
}
`;

export const getContractMarketData = gql`
query GetContractMarketData($contractId: String!) {
	marginalPricesTodayAvgForContract(contractId: $contractId) {
		avgRate
	}
}
`;

export const checkLayeredPurchasing = gql`
query CheckLayeredPurchasing($contractId: String!) {
	contractCanDoLayeredPurchasing(contractId: $contractId) {
		canDoLayeredPurchasing
	}
}
`;

export const getContractPerformanceData = gql`
query GetContractPerformanceData($contractId: String!) {
	contractPerformance(contractId: $contractId) {
		utilityRates {
			rate
		}
		marketPerformance{
			total
		}
	}
}
`;

export const getAgentLocationCommissions = gql`
query GetAgentLocationCommissions($contractId: String!, $contractLocationId: String!, $agentId: String!) {
	commissions(criteria: { contractId: $contractId, contractLocationId: $contractLocationId, agentId: $agentId }) {
		message {
			commissionId
			commissionDate
			amount
			usage
			rate
			cycleStartDate
			cycleEndDate
			description
		}
		total
		start
		end
	}
}
`;

export const getContractInfoData = gql`
query ContractInfoData($contractId: String!) {
	contract(contractId: $contractId) {
		contractId
		status
		displayStatus
		supplierResidentialEnrollmentStatus
		isResidential
		contractNum
		renewalId
		supplierId
		customerId
		rfqSessionId
		planId
		serviceTypeId
		externalId
		externalStatus
		rejectReason
		effectiveDate
		expirationDate
		contractDate
		term
		annualUsage
		rate
		margin
		bufferMargin
		commissionSplitBufferMargin
		matrixAgentBufferMargin
		moveInType
		ipAddress
		signIPAddress
		agentIPAddress
		stateId
		submissionStatus
		submissionStatusAutoSubmittedDate
		commissionStatus
		previousRate
		confirmationDate
		commissionRate
		commissionRate2
		commissionRate3
		awardDate
		uploadDate
		salesTax
		signIpFraudScore
		units
		agentId
		isTaxExempt
		taxExemptReason
		commissionPayPlanId
		loadFactor
		networkLoadFactor
		customLoadFactor
		hasUsage
		canGetUsage
		plc
		networkPlc
		isFraudulent
		signedAnnualUsage
		payableAnnualUsage
		depositRequiredAmount
		isSupplierApiSubmitted
		isCommissionAuditPaused
		rejectReasonCategoryId
		rejectReasonSubCategoryId
		lostProspectReason
		droppedTermValue
		rejectedTermValue
		reinstatementTermValue
		productId
		greenPercentage
		isGuaranteePayment
		isNewBrokerAuction
		etfAmount
		isBlendAndExtend
		blendAndExtendRenewTerm
		showAutomatedRenewalNotification
		isSentToCustomer
		rateAddDate
		compliance {
			riskScore
			riskScoreDisplay
			riskScoreColor
			riskScoreTooltip
		}
		commissionPayPlan {
			id
			name
			commissionPayPlanTypeId
			commissionPayPlanType {
				id
				description
			}
		}
		commissionPayables {
			id
			agentId
			contractLocationId
			isProcessed
			amount
			date
			usage
			rate
			margin
			cycleStartDate
			cycleEndDate
			purchasingLayerHistoryId
			exchangeRate
		}
		commissionEstimates {
			id
			contractLocationId
			date
			amount
			exchangeRate
		}
		pendingCommissions(criteria: { isArchived: false }, sort: "reportDate") {
			supplierPayment
			usage
			reportDate
			isProcessed
			isGuaranteePayment
			margin
			exchangeRate
		}
		rfqSession {
			endDate2
			isOnHold
		}
		promoCode {
			slug
		}
		serviceType {
			serviceTypeId
			name
			showPackages
			multiplier
		}
		supplier {
			name
			hasAPI
			maxNumLocations
			commissionRules {
				commissionCutoff
				serviceTypeId
				supplierId
				upfrontPercentage
				upfrontLag
				monthlyLag
				upfrontDateType
				hasAnniversaryUpfront
				anniversaryUpfrontDateType
				minUpfrontTermFactor
				maxUpfrontTermFactor
				discountFactor
				hasResidentialUpfront
				residentialPayLag
				residentialPayAmount
				residentialMargin
				marginCap
				marginSplit
				hasCommercial
				hasResidential
				residentialDescription
				commercialDescription
				contractStartDate
				contractEndDate
			}
		}
		agent {
			agentId
			name
			phone
			phoneExt
			mobile
			commissionRate
			commissionRate2
			commissionRate3
			discountFactor
			parentId
			brotherId
			phoneCountryId
			mobileCountryId
			isNewBroker
			isActive
			isFraudulent
			hideContractDetailsFromBrother
			complianceDropPredictionThreshold
			brother {
				agentId
				name
				isEmployee
				isActive
				isFraudulent
			}
			parent {
				name
				agentId
				commissionRate
				commissionRate2
				commissionRate3
				phone
				phoneExt
				mobile
				phoneCountryId
				mobileCountryId
				isNewBroker
				commissionSplitTypeId
				isActive
				isFraudulent
				brother {
					agentId
					name
					isEmployee
					isNewBroker
					isActive
					isFraudulent
				}
				logins {
					userId
					isActive
					fname
					middle
					lname
					isPrimary
					phone
					phoneCountryId
					mobile
					mobileCountryId
					addDate
					emails {
						email
					}
				}
			}
			accountManager {
				fullName
			}
			emailPreferences {
				slug
			}
			logins {
				userId
				isActive
				fname
				middle
				lname
				isPrimary
				phone
				phoneCountryId
				mobile
				mobileCountryId
				addDate
				emails {
					email
				}
			}
		}
		customer {
			dbaName
			dbaName2
			contactFname
			contactLname
			contactMiddle
			contactTitle
			address1
			address2
			city
			stateId
			zipCode
			billingAddress1
			billingAddress2
			billingCity
			billingStateId
			billingZipCode
			email
			phone
			phoneExt
			mobile
			ssn
			dob
			phoneCountryId
			mobileCountryId
			state {
				stateLong
				stateShort
			}
			billingState {
				stateLong
				stateShort
			}
			emailPreferences {
				slug
			}
			customerAvailability {
				mondayFrom
				mondayTo
				tuesdayFrom
				tuesdayTo
				wednesdayFrom
				wednesdayTo
				thursdayFrom
				thursdayTo
				fridayFrom
				fridayTo
				timezone {
					id
					name
					abbreviation
				}
			}
		}
		promoCode {
			slug
		}
		underwriter {
			fname
			lname
			middle
			fullName
		}
		tasks(criteria: { isComplete: false }) {
			taskId
			title
			body
			isComplete
			completeDate
			addDate
			dueDate
			assignedToUser {
				fname
				lname
				middle
				fullName
			}
		}
		state {
			stateShort
			stateLong
			countryId
			country {
				symbol
				isoCode
				symbol2
			}
		}
		locations(sort: "sortOrder", max: 2) {
			contractLocationId
			address1
			address2
			city
			stateId
			zipCode
			utilityId
			utilityAccountNum
			utilityAccountNum2
			utilityNameKey
			utilityMeterNum
			annualUsage
			zone
			priorSupplier
			rateClass
			addDate
			status
			dropDate
			reinstatementDate
			statusDescription
			statusReasonTypeId
			isActive
			commissions {
				commissionId
				agentId
				paidDate
				cycleStartDate
				cycleEndDate
				usage
				margin
				rate
				commissionDate
				amount
				reverseCommissionId
				exchangeRate
				agent {
					name
				}
				type {
					name
				}
				pendingCommission {
					id
					attachmentId
					isGuaranteePayment
				}
			}
			pendingCommissions(sort: "reportDate") {
				id
				meterStartDate
				meterEndDate
				usage
				supplierPayment
				reportDate
				margin
				reversePendingCommissionId
				attachmentId
				isArchived
				isGuaranteePayment
				groupId
				exchangeRate
				commissionType {
					name
				}
			}
			commissionEstimates {
				id
			}
			state {
				stateShort
				stateLong
			}
			utility {
				abbreviation
				accountNumLabel
				accountNumFormat
				showNameKey
				nameKeyFormat
				nameKeyLabel
				showReferenceNum
				referenceNumFormat
				referenceNumLabel
				showMeterNum
				meterNumFormat
				meterNumLabel
			}
		}
		esigns {
			isActive
			isComplete
			addDate
			documents {
				document {
					name
				}
			}
		}
		attachments(sort: "addDate desc") {
			attachmentId
			name
			description
			pageCount
			attachmentTypeId
			esignDocument {
				esignId
				esignDocumentId
				esign {
					signature
					isManualUpload
				}
				document {
					attachment {
						attachmentId
						pageCount
					}
				}
			}
			attachmentType {
				name
			}
			addDate
			user {
				fname
				lname
				middle
				fullName
			}
			keyTextExtractionResults {
				isAccountNumberMatched
				accountNumberMatchedResult
				accountNumberConfidentScore
				accountNumberSimilarity
				isLENMatched
				LENMatchedResult
				LENConfidentScore
				LENSimilarity
				isAddressMatched
				addressMatchedResult
				addressConfidentScore
				addressSimilarity
			}
		}
		notes(sort: "addDate desc") {
			addDate
			subject
			body
			isInternal
			noteTypeId
			user {
				fname
				lname
				middle
				fullName
			}
			noteType {
				name
			}
		}
		previousContract {
			contractId
			contractNum
			status
			displayStatus
			isNewBrokerAuction
		}
		renewalContract {
			contractId
			contractNum
			status
			displayStatus
			isNewBrokerAuction
		}
		company {
			name
			isSaas
			isDecoupled
			licensePlans {
				id
			}
		}
		product {
			name
			productTypeId
		}
		supplierCommissionRule {
			marginSplit
		}
	}
	rfqSessions(criteria: { contractId: $contractId }, sort: "addDate desc") {
		message {
			id
			startDate2
			endDate2
			addDate
		}
	}
	commissionTypes(sort: "sortOrder") {
		message {
			id
			name
			sortOrder
		}
	}
}
`;

export const contractInfoData = gql`
query ContractInfo($contractId: String!) {
	rfqSessionInfo(contractId: $contractId) {
		response
	}
}
`;

export const getContractCommissionPurchaseHistory = gql`
query GetPurchasingLayerPurchaseHistory($id: Int!) {
	purchasingLayerPurchaseHistory(id: $id) {
		id
		date
		effectiveDate
		term
		quantity
	}
}
`;

export const getContractEsignDocuments = gql`
query GetContractEsignDocuments($contractId: String!) {
	esigns(criteria: { contractId: $contractId, isActive: true, isComplete: false }, sort: "addDate") {
		message {
			esignId
			signature
			initials
			addDate
			documents {
				esignDocumentId
				documentId
				document {
					name
					attachment {
						attachmentTypeId
					}
				}
			}
		}
	}
}
`;

export const exportPendingCommissions = gql`
query ExportPendingCommissions($ids: [Int!]!, $sort: String) {
	exportPendingCommissions(ids: $ids, sort: $sort) {
		response
	}
}
`;

export const exportPendingConfirmations = gql`
query ExportPendingConfirmations($ids: [Int!]!, $sort: String) {
	exportPendingConfirmations(ids: $ids, sort: $sort) {
		response
	}
}
`;

export const rfqSessionContract = gql`
query ContractInfo($contractId: String!) {
	contract(contractId: $contractId) {
		rfqSessionId
	}
}
`;

export const rfqSessionInfoData = gql`
query RfqSessionInfo($rfqSessionId: String!, $contractId: String!, $rfqSessionSupplierId: String) {
	rfqSessionInfo(rfqSessionId: $rfqSessionId, contractId: $contractId, rfqSessionSupplierId: $rfqSessionSupplierId) {
		response
	}
}
`;

export const auctionInProgressBestBids = gql`
query AuctionInProgressBestBids($rfqSessionId: String) {
	rfqSession(rfqSessionId: $rfqSessionId) {
		bestBids {
			greenPercentage
			rfqSessionSupplierId
			bidCount
			bandwidthPercentage
			supplierName
			salesTax
			billingMethod
			rfqSessionId
			lastBid
			lastBidDate
			rfqSessionProductId
			bestBid
			isGRTIncluded
			rank
			units
		}
		bids(sort: "addDate2 desc,amount") {
			id
			bandwidthPercentage
			salesTax
			supplierId
			ipAddress
			billingMethod
			rfqSessionId
			debug
			addDate
			amount
			bufferMargin
			addDate2
			units
			rfqSessionProductId
			rfqSessionProduct {
				greenPercentage
			}
			supplier {
				name
			}
		}
	}
}
`;

export const refreshAuctionInProgressModalData = gql`
query RefreshAuctionInProgressModalData($rfqSessionId: String) {
	rfqSession(rfqSessionId: $rfqSessionId) {
		id
		startDate
		startTime
		endDate
		endTime
		maxBids
		startDate2
		endDate2
		maxExtends
		instructions
		instructionsAdmin
		isOnHold
		isCustomerInvited
		suppliers {
			id
			note
			noteAddDate
			isOptOut
			supplierId
			optOutReasonNote
			optOutReasonNoteAddDate
			loaRequestDate
			loeRequestDate
			sampleBillRequestDate
			loaRequestNote
			loeRequestNote
			sampleBillRequestNote
			optOutReasonType {
				name
			}
			supplier {
				supplierId
				name
			}
		}
		products {
			id
			productId
			term
			lag
			blockType
			blockSize
			greenPercentage
			initialFixedUsage
			product {
				id
				name
				sortOrder
			}
		}
		bids(sort: "addDate2 desc,amount") {
			id
			bandwidthPercentage
			salesTax
			supplierId
			ipAddress
			billingMethod
			rfqSessionId
			debug
			addDate
			amount
			bufferMargin
			addDate2
			rfqSessionProductId
			units
			bufferMargin
			supplier {
				name
			}
		}
		bestBids {
			greenPercentage
			rfqSessionSupplierId
			bidCount
			bandwidthPercentage
			supplierName
			salesTax
			billingMethod
			rfqSessionId
			lastBid
			lastBidDate
			rfqSessionProductId
			bestBid
			isGRTIncluded
			rank
			units
		}
	}
}
`;

export const auctionInProgressModalData = gql`
query AuctionInProgressModalData($rfqSessionId: String, $serviceTypeId: String, $stateId: String, $dateGTE: String) {
	rfqSession(rfqSessionId: $rfqSessionId) {
		id
		startDate
		startTime
		endDate
		endTime
		maxBids
		startDate2
		endDate2
		maxExtends
		instructions
		instructionsAdmin
		isOnHold
		isCustomerInvited
		marketSuggestionEffectiveDate
		contract {
			contractId
			contractNum
			serviceTypeId
			stateId
			customerId
			supplierId
			annualUsage
			agentId
			margin
			term
			bufferMargin
			commissionSplitBufferMargin
			effectiveDate
			awardDate
			status
			units
			companyId
			isNewBrokerAuction
			minAuctionEndDate {
				businessDays
				date
			}
			customer {
				dbaName
			}
			serviceType {
				name
				multiplier
			}
			state {
				stateShort
				country {
					symbol
					symbol2
				}
			}
			supplier {
				name
			}
			esigns {
				isActive
				isComplete
				addDate
				documents {
					document {
						name
					}
				}
			}
			attachments {
				attachmentId
				attachmentTypeId
			}
			locations {
				utilityId
				annualUsage
				utility {
					name
					defaultDaysUntilAuctionEnd
					utilityMarketSettings(criteria: { isActive: true }) {
						stateId
						serviceTypeId
						requireLoaForPricingSession
						requireLoeForPricingSession
						requireHudForPricingSession
						requireBillForPricingSession
						requireLoaOrBillOrManyLocations
					}
				}
			}
		}
		suppliers {
			id
			note
			noteAddDate
			isOptOut
			supplierId
			optOutReasonNote
			optOutReasonNoteAddDate
			loaRequestDate
			loeRequestDate
			sampleBillRequestDate
			loaRequestNote
			loeRequestNote
			sampleBillRequestNote
			optOutReasonType {
				name
			}
			supplier {
				supplierId
				name
			}
		}
		products {
			id
			productId
			term
			lag
			blockType
			blockSize
			greenPercentage
			initialFixedUsage
			product {
				id
				name
				sortOrder
			}
		}
		bids(sort: "addDate2 desc,amount") {
			id
			bandwidthPercentage
			salesTax
			supplierId
			ipAddress
			billingMethod
			rfqSessionId
			debug
			addDate
			amount
			bufferMargin
			addDate2
			rfqSessionProductId
			units
			bufferMargin
			supplier {
				name
			}
		}
		bestBids {
			greenPercentage
			rfqSessionSupplierId
			bidCount
			bandwidthPercentage
			supplierName
			salesTax
			billingMethod
			rfqSessionId
			lastBid
			lastBidDate
			rfqSessionProductId
			bestBid
			isGRTIncluded
			rank
			units
		}
		marketSuggestions {
			effectiveDate
			minRate
			percentChange
		}
	}
	products(criteria: { isActive: true, serviceTypeId: $serviceTypeId, stateId: $stateId }, sort: "sortOrder") {
		message {
			id
			name
			serviceTypeId
			description
		}
	}
	blockTypes(criteria: { isActive: true }) {
		message {
			id
			name
		}
	}
	holidays(criteria: { dateGTE: $dateGTE }) {
		message {
			date
		}
	}
}
`;

export const serviceTypeUnitData = gql`
query GetServiceTypeUnitData($serviceTypeId: String!, $stateId: String!, $utilityId: String!) {
	serviceTypeUnit(criteria: { serviceTypeId: $serviceTypeId, stateId: $stateId, utilityId: $utilityId }) {
		units
		marginUnits
	}
}
`;

export const auctionModalData = gql`
query AuctionModalData($contractId: String!, $serviceTypeId: String!, $stateId: String!, $dateGTE: String) {
	contract(contractId: $contractId) {
		contractId
		contractNum
		serviceTypeId
		stateId
		customerId
		supplierId
		annualUsage
		agentId
		margin
		term
		bufferMargin
		effectiveDate
		units
		loadFactor
		networkLoadFactor
		customLoadFactor
		hasUsage
		productId
		status
		isNewBrokerAuction
		minAuctionEndDate {
			businessDays
			date
		}
		customer {
			dbaName
		}
		serviceType {
			name
			multiplier
		}
		state {
			stateShort
		}
		supplier {
			name
		}
		esigns {
			isActive
			isComplete
			addDate
			documents {
				document {
					name
				}
			}
		}
		attachments {
			attachmentId
			attachmentTypeId
		}
		locations {
			utilityId
			annualUsage
			utilityAccountNum
			utilityReferenceNum
			utility {
				name
				defaultDaysUntilAuctionEnd
				utilityMarketSettings(criteria: { isActive: true }) {
					stateId
					serviceTypeId
					requireLoaForPricingSession
					requireLoeForPricingSession
					requireHudForPricingSession
					requireBillForPricingSession
					requireLoaOrBillOrManyLocations
				}
			}
		}
	}
	products(criteria: { isActive: true, serviceTypeId: $serviceTypeId, stateId: $stateId }, sort: "sortOrder") {
		message {
			id
			name
			serviceTypeId
			term
			description
		}
	}
	blockTypes(criteria: { isActive: true }) {
		message {
			id
			name
			isActive
		}
	}
	holidays(criteria: { dateGTE: $dateGTE }) {
		message {
			date
		}
	}
}`;

export const getStateEditPageData = gql`
query GetStateEditmessage {
	countries(sort: "name") {
		message {
			id
			name
		}
	}
	serviceTypes(criteria: { isActive: true }) {
		message {
			name
			serviceTypeId
		}
	}
}
`;

export const getStateEditData = gql`
query GetStateEditData($stateId: String!) {
	state(stateId: $stateId) {
		stateShort
		stateId
		stateLong
		isActive
		countryId
		prefix
		serviceTypes {
			serviceTypeId
			isDeregulated
			serviceType {
				name
			}
		}
	}
}
`;

export const availableSuppliers = gql`
query GetAvailableSuppliers($serviceTypeId: String!, $stateId: String!, $utilityId: String,
	$annualUsage: Float, $supplierId: String, $effectiveDate: String) {
	availableSuppliers(serviceTypeId: $serviceTypeId, stateId: $stateId, utilityId: $utilityId,
		annualUsage: $annualUsage, includeSupplierId: $supplierId, effectiveDate: $effectiveDate) {
		message {
			supplierId
			name
		}
	}
	otherSuppliers: availableSuppliers(serviceTypeId: $serviceTypeId, stateId: $stateId, utilityId: $utilityId,
		excludeSupplierId: $supplierId, effectiveDate: $effectiveDate) {
		message {
			supplierId
			name
		}
	}
}
`;

export const viewOperationsContractActivityDashboard = gql`
query ViewOperationsContractActivityDashboard(
	$criteria: ViewOperationsContractActivityDashboardInput, $sort: String, $max: Int, $offset: Int) {
	viewOperationsContractActivityDashboard(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			contractId
			contractDate
			contractNum
			effectiveDate
			droppedTermValue
			term
			annualUsage
			status
			displayStatus
			isResidential
			dbaName
			companyId
			commodity
			supplierName
			units
			esignIsComplete
			submissionStatus
			latestDropDate
		}
		total
		start
		end
	}
}
`;

export const viewCustomerEnrollmentDashboard = gql`
query ViewCustomerEnrollmentDashboard($criteria: CustomContractInput, $sort: String, $max: Int, $offset: Int) {
	viewContracts(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			commodity
			dbaName
			agentName
			contractDate
			status
			contractNum
			notifications
			expirationDate
			effectiveDate
			supplierName
			contractId
			confirmationErrorMessage
			signIpFraudScore
			callNoResponseDate
			fraudulentDate
			isFailedEnrollment
		}
		total
		start
		end
	}
}`;

export const viewContractDashboard = gql`
query ViewContractDashboard($criteria: CustomContractInput, $sort: String, $max: Int, $offset: Int) {
	viewContractDashboard(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			rate
			margin
			bufferMargin
			commissionSplitBufferMargin
			matrixAgentBufferMargin
			serviceTypeId
			hasCommercial
			commodity
			sampleBillRequested
			droppedTermValue
			term
			annualUsage
			dbaName
			agentId
			agentName
			contractDate
			status
			customerId
			parentName
			contractNum
			notifications
			hasLVM
			displayStatus
			expirationDate
			effectiveDate
			auctionEndDate
			hasIssueFound
			brotherId
			brotherName
			supplierName
			contractId
			hasHappyCustomer
			parentId
			isResidential
			loeRequested
			hasOthers
			underwriterName
			stateId
			rejectReason
			auctionStartDate
			isRenewal
			previousContractId
			previousContractNum
			previousContractRate
			confirmationDate
			modifiedDate
			customerFname
			customerMiddle
			customerLname
			customerPhone
			customerEmail
			customerTitle
			awardDate
			stateLong
			stateShort
			supplierId
			salesTax
			units
			utilityIdList
			utilityNameList
			confirmationQueueStatus
			confirmationErrorMessage
			commissionPayPlanName
			signedAnnualUsage
			payableAnnualUsage
			depositRequiredAmount
			isNewBrokerAuction
			agentIsNewBroker
			dropDate
			submissionStatus
			submissionStatusAutoSubmittedDate
			signIpFraudScore
			callNoResponseDate
			fraudulentDate
			hideContractDetailsFromBrother
		}
		total
		start
		end
	}
}`;

export const getStateListData = gql`
query GetStateListData($sort: String) {
	states(sort: $sort) {
		message {
			stateShort
			stateId
			stateLong
			isActive
			prefix
			country {
				name
			}
		}
	}
}
`;

export const getChartRetailFormData = gql`
query GetChartRetailFormmessage {
	serviceTypes(sort: "name") {
		message {
			serviceTypeId
			name
			units
		}
	}
	states(sort: "stateShort") {
		message {
			stateId
			stateShort
		}
	}
}
`;

export const getChartFormData = gql`
query GetChartFormData($serviceTypeId: String!, $chartType: String!)  {
	marketStates(serviceTypeId: $serviceTypeId, chartType: $chartType) {
		stateId
		stateShort
	}
}
`;

export const addressValidation = gql`
query AddressValidation($address: AddressValidationInput!) {
	addressValidation(address: $address) {
		zip
		hasError
		errorMessage
		city
		countyName
		countyCode
		address1
		address2
		state {
			stateId
			stateLong
			stateShort
			countryId
		}
	}
}
`;

export const utilityAccountNumValidation = gql`
query UtilityAccountNumValidation($utilityAccountNum: UtilityAccountNumValidationInput!) {
	utilityAccountNumValidation(utilityAccountNum: $utilityAccountNum) {
		utilityAccountNum
		hasError
		errorMessage
	}
}
`;

export const utilityAccountNumRenewalValidation = gql`
query UtilityAccountNumRenewalValidation($utilityAccountNum: UtilityAccountNumRenewalValidationInput!) {
	utilityAccountNumRenewalValidation(utilityAccountNum: $utilityAccountNum) {
		utilityAccountNum
		hasError
		errorMessage
	}
}
`;

export const usageCharts = gql`
query UsageCharts($locations: [ContractLocationInput!]!) {
	usageCharts(locations: $locations) {
		message {
			contractLocationId
			plc {
				endDate
				startDate
				value
			}
			networkPlc {
				endDate
				startDate
				value
			}
			usageData {
				period
				usage
				demand
			}
			loadProfile
			utilityErrorMessage
			utilityErrorMessageDetail
		}
	}
}
`;

export const getProposalModalData = gql`
query GetProposalModalData($locations: [ContractLocationInput!]!) {
	totalUsageCharts(locations: $locations) {
		message {
			period
			usage
			demand
		}
	}
}
`;

export const destroyAllPendingCommission = gql`
query DestroyAllPendingCommission($id: [PendingCommissionInput!]!) {
	destroyAllPendingCommission(id: $id) {
		message {
		id
		}
	}
}
`;

export const destroyAllPendingInvoice = gql`
query DestroyAllPendingInvoice($id: [PendingInvoiceInput!]!) {
	destroyAllPendingInvoice(id: $id) {
		message {
			id
		}
	}
}
`;

export const errorCheckPendingCommission = gql`
query ErrorCheckPendingCommission($id: [PendingCommissionInput!]!) {
	errorCheckPendingCommission(id: $id) {
		message {
		id
		}
	}
}
`;
export const errorCheckPendingInvoice = gql`
query ErrorCheckPendingInvoice($id: [PendingInvoiceInput!]!) {
	errorCheckPendingInvoice(id: $id) {
		message {
			id
		}
	}
}
`;

export const completeReviewAllPendingCommission = gql`
query CompleteReviewAllPendingCommission($id: [PendingCommissionInput!]!) {
	completeReviewAllPendingCommission(id: $id) {
		message {
		id
		}
	}
}
`;

export const completeReviewAllPendingInvoice = gql`
query CompleteReviewAllPendingInvoice($id: [PendingInvoiceInput!]!) {
	completeReviewAllPendingInvoice(id: $id) {
		message {
			id
		}
	}
}
`;

export const incompleteReviewAllPendingCommission = gql`
query IncompleteReviewAllPendingCommission($id: [PendingCommissionInput!]!) {
	incompleteReviewAllPendingCommission(id: $id) {
		message {
		id
		}
	}
}
`;

export const incompleteReviewAllPendingInvoice = gql`
query IncompleteReviewAllPendingInvoice($id: [PendingInvoiceInput!]!) {
	incompleteReviewAllPendingInvoice(id: $id) {
		message {
			id
		}
	}
}
`;

export const processAllPendingCommission = gql`
query ProcessAllPendingCommission($id: [PendingCommissionInput!]!) {
	processAllPendingCommission(id: $id) {
		message {
		id
		}
	}
}
`;

export const processAllPendingInvoice = gql`
query ProcessAllPendingInvoice($id: [PendingInvoiceInput!]!) {
	processAllPendingInvoice(id: $id) {
		message {
			id
		}
	}
}
`;

export const texasAccountNumber = gql`
query TexasAccountNumber($address: String!) {
	texasAccountNumber(address: $address) {
		accountNumber
		error
	}
}
`;

export const customerSearch = gql`
query CustomerSearch($dbaName: String!, $city: String!, $stateId: String!, $zipCode: String!) {
	customerSearch(dbaName: $dbaName, city: $city, stateId: $stateId, zipCode: $zipCode) {
		message {
			customerId
			dbaName
			address1
			address2
			zipCode
			phone
			phone2
			fax
			mobile
			email
			contactFname
			contactMiddle
			contactLname
			contactTitle
			phoneCountryId
			phone2CountryId
			mobileCountryId
			faxCountryId
			customerAvailabilityId
			customerAvailability {
				mondayFrom
				mondayTo
				tuesdayFrom
				tuesdayTo
				wednesdayFrom
				wednesdayTo
				thursdayFrom
				thursdayTo
				fridayFrom
				fridayTo
				timezone {
					id
					name
					abbreviation
				}
			}
		}
	}
}
`;

export const getSavingsData = gql`
query GetSavingsData($contract: ContractInput!, $baseRate: Float!) {
	savings(contract: $contract, baseRate: $baseRate) {
		customerRate {
			value
			date
		}
		termRate {
			value
		}
		savings {
			value
			usage
			units
		}
		totalSavings
	}
}
`;

export const getSavingsContracts = gql`
query GetSavingsContracts($contractId: String!) {
	contract(contractId: $contractId) {
		contractId
		serviceTypeId
		term
		rate
		margin
		bufferMargin
		matrixAgentBufferMargin
		effectiveDate
		stateId
		locations {
			contractLocationId
			utilityAccountNum
			annualUsage
			zone
			utilityId
		}
	}
}
`;

export const getContractServiceType = gql`
query GetContractServiceType($contractId: String!) {
	contract(contractId: $contractId) {
		serviceTypeId
	}
}
`;

export const getCalendarData = gql`
query GetCalendarData($criteria: RateCalendarInput) {
	rateCalendarData(criteria: $criteria) {
		message {
			minRate
			effectiveDate
			term
			supplierIdList
		}
	}
}
`;

export const getRates = gql`
query GetRates($criteria: RateInput) {
	rates(criteria: $criteria) {
		message {
			rateMatrixId
			addDate
			bandwidthPercentage
			greenPercentage
			baseRate
			billingMethod
			customerId
			displayRate
			displayRateNoSalesTax
			logo
			name
			productId
			productName
			rate
			savings
			supplierId
			stateId
			term
			termName
			usageAdjustments
			tosPath
			planName
			disclaimerPath
			extra
			salesTax
			rateClass
			rateCodeId
			loadFactor
			puct
			planId
			usageAdjustment
			eflPath
			rateAddDate
			premium
			yracPath
			monthlyServiceFee
			commission
			cancellationPenaltyDesc
			phone3
			loadFactorNote
			contractAgentFinalMargin
			showTaxId
			effectiveDate
			allowMatrixRenewal
			commissionOptions {
				percentage
				estTotalCommission
				estAnnualPayment
				estFirstPayment
				estFirstPaymentDescription
				commissionPayPlanTypeId
				commissionPayPlanType {
					description
					header
					details
				}
			}
			maxNumLocations
			restrictionsNote
			isAffectedByMarginCapSplit
			marginOverMarginCap
			upfrontPaymentRule
			residualPaymentRule
			paymentAmount
			marginSplitRule
			marginCapRule
			compensationRuleDescription
			units
			isUsingAgentMarginCap
			displayAgentMarginCap
		}
	}
}
`;

export const getOffPeakGasMarginalPricingStatistics = gql`
query GetOffPeakGasMarginalPricesPricingStatistics(
	$nymexMarginalPricesRequest: MarginalPricesRequest!,
	$supplyMarginalPricesRequest: MarginalPricesRequest!,
	$basisMarginalPricesRequest: MarginalPricesRequest!) {
	nymexMarginalPricesPricingStatistics: marginalPricesOffPeakPricingStatistics(marginalPricesRequest: $nymexMarginalPricesRequest) {
		rates {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
	supplyMarginalPricesPricingStatistics: marginalPricesOffPeakPricingStatistics(marginalPricesRequest: $supplyMarginalPricesRequest) {
		rates {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
	basisMarginalPricesPricingStatistics: marginalPricesOffPeakPricingStatistics(marginalPricesRequest: $basisMarginalPricesRequest) {
		rates {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
}
`;

export const getLayeredPurchasingNymexGasStatistics = gql`
query GetGasMarginalPricesPricingStatistics($nymexMarginalPricesRequest: MarginalPricesRequest!) {
	nymexMarginalPricesPricingStatistics: marginalPricesPeakPricingStatistics(marginalPricesRequest: $nymexMarginalPricesRequest) {
		rates {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
}
`;

export const getNymexGasMarginalPricingStatistics = gql`
query GetGasMarginalPricesPricingStatistics($nymexMarginalPricesRequest: MarginalPricesRequest!) {
	nymexMarginalPricesPricingStatistics: marginalPricesPricingStatistics(marginalPricesRequest: $nymexMarginalPricesRequest) {
		rates {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
}
`;

export const getMarginalPricesNymexIntradayStatistics = gql`
query getMarginalPricesNymexIntradayStatistics {
	marginalPricesNymexIntradayStatistics {
		gasStats {
			dateTime
			value
		}
	}
}
`;

export const getGasMarginalPricingStatistics = gql`
query GetGasMarginalPricesPricingStatistics(
	$nymexMarginalPricesRequest: MarginalPricesRequest!,
	$supplyMarginalPricesRequest: MarginalPricesRequest!,
	$basisMarginalPricesRequest: MarginalPricesRequest!) {
	nymexMarginalPricesPricingStatistics: marginalPricesPricingStatistics(marginalPricesRequest: $nymexMarginalPricesRequest) {
		rates {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
	supplyMarginalPricesPricingStatistics: marginalPricesPricingStatistics(marginalPricesRequest: $supplyMarginalPricesRequest) {
		rates {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
	basisMarginalPricesPricingStatistics: marginalPricesPricingStatistics(marginalPricesRequest: $basisMarginalPricesRequest) {
		rates {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
}
`;

export const getOffPeakElectricityMarginalPricingStatistics = gql`
query GetOffPeakElectricityMarginalPricingStatistics($marginalPricesRequest: MarginalPricesRequest!) {
	energyMarginalPricesPricingStatistics: marginalPricesOffPeakPricingStatistics(marginalPricesRequest: $marginalPricesRequest) {
		rates {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
	marginalPricesCapacityStatistics(marginalPricesRequest: $marginalPricesRequest) {
		capacity {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
		billableCapacity {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
	marginalPricesAncillaryStatistics(marginalPricesRequest: $marginalPricesRequest) {
		ancillaries {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
	marginalPricesHourlyPricingStatistics(marginalPricesRequest: $marginalPricesRequest) {
		rates {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
}
`;

export const getTaskEditUsers = gql`
query GetTaskEditUsers {
	users(criteria: { isActive: true }, sort: "lname,fname", max: 250) {
		message {
			userId
			fname
			lname
			middle
			agent {
				name
			}
		}
	}
}`;

export const getContractLocationData = gql`
query GetContractLocationData($contractLocationId: String!) {
	contractLocation(contractLocationId: $contractLocationId) {
		contractLocationId
		contractId
		annualUsage
		address1
		address2
		city
		stateId
		zipCode
		utilityAccountNum
		utilityAccountNum2
		utilityNameKey
		utilityReferenceNum
		utilityMeterNum
		priorSupplier
		utilityId
		status
		dropDate
		reinstatementDate
		statusDescription
		rateClass
		zone
		deliveryTypeId
		etfAmount
		skipStatusNotification
		effectiveDate
		rejectionDate
		state {
			countryId
		}
	}
}`;

export const getLookupReporterData = gql`
query GetLookupReportermessage {
	roles {
		message {
			roleId
			name
		}
	}
}
`;

export const getSecurityEditPageData = gql`
query GetSecurityEditPageData($userId: String!) {
	user(userId: $userId) {
		agentId
		userId
		fname
		lname
		middle
		phone
		phoneCountryId
		phone2
		phone2CountryId
		fax
		faxCountryId
		mobile
		mobileCountryId
		roleId
		username
		isActive
		stripeCustomerId
		subscribeToErrorNotifications
		defaultElectricityUnit
		defaultGasUnit
		supplierId
		defaultRateSort
		companyId
		isAdmin
		isSaas
		isOwner
		isFinance
		isOperations
		defaultDashboardId
		emails {
			email
		}
		contactMethods {
			id
		}
		role {
			name
			roleId
		}
		agent {
			agentId
			parentId
			brotherId
			isActive
			isEmployee
			name
			commissionRate
			commissionRate2
			commissionRate3
			residentialCommissionRate
			residentialCommissionRate2
			residentialCommissionRate3
			agentDomainAlias
			promoCodeCount
			agentBundleCount
			eCommerceDomainName
			isNewBroker
			commissionSplitTypeId
			darkBackgroundsLogoId
			lightBackgroundsLogoId
			emailPreferences {
				id
			}
		}
		dashboards {
			id
			name
		}
	}
	countries(sort: "name") {
		message {
			id
			name
			code
		}
	}
	roles {
		message {
			roleId
			name
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	emailPreferences(criteria: { isAdminOnly: false }) {
		message {
			id
			name
			slug
			description
			sortOrder
		}
	}
	contactMethods(sort: "sortOrder") {
		message {
			id
			name
		}
	}
}
`;

export const getLogo = gql`
query GetLogo($logoId: String!) {
	logo(logoId: $logoId) {
		logoId
		attachmentId
		attachment {
			name
			description
			path
		}
	}
}
`;

export const getLicensePlansAddEditPageData = gql`
query GetLicensePlansAddEditPageData($agentId: String!) {
	agentCustomerCards(agentId: $agentId) {
		exp_month
		exp_year
		last4
		brand
		id
	}
	agent(agentId: $agentId) {
		agentId
		parentId
		brotherId
		isEmployee
		name
		commissionRate
		commissionRate2
		commissionRate3
		residentialCommissionRate
		residentialCommissionRate2
		residentialCommissionRate3
		agentDomainAlias
		promoCodeCount
		agentBundleCount
		eCommerceDomainName
		isNewBroker
		commissionSplitTypeId
		darkBackgroundsLogoId
		lightBackgroundsLogoId
		darkBackgroundsLogo {
			logoId
			attachmentId
		}
		lightBackgroundsLogo {
			logoId
			attachmentId
		}
		logins {
			userId
			isActive
		}
		licensePlans {
			id
			name
			upfrontFee
			monthlyFee
			commissionRate
			years
			parents {
				id
				name
			}
		}
		parentIsEmployee {
			isEmployee
		}
		brotherIsEmployee {
			isEmployee
		}
		eCommerceWebsite {
			domainName
		}
		subAgents(sort: "name") {
			agentId
			parentId
			brotherId
			isEmployee
			name
			promoCodeCount
			commissionRate
			commissionRate2
			commissionRate3
			residentialCommissionRate
			residentialCommissionRate2
			residentialCommissionRate3
			agentDomainAlias
			eCommerceDomainName
			isBundledAgent
			isNewBroker
			commissionSplitElectricityBufferMargin
			commissionSplitGasBufferMargin
			brotherIsEmployee {
				isEmployee
			}
			parentIsEmployee {
				isEmployee
			}
			logins {
				userId
				isActive
			}
			licensePlans {
				id
				name
				upfrontFee
				monthlyFee
				commissionRate
				years
				parents {
					id
				}
			}
		}
	}
	licensePlans(criteria: { licensePlanTypeId: 1 }, sort: "id") {
		message {
			id
			name
			description
			upfrontFee
			monthlyFee
			commissionRate
			years
			parents {
				id
			}
		}
	}
}
`;

export const getStripeAgentCustomerCardData = gql`
query GetStripeAgentCustomerCardData($agentId: String!) {
	agentCustomerCards(agentId: $agentId) {
		exp_month
		exp_year
		last4
		brand
		id
	}
	agent(agentId: $agentId) {
		isActive
		name
		logins {
			addDate
			isPrimary
			emails {
				email
			}
		}
	}
}`;

export const getStripeAgentCustomerInvoicesData = gql`
query GetStripeAgentCustomerInvoicesData($agentId: String!) {
	agentCustomerInvoices(agentId: $agentId) {
		number
		created
		due_date
		amount_due
		status
		charge {
			amount_refunded
		}
		lines {
			data {
				description
			}
		}
	}
}
`;

export const getStripeCustomerData = gql`
query GetStripeCustomerData($stripeCustomerId: String!) {
	customerCards(stripeCustomerId: $stripeCustomerId) {
		message {
			exp_month
			exp_year
			last4
			brand
			id
		}
	}
	customerInvoices(stripeCustomerId: $stripeCustomerId) {
		message {
			number
			date
			due_date
			amount_due
			paid
			lines {
				data {
					description
				}
			}
		}
	}
}`;

export const getTaskEditAgents = gql`
query GetTaskEditAgents($agentId: String!) {
	agent(agentId: $agentId) {
		agentId
		logins {
			userId
			fname
			lname
			middle
		}
	}
	agents(criteria: { parentId: $agentId, isActive: true }) {
		message {
			agentId
		}
	}
}
`;

export const getTaskEditAgentUsers = gql`
query GetTaskEditAgents($agentIds: String!) {
	users(criteria: { isActive: true, agentId: $agentIds }, sort: "lname,fname") {
		message {
			userId
			agentId
			fname
			lname
			middle
			agent {
				name
			}
		}
	}
}`;

export const getContractLocationPageData = gql`
query GetContractLocationPageData($contractId: String!) {
	contract(contractId: $contractId) {
		contractId
		contractNum
		isResidential
		serviceTypeId
		switchTypeCode
		awardDate
		effectiveDate
		expirationDate
		confirmationDate
		locations {
			rateClass
			utilityId
		}
		serviceType {
			name
		}
		supplier {
			name
			maxNumLocations
		}
		state {
			countryId
		}
	}
	states(sort: "stateShort") {
		message {
			stateId
			stateShort
			stateLong
		}
	}
	countries(criteria: { isActive: true }, sort: "name") {
		message {
			id
			name
			zipCodeFormat
			code
		}
	}
	deliveryTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
}`;

export const getReportersData = gql`
query GetReportersData($criteria: UserInput, $sort: String, $max: Int, $offset: Int) {
	users(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			userId
			fname
			lname
			username
			isActive
			lastLogin
			addDate
			roleId
			isAdmin
			isSaas
			emails {
				email
			}
			role {
				roleId
				name
			}
		}
		total
		start
		end
	}
}
`;

export const getTicketPageData = gql`
query GetTicketPageData {
	ticketCategories(criteria: { isActive: true }, sort: "name") {
		message {
			id
			name
			roles {
				roleId
			}
		}
	}
	ticketStatuses(sort: "name") {
		message {
			id
			name
		}
	}
	users(criteria: { roleId: "297ed5063d288ec7013d290e2dab003e,297ed5063d288ec7013d290e2dc7003f", isActive: true }, sort: "fname, lname") {
		message {
			userId
			fname
			lname
			isActive
			emails {
				email
			}
		}
	}
	roles(sort: "name") {
		message {
			roleId
			name
		}
	}
	contactMethods(sort: "sortOrder") {
		message {
			id
			name
		}
	}
}
`;

export const getTaskListDataCount = gql`
query GetTaskListDataCount($criteria: TaskInput, $sort: String, $max: Int, $offset: Int) {
	tasks(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		total
		start
		end
	}
}
`;

export const getTaskListData = gql`
query GetTaskListData($criteria: TaskInput, $sort: String, $max: Int, $offset: Int) {
	tasks(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			taskId
			title
			body
			addDate
			dueDate
			completeDate
			isComplete
			isDismissed
			isRead
			assignedToUser {
				fname
				lname
				middle
				fullName
			}
			contract {
				contractId
				contractNum
				status
				stateId
				serviceTypeId
				isResidential
				agentId
				isNewBrokerAuction
				agent {
					parentId
				}
				rfqSession {
					endDate2
				}
				supplier{
					name
				}
				renewalContract {
					contractId
					isResidential
					contractNum
					status
					stateId
					serviceTypeId
					agentId
					annualUsage
					isNewBrokerAuction
					rejectReasonSubCategoryId
					agent {
						parentId
					}
					rfqSession {
						endDate2
					}
				}
			}
		}
		total
		start
		end
	}
}
`;

export const getAlertListDataCount = gql`
query GetAlertListDataCount($criteria: AlertInput, $sort: String, $max: Int, $offset: Int) {
	alerts(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		total
		start
		end
	}
}
`;

export const getMarketUpdateLog = gql`
query GetMarketUpdateLog($id: Int!) {
	marketUpdateLog(id: $id) {
		id
		json
		addDate
	}
	serviceTypes(criteria: { isActive: true }) {
		message {
			serviceTypeId
			name
			multiplier
			units
			units2
		}
	}
	marketUpdateLogMap {
		json
	}
}
`;

export const getLatestMarketUpdateLog = gql`
query GetLatestMarketUpdateLog {
	marketUpdateLogs(sort: "addDate desc", max: 1) {
		message {
			id
			json
			addDate
		}
	}
	serviceTypes(criteria: { isActive: true }) {
		message {
			serviceTypeId
			name
			multiplier
			units
			units2
		}
	}
	marketUpdateLogMap {
		json
	}
}
`;

export const getMarketUpdateListData = gql`
query GetMarketUpdateListData($sort: String, $max: Int, $offset: Int) {
	marketUpdateLogs(sort: $sort, max: $max, offset: $offset) {
		message {
			id
			json
			addDate
		}
		total
		start
		end
	}
}
`;

export const getAlertListData = gql`
query GetAlertListData($criteria: AlertInput, $sort: String, $max: Int, $offset: Int) {
	alerts(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			isRead
			isOpened
			isViewed
			addDate
			contractId
			foldedMessage
			unfoldedMessage
			alertType {
				name
			}
			contract {
				contractId
				contractNum
				isNewBrokerAuction
			}
			ticket {
				id
				subject
				category {
					name
				}
				assignedToUser {
					fname
					lname
				}
			}
		}
		total
		start
		end
	}
}
`;

export const getViewTicketDashboardDetailData = gql`
query GetViewTicketDashboardDetailData($id: Int!) {
	viewTicketDashboard(criteria: { id: $id }){
		message  {
			id
			subject
			body
			email
			priority
			priorityDisplay
			addDate
			completeDate
			ticketCategoryId
			ticketCategoryName
			completeUserId
			completedFullName
			assignedToUserId
			assigneeFullName
			reporterId
			reporterFname
			reporterLname
			reporterFullName
			statusId
			status
			brokerStatus
			inProgressDate
			modifiedDate
			agent {
				agentId
				name
				residentialContractRatio
				isNewBroker
			}
			user {
				userId
				fname
				lname
				isActive
				isAdmin
				isSaas
				agentId
				agent {
					name
				}
				emails {
					email
				}
				role {
					name
				}
			}
			attachments {
				attachmentId
				originalName
				size
				name
				attachmentTypeId
				ticketCommentId
				addDate
				user {
					fname
					lname
					emails {
						email
					}
				}
			}
			comments(sort: "addDate desc") {
				id
				ticketId
				body
				addDate
				modifiedDate
				isInternal
				user {
					userId
					fname
					lname
					isAdmin
				}
			}
			contracts {
				contractId
				contractNum
				rejectReason
				lostProspectReason
				status
				isNewBrokerAuction
			}
			contactMethods {
				id
				name
			}
			latestAiGeneratedContent {
				id
			}
		}
	}
}
`;

export const getTicketDetailPageData = gql`
query GetTicketDetailPageData {
	ticketCategories(criteria: { isActive: true }, sort: "name") {
		message {
			id
			name
			roles {
				roleId
			}
		}
	}
	ticketStatuses(sort: "name") {
		message {
			id
			name
		}
	}
	users(criteria: { roleId: "297ed5063d288ec7013d290e2dab003e,297ed5063d288ec7013d290e2dc7003f", isActive: true }, sort: "fname, lname") {
		message {
			userId
			fname
			lname
			isActive
			emails {
				email
			}
		}
	}
	contactMethods(sort: "sortOrder") {
		message {
			id
			name
		}
	}
}
`;

export const getSingleTicketPageData = gql`
query GetSingleTicketPageData($id: Int!) {
	ticket(id: $id) {
		id
		subject
		body
		email
		priority
		addDate
		completeDate
		ticketCategoryId
		completeUserId
		assignedToUserId
		userId
		contractId
		agentId
		agent {
			name
			residentialContractRatio
			isNewBroker
		}
		category {
			name
		}
		user {
			userId
			fname
			lname
			isActive
			isAdmin
			isSaas
			emails {
				email
			}
			role {
				name
			}
		}
		assignedToUser {
			fname
			lname
		}
		completeUser {
			fname
			lname
		}
		attachments {
			attachmentId
			originalName
			size
			name
			attachmentTypeId
			ticketCommentId
			addDate
			user {
				fname
				lname
				emails {
					email
				}
			}
		}
		comments(sort: "addDate") {
			id
			ticketId
			body
			addDate
			modifiedDate
			isInternal
			user {
				userId
				fname
				lname
				isAdmin
			}
		}
		contracts {
			contractId
			contractNum
			rejectReason
			lostProspectReason
			status
			isNewBrokerAuction
		}
		contactMethods {
			id
			name
		}
	}
	viewTicketDashboard(criteria: {id: $id}){
		message{
			status
			brokerStatus
			inProgressDate
			modifiedDate
		}
	}
	ticketCategories(criteria: { isActive: true }, sort: "name") {
		message {
			id
			name
			roles {
				roleId
			}
		}
	}
	users(criteria: { roleId: "297ed5063d288ec7013d290e2dab003e,297ed5063d288ec7013d290e2dc7003f", isActive: true }, sort: "fname, lname") {
		message {
			userId
			fname
			lname
			isActive
			emails {
				email
			}
		}
	}
	contactMethods(sort: "sortOrder") {
		message {
			id
			name
		}
	}
}
`;

export const viewTicketDashboard = gql`
query GetNewTicketData($criteria: TicketSearchInput, $sort: String, $max: Int, $offset: Int) {
	viewTicketDashboard(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			subject
			ticketCategoryId
			ticketCategoryName
			inProgressDate
			priority
			priorityDisplay
			status
			brokerStatus
			statusId
			reporterId
			reporterFname
			reporterLname
			reporterFullName
			reporterAgentName
			reporterRole
			assignedToUserId
			assigneeFname
			assigneeLname
			assigneeFullName
			completeUserId
			completedFname
			completedLname
			completedFullName
			completeDate
			addDate
			modifiedDate
			email
			residentialContractRatio
			isHighImpactAgent
		}
		total
		start
		end
	}
}
`;

export const getTicketData = gql`
query GetTicketData($criteria: TicketSearchInput, $sort: String, $max: Int, $offset: Int) {
	tickets(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			subject
			body
			email
			priority
			addDate
			completeDate
			ticketCategoryId
			completeUserId
			assignedToUserId
			userId
			contractId
			agentId
			emailId
			category {
				name
			}
			agent {
				name
				residentialContractRatio
				isNewBroker
			}
			user {
				userId
				agentId
				fname
				lname
				isActive
				isAdmin
				isSaas
				surveySettings {
					id
					userSurveyCategoryId
					show
				}
				emails {
					email
				}
				role {
					name
				}
			}
			assignedToUser {
				fname
				lname
			}
			completeUser {
				fname
				lname
			}
			attachments {
				attachmentId
				originalName
				size
				name
				attachmentTypeId
				addDate
				user {
					fname
					lname
					emails {
						email
					}
				}
			}
			comments(sort: "addDate") {
				id
				ticketId
				body
				addDate
				modifiedDate
				isInternal
				user {
					userId
					fname
					lname
				}
			}
			contract {
				contractId
				contractNum
				rejectReason
				lostProspectReason
				status
				isNewBrokerAuction
			}
			contactMethods {
				id
				name
			}
		}
		total
		start
		end
	}
}
`;

export const getDocumentBase64 = gql`
query GetDocumentBase64($documentId: String!, $criteria: DocumentSignInput) {
	documentBase64(documentId: $documentId, criteria: $criteria) {
		response
	}
}
`;

export const getAttachmentEditData = gql`
query GetAttachmentEditData($attachmentId: String!) {
	attachmentTypes(sort: "name") {
		message {
			attachmentTypeId
			name
		}
	}
	attachment(attachmentId: $attachmentId) {
		attachmentId
		name
		description
		attachmentTypeId
		contractId
		contract {
			contractId
			contractNum
			isNewBrokerAuction
		}
	}
}
`;

export const getServiceAddData = gql`
query GetServiceAddData($criteria: ServiceTypeInput, $sort: String) {
	serviceTypes(criteria: $criteria, sort: $sort) {
		message {
			name
			serviceTypeId
		}
	}
}
`;

export const getLayeredPurchasingEnergyElectricityStatistics = gql`
query GetLayeredPurchasingEnergyElectricityStatistics(
	$marginalPricesRequest: MarginalPricesRequest!, $marginalPricesCongestionRequest: MarginalPricesCongestionRequest!
) {
	energyMarginalPricesPricingStatistics: marginalPricesPricingStatistics(marginalPricesRequest: $marginalPricesRequest) {
		rates {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
	marginalPricesCapacityStatistics(marginalPricesRequest: $marginalPricesRequest) {
		billableCapacity {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
	marginalPricesAncillaryStatistics(marginalPricesRequest: $marginalPricesRequest) {
		ancillaries {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
	marginalPricesCongestionStatistics(marginalPricesCongestionRequest: $marginalPricesCongestionRequest) {
		congestion {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
}
`;


export const getElectricityMarginalPricingStatistics = gql`
query GetElectricityMarginalPricingStatistics($marginalPricesRequest: MarginalPricesRequest!) {
	energyMarginalPricesPricingStatistics: marginalPricesPricingStatistics(marginalPricesRequest: $marginalPricesRequest) {
		rates {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
			pricePercentile
		}
	}
	marginalPricesCapacityStatistics(marginalPricesRequest: $marginalPricesRequest) {
		capacity {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
		billableCapacity {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
	marginalPricesAncillaryStatistics(marginalPricesRequest: $marginalPricesRequest) {
		ancillaries {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
	marginalPricesGlobalAdjStatistics(marginalPricesRequest: $marginalPricesRequest) {
		globalAdjustments {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
	marginalPricesHourlyPricingStatistics(marginalPricesRequest: $marginalPricesRequest) {
		rates {
			stats {
				date
				hour
				average
				max
				min
				percentile
			}
			max
			min
			average
			stdDeviation
		}
	}
}
`;

export const getMarketTrendCharts = gql`
query GetMarketTrendCharts(
	$startDate: String, $endDate: String, $serviceTypeId: String,
	$utilityId: String, $stateId: String, $rateClass: String,
	$usage: Float, $demand: Float
) {
	marketTrendCharts(criteria: {
		startDate: $startDate, endDate: $endDate, serviceTypeId: $serviceTypeId,
		utilityId: $utilityId, stateId: $stateId, rateClass: $rateClass
	}) {
		term12Rates {
			date
			rate
		}
		term24Rates {
			date
			rate
		}
		term36Rates {
			date
			rate
		}
		term12MetaData {
			term
			max
			min
			average
			stdDeviation
		}
		term24MetaData {
			term
			max
			min
			average
			stdDeviation
		}
		term36MetaData {
			term
			max
			min
			average
			stdDeviation
		}
		overallMetaData {
			max
			min
			average
			stdDeviation
		}
	}
	utilityBaseRates(criteria: {
		serviceTypeId: $serviceTypeId, utilityId: $utilityId, stateId: $stateId, rateClass: $rateClass,
		effectiveDateLte: $endDate, effectiveDateGte: $startDate,
		sampleAnnualUsage: $usage, samplePlc: $demand
	}, sort: "effectiveDate") {
		message {
			effectiveDate
			ptc
		}
	}
}
`;

export const getChartRetailUtilities = gql`
query GetChartRetailUtilities($serviceTypeId: String) {
	utilities(criteria: { serviceTypeId: $serviceTypeId, isActive: true, noChildren: true }, sort: "name") {
		message {
			utilityId
			name
			abbreviation
		}
	}
}
`;

export const getChartUtilitiesWithProperties = gql`
query GetChartUtilitiesWithProperties($serviceTypeId: String!, $chartType: String!) {
	marketUtilities(serviceTypeId: $serviceTypeId, chartType: $chartType) {
		abbreviation
		name
		utilityId
		utilityStates {
			stateId
		}
		zones {
			name
			stateId
		}
		rateClasses {
			name
			stateId
		}
	}
}
`;

export const getBulkUploadModalData = gql`
query GetBulkUploadModalData($serviceTypeId: String!, $stateId: String!) {
	utilities(criteria: { stateId: $stateId, serviceTypeId: $serviceTypeId, isActive: true, noChildren: true }, sort: "name") {
		message {
			utilityId
			name
			abbreviation
			showNameKey
			nameKeyFormat
			nameKeyLabel
			nameKeyFormatHelp
			showMeterNum
			meterNumFormat
			meterNumLabel
			meterNumFormatHelp
			showReferenceNum
			referenceNumFormat
			referenceNumLabel
			referenceNumFormatHelp
			accountNumFormatHelp
			accountNumLabel
			accountNumFormat
		}
	}
}
`;

export const getUtilitiesWithProperties = gql`
query GetUtilitiesWithProperties($serviceTypeId: String!, $stateId: String!, $sampleAnnualUsage: Float, $samplePlc: Float) {
	utilities(criteria: { stateId: $stateId, serviceTypeId: $serviceTypeId, isActive: true, noChildren: true }, sort: "name") {
		message {
			utilityId
			name
			abbreviation
			showNameKey
			nameKeyFormat
			nameKeyLabel
			nameKeyFormatHelp
			showMeterNum
			meterNumFormat
			meterNumLabel
			meterNumFormatHelp
			showReferenceNum
			referenceNumFormat
			referenceNumLabel
			referenceNumFormatHelp
			accountNumFormatHelp
			accountNumLabel
			accountNumFormat
			sampleBillAttachment {
				attachmentId
			}
			margin
			margin2
			rateClasses(stateId: $stateId, isActive: true, sort: "name") {
				name
				description
				isDefault
			}
			zones(stateId: $stateId, serviceTypeId: $serviceTypeId, isActive: true, sort: "name") {
				name
			}
			baseRates(
				stateId: $stateId, serviceTypeId: $serviceTypeId, sampleAnnualUsage: $sampleAnnualUsage,
				samplePlc: $samplePlc, sort: "effectiveDate desc"
				) {
				effectiveDate
				rateClass
				zone
				ptc
			}
			serviceTypeUnit(criteria: { stateId: $stateId, serviceTypeId: $serviceTypeId }) {
				units
				marginUnits
			}
		}
	}
}
`;

export const getUtilitiesWithPropertiesForMapping = gql`
query GetUtilitiesWithPropertiesForMapping($stateId: String!) {
	utilities(criteria: { stateId: $stateId, noChildren: true }, sort: "name") {
		message {
			utilityId
			name
			abbreviation
		}
	}
}
`;

export const getZipCodeWithDefaultProperties = gql`
query GetZipCodeWithDefaultProperties($zipCode: String!, $serviceTypeId: String!) {
	zipCode(zipCode: $zipCode) {
		cities
		stateId
		state {
			stateShort
			stateLong
			countryId
			country {
				symbol
				isoCode
				symbol2
			}
		}
		utilityZipCodeMaps(serviceTypeId: $serviceTypeId) {
			zone
			serviceTypeId
			utilityId
		}
	}
}
`;

export const getErcotZipCodeAddresses = gql`
query GetErcotZipCodeAddresses($zipCode: String!) {
	ercotZipCodeAddresses(zipCode: $zipCode) {
		accountNumber
		address
	}
}
`;

export const getQuoteVerifyData = gql`
query GetQuoteVerifyData($supplierId: String!, $signUpCriteria: SignUpRequirementInput, $switchTypeCriteria: SwitchTypeInput, ) {
	supplier(supplierId: $supplierId) {
		name
		switchTypes(criteria: $switchTypeCriteria) {
			switchTypeCode
			switchDescription
			switchHelp
			moveInTypes {
				value
				text
				allowedDates
			}
		}
	}
	signUpRequirements(criteria: $signUpCriteria) {
		showDob
		showSsn
		showTaxExemptReasons
	}
	holidays {
		message {
			date
			id
		}
	}
}
`;

export const getQuoteVerifyRFQData = gql`
query GetQuoteVerifyRFQData($criteria: SwitchTypeInput) {
	switchTypes(criteria: $criteria) {
		message {
			switchTypeCode
			switchDescription
			switchHelp
			moveInTypes {
				value
				text
				allowedDates
			}
		}
	}
}
`;

export const getQuoteInfoData = gql`
query GetQuoteInfoData($supplierId: String!, $zipCode: String!) {
	supplier(supplierId: $supplierId) {
		supplierId
		name
	}
	zipCode(zipCode: $zipCode) {
		cities
		stateId
		state {
			stateShort
			stateLong
			countryId
		}
	}
}
`;

export const getMarginAdjustments = gql`
query GetMarginAdjustments($serviceTypeId: String!, $stateId: String!, $utilityId: String!, $margin: Float!) {
	serviceType(serviceTypeId: $serviceTypeId) {
		marginAdjustments(stateId: $stateId, utilityId: $utilityId, margin: $margin) {
			label
			value
		}
	}
}
`;

export const getAutomatedRenewalNotificationData = gql`
query GetAutomatedRenewalNotificationData($serviceTypeId: String!, $stateId: String!, $utilityId: String!, $margin: Float!) {
	serviceType(serviceTypeId: $serviceTypeId) {
		marginAdjustments(stateId: $stateId, utilityId: $utilityId, margin: $margin) {
			label
			value
		}
	}
	serviceTypeUnit(criteria: { serviceTypeId: $serviceTypeId, stateId: $stateId, utilityId: $utilityId }) {
		marginUnits
	}
}
`;


export const getSessionRatesData = gql`
query GetSessionRatesData($contractId: String!, $rfqSessionRateCriteria: RateInput, $rateCriteria: RateInput) {
	contract(contractId: $contractId) {
		rfqSession {
			rates(criteria: $rfqSessionRateCriteria) {
				rfqSessionBidId
				greenPercentage
				bandwidthPercentage
				salesTax
				rate
				termName
				logo
				supplierId
				stateId
				utilityId
				billingMethod
				term
				productId
				productName
				rateClass
				displayRate
				customerId
				companyId
				usageAdjustment
				rateAddDate
				baseRate
				contractId
				premium
				rfqSessionId
				name
				commission
				addDate
				savings
				isGRTIncluded
				effectiveDate
				maxNumLocations
				isAffectedByMarginCapSplit
				marginOverMarginCap
				upfrontPaymentRule
				residualPaymentRule
				paymentAmount
				marginSplitRule
				marginCapRule
				compensationRuleDescription
				units
				commissionOptions {
					percentage
					estTotalCommission
					estAnnualPayment
					estFirstPayment
					estFirstPaymentDescription
					commissionPayPlanTypeId
					commissionPayPlanType {
						description
						header
						details
					}
				}
			}
		}
	}
	rates(criteria: $rateCriteria) {
		message {
			rateMatrixId
			addDate
			bandwidthPercentage
			greenPercentage
			baseRate
			billingMethod
			customerId
			displayRate
			displayRateNoSalesTax
			logo
			name
			productId
			productName
			rate
			savings
			supplierId
			term
			termName
			usageAdjustments
			tosPath
			planName
			disclaimerPath
			extra
			salesTax
			rateClass
			rateCodeId
			loadFactor
			puct
			planId
			usageAdjustment
			eflPath
			rateAddDate
			premium
			yracPath
			monthlyServiceFee
			commission
			cancellationPenaltyDesc
			phone3
			loadFactorNote
			contractAgentFinalMargin
			showTaxId
			effectiveDate
			maxNumLocations
			restrictionsNote
			isAffectedByMarginCapSplit
			marginOverMarginCap
			upfrontPaymentRule
			residualPaymentRule
			paymentAmount
			marginSplitRule
			marginCapRule
			compensationRuleDescription
			units
			allowMatrixRenewal
			commissionOptions {
				percentage
				estTotalCommission
				estAnnualPayment
				estFirstPayment
				estFirstPaymentDescription
				commissionPayPlanTypeId
				commissionPayPlanType {
					description
					header
					details
				}
			}
		}
	}
}
`;

export const getRatesQuotesContractSessionData = gql`
${fragments.CORE_QUOTE_PAGE_AGENT_FIELDS}
${fragments.CORE_QUOTE_PAGE_CONTRACT_FIELDS}
${fragments.CORE_QUOTE_PAGE_CUSTOMER_FIELDS}
query GetRatesQuotesContractSessionData($contractId: String!) {
	contract(contractId: $contractId) {
		...CoreQuotePageContractFields
		customer {
			...CoreQuotePageCustomerFields
		}
		agent {
			...CoreQuotePageAgentFields
		}
		serviceType {
			serviceTypeId
			units
			units2
			defaultUsage
			defaultUsageCanada
			name
			multiplier
		}
		locations(sort: "sortOrder") {
			contractLocationId
			address1
			address2
			city
			stateId
			zipCode
			rateClass
			zone
			utilityAccountNum
			utilityNameKey
			utilityMeterNum
			utilityReferenceNum
			annualUsage
			utilityId
			utility {
				utilityId
				name
				abbreviation
				margin
				margin2
				leadTime
				showNameKey
				nameKeyFormat
				nameKeyLabel
				nameKeyFormatHelp
				showMeterNum
				meterNumFormat
				meterNumLabel
				meterNumFormatHelp
				showReferenceNum
				referenceNumFormat
				referenceNumLabel
				referenceNumFormatHelp
				accountNumFormatHelp
				accountNumLabel
				accountNumFormat
			}
			state {
				stateShort
				stateLong
				countryId
			}
		}
		company {
			isSaas
			isDecoupled
			licensePlans {
				id
			}
		}
		state {
			countryId
		}
	}
}
`;

export const getRatesQuotesEclsData = gql`
${fragments.CORE_QUOTE_PAGE_AGENT_FIELDS}
query GetRatesQuotesEclsData($accountUuid: String, $agentId: String!) {
	ecls(criteria: { accountUuid: $accountUuid }) {
		message {
			utilityId
			serviceTypeId
			utilityAccountNum
			dbaName1
			dbaName2
			address1
			address2
			city
			stateId
			zipCode
			billingAddress1
			billingAddress2
			billingCity
			billingStateId
			billingZipCode
			billingCountryId
			rateClass
			annualUsage
			isUsingDefaultService
			state {
				stateId
				stateShort
				stateLong
				countryId
			}
			billingState {
				stateId
				stateShort
				stateLong
				countryId
			}
			utility {
				showNameKey
				nameKeyFormat
				nameKeyLabel
				nameKeyFormatHelp
				showMeterNum
				meterNumFormat
				meterNumLabel
				meterNumFormatHelp
				showReferenceNum
				referenceNumFormat
				referenceNumLabel
				referenceNumFormatHelp
				accountNumFormatHelp
				accountNumLabel
				accountNumFormat
			}
		}
	}
	agent(agentId: $agentId) {
		...CoreQuotePageAgentFields
	}
}
`;

export const getRatesQuotesLeadData = gql`
${fragments.CORE_QUOTE_PAGE_AGENT_FIELDS}
query GetRatesQuotesLeadData($leadId: Int!, $agentId: String!) {
	lead(id: $leadId) {
		id
		dbaName
		address1
		address2
		city
		stateId
		zipCode
		leadContacts {
			isPrimary
			fName
			lName
			mobile
			mobileCountryId
			phone
			phoneCountryId
			phoneExt
			email
		}
		state {
			stateId
			stateShort
			stateLong
			countryId
		}
	}
	agent(agentId: $agentId) {
		...CoreQuotePageAgentFields
	}
}
`;

export const getRatesQuotesContractAddServiceData = gql`
${fragments.CORE_QUOTE_PAGE_AGENT_FIELDS}
${fragments.CORE_QUOTE_PAGE_CONTRACT_FIELDS}
${fragments.CORE_QUOTE_PAGE_CUSTOMER_FIELDS}
query GetRatesQuotesContractAddServiceData($contractId: String!) {
	contract(contractId: $contractId) {
		...CoreQuotePageContractFields
		customer {
			...CoreQuotePageCustomerFields
		}
		agent {
			...CoreQuotePageAgentFields
		}
		serviceType {
			serviceTypeId
			units
			units2
			defaultUsage
			defaultUsageCanada
			name
			multiplier
		}
		locations(sort: "sortOrder") {
			address1
			address2
			city
			stateId
			zipCode
			rateClass
			zone
			state {
				stateLong
				stateShort
				countryId
			}
		}
		company {
			isSaas
			isDecoupled
			licensePlans {
				id
			}
		}
		state {
			countryId
		}
	}
}
`;

export const getRatesQuotesContractData = gql`
${fragments.CORE_QUOTE_PAGE_AGENT_FIELDS}
${fragments.CORE_QUOTE_PAGE_CONTRACT_FIELDS}
${fragments.CORE_QUOTE_PAGE_CUSTOMER_FIELDS}
query GetRatesQuotesContractData($contractId: String!) {
	contract(contractId: $contractId) {
		...CoreQuotePageContractFields
		customer {
			...CoreQuotePageCustomerFields
		}
		agent {
			...CoreQuotePageAgentFields
		}
		serviceType {
			serviceTypeId
			units
			units2
			defaultUsage
			defaultUsageCanada
			name
			multiplier
		}
		locations(sort: "sortOrder") {
			contractLocationId
			address1
			address2
			city
			stateId
			zipCode
			rateClass
			zone
			utilityAccountNum
			utilityNameKey
			utilityMeterNum
			utilityReferenceNum
			annualUsage
			utilityId
			utility {
				utilityId
				name
				abbreviation
				margin
				margin2
				leadTime
				showNameKey
				nameKeyFormat
				nameKeyLabel
				nameKeyFormatHelp
				showMeterNum
				meterNumFormat
				meterNumLabel
				meterNumFormatHelp
				showReferenceNum
				referenceNumFormat
				referenceNumLabel
				referenceNumFormatHelp
				accountNumFormatHelp
				accountNumLabel
				accountNumFormat
			}
			state {
				stateLong
				stateShort
				countryId
			}
		}
		company {
			isSaas
			isDecoupled
			licensePlans {
				id
			}
		}
		state {
			countryId
		}
	}
}
`;

export const getRatesQuotesPageAgentData = gql`
${fragments.CORE_QUOTE_PAGE_AGENT_FIELDS}
query GetRatesQuotesPageAgentData($agentId: String!) {
	agent(agentId: $agentId) {
		...CoreQuotePageAgentFields
	}
}
`;

export const getRatesQuotesPageData = gql`
query GetRatesQuotesPageData($contractDate: String) {
	countries(sort: "name") {
		message {
			id
			name
			zipCodeFormat
			isActive
			code
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "sortOrder") {
		message {
			serviceTypeId
			name
			units
			units2
			defaultUsage
			defaultUsageCanada
			multiplier
		}
	}
	commissionPayPlans(criteria: { contractDate: $contractDate }) {
		message {
			id
			name
			commissionPayPlanTypeId
		}
	}
	commissionPayPlanTypes(sort: "id") {
		message {
			id
			description
		}
	}
	holidays {
		message {
			id
			date
			holidayTypeId
			type {
				name
			}
		}
	}
}
`;

export const getBeginAgentData = gql`
query GetBeginAgentData($agentId: String!) {
	agent(agentId: $agentId) {
		agentId
		name
		isActive
		isNewBroker
		logins {
			userId
			fname
			middle
			lname
			isPrimary
			addDate
			emails {
				email
			}
		}
	}
}
`;

export const getBeginData = gql`
query GetBeginData($promoCode: String!) {
	serviceTypes(criteria: { isActive: true }, sort: "sortOrder") {
		message {
			serviceTypeId
			name
			units2
			defaultUsage
			defaultUsageCanada
		}
	}
	promoCode(slug: $promoCode) {
		id
		isActive
		agentId
		slug
		agent {
			agentId
			name
			isActive
			commissionPayPlanId
			logins {
				userId
				fname
				middle
				lname
				isPrimary
				addDate
				emails {
					email
				}
			}
		}
	}
}
`;

export const getBeginCountryData = gql`
query GetBeginCountryData($stateId: String!) {
	state(stateId: $stateId) {
		country {
			id
			name
			zipCodeFormat
			isActive
			code
		}
	}
}`;

export const getUserEditCustomerData = gql`
query GetUserEditCustomerData($customerId: String!) {
	customer(customerId: $customerId) {
		contactFName
		contactMiddle
		contactLName
		phone
		phone2
		fax
		mobile
		email
	}
	countries(sort: "sortOrder") {
		message {
			id
			name
			code
		}
	}
	supervisors: users(
		criteria: {
			roleId: "297ed5063d288ec7013d290e2dab003e,297ed5063d288ec7013d290e2dc7003f",
			isActive: true
		}, sort: "lname,fname") {
		message {
			userId
			fname
			lname
		}
	}
	roles(criteria: { name: "customer" }) {
		message {
			roleId
			name
		}
	}
	contactMethods(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getEducationAgentData = gql`
query GetEducationAgentData($agentId: String!) {
	agent(agentId: $agentId) {
		videos
		powerpoints
		parent {
			videos
			powerpoints
		}
	}
}`;

export const getDocumentTemplatesData = gql`
query GetDocumentTemplatesData {
	supplierContractTemplates: documents(criteria: {
		isActive: true, name: "%contract%", attachmentTypeId: "4028d1e53e523250013e525a81fa0004" },
		sort: "name", max: 0) {
		message {
			name
			description
			attachmentId
			addDate
		}
	}
	loaContractTemplates: documents(criteria: {
		isActive: true, name: "%loa%", attachmentTypeId: "588f51ee45ec8fe50145eddabe4a0002" },
		sort: "name", max: 0) {
		message {
			name
			description
			attachmentId
			addDate
		}
	}
	loeContractTemplates: documents(criteria: {
		isActive: true, name: "%loe%", attachmentTypeId: "588f51ee45ec8fe50145eddaf83c0003" },
		sort: "name", max: 0) {
		message {
			name
			description
			attachmentId
			addDate
		}
	}
}`;

export const getMarketOptions = gql`
query ViewMarketOptions($criteria: ViewMarketOptionInput) {
	viewMarketOptions(criteria: $criteria) {
		state {
			stateId
			stateLong
		}
		utility {
			utilityId
			name
		}
		serviceType {
			serviceTypeId
			name
		}
	}
}`;

export const getGroupedMarketsState = gql`
query ViewGroupedMarketsState($criteria: ViewGroupedMarketInput) {
	viewGroupedMarketsState(criteria: $criteria) {
		stateId
		stateLong
		stateShort
		suppliers {
			supplierId
			name
			hasElectricity
			hasNaturalGas
		}
	}
}`;

export const getGroupedMarketsUtility = gql`
query ViewGroupedMarketsUtility($criteria: ViewGroupedMarketInput) {
	viewGroupedMarketsUtility(criteria: $criteria) {
		data {
			stateId
			stateLong
			utilities {
				utilityId
				name
				suppliers {
					supplierId
					name
					hasNaturalGas
					hasElectricity
				}
			}
		}
		stats {
			numStates
			numUtilities
			numSuppliers
		}
	}
}`;

export const exportGroupedMarkets = gql`
query ExportGroupedMarkets($criteria: ViewGroupedMarketInput) {
	exportGroupedMarkets(criteria: $criteria) {
		response
	}
}`;

export const exportActualPayables = gql`
query ExportActualPayables($criteria: ActualPayableReportInput) {
	exportActualPayables(criteria: $criteria) {
		response
	}
}`;

export const exportActualReceivables = gql`
query ExportActualReceivables($criteria: ActualReceivableReportInput) {
	exportActualReceivables(criteria: $criteria) {
		response
	}
}`;

export const getUserEditSupplierData = gql`
query GetUserEditSupplierData($supplierId: String!) {
	supplier(supplierId: $supplierId) {
		name
		supplierId
		contactFname
		contactMiddle
		contactLname
		phone
		phone2
		fax
		mobile
		email
		isActive
		logins {
			companyId
		}
	}
}
`;

export const getUserEditAgentData = gql`
query GetUserEditAgentData($agentId: String!) {
	agent(agentId: $agentId) {
		agentId
		phone
		phone2
		fax
		mobile
		isActive
		companyId
		logins {
			userId
			fname
			middle
			lname
			isPrimary
			addDate
			emails {
				email
			}
		}
	}
}
`;

export const getUserEditRfqSettingsData = gql`
query GetUserEditRfqSettingsData {
	serviceTypes(sort: "name", criteria: { isActive: true }) {
		message {
			serviceTypeId
			name
			utilityServiceTypes {
				serviceTypeId
				utility {
					utilityId
					name
				}
			}
		}
	}
	states(sort: "stateShort") {
		message {
			stateId
			stateLong
		}
	}
}
`;

export const getUserAddEditPageData = gql`
query GetUserAddEditPageData {
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			name
			serviceTypeId
			units
			utilityServiceTypes {
				utility {
					utilityId
					name
					utilityStates {
						stateId
					}
				}
			}
		}
	}
	states(sort: "stateLong") {
		message {
			stateLong
			stateId
		}
	}
	countries(sort: "sortOrder") {
		message {
			id
			name
			code
		}
	}
	supervisors: users(
		criteria: {
		roleId: "297ed5063d288ec7013d290e2dab003e,297ed5063d288ec7013d290e2dc7003f",
		isActive: true
	}, sort: "lname,fname") {
		message {
			userId
			fname
			lname
		}
	}
	roles {
		message {
			roleId
			name
			dashboards {
				id
				name
			}
		}
	}
	contactMethods(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	dashboards(sort: "name") {
		message {
			id
			name
		}
	}
}
`;

export const getUserData = gql`
query GetUserData($userId: String!) {
	user(userId: $userId) {
		userId
		agentId
		supplierId
		fname
		lname
		username
		lastLogin
		addDate
		isActive
		roleId
		fullName
		isAdmin
		isSaas
		emails {
			email
		}
		role {
			name
		}
		agent {
			name
		}
	}
}
`;

export const getUserEditUserData = gql`
query GetUserEditUserData($userId: String!) {
	user(userId: $userId) {
		userId
		fname
		lname
		middle
		phone
		phoneCountryId
		phone2
		phone2CountryId
		fax
		faxCountryId
		mobile
		mobileCountryId
		roleId
		supervisorId
		supervisor2Id
		username
		isActive
		agentId
		subscribeToErrorNotifications
		isAccountManager
		isCommissionUser
		defaultRateSort
		companyId
		isAdmin
		isSaas
		isOwner
		defaultDashboardId
		isPrimary
		emails {
			email
		}
		contactMethods {
			id
		}
		agent {
			name
		}
		supplier {
			name
		}
		role {
			name
			dashboards {
				id
			}
		}
		dashboards {
			id
		}
	}
}
`;

export const getUserEditUserDataRfqSettings = gql`
query GetUserEditUserDataRfqSettings($userId: String!) {
	user(userId: $userId) {
		rfqSettings {
			id
			serviceTypeId
			utilityId
			stateId
			userId
			supplierId
			minUsage
			maxUsage
			isActive
			isPaused
			pauseExpirationDate
			userRfqSettingRestrictionTypeId
			numRestrictedMonths
			restrictedStartDate
			restrictedEndDate
			reviewedDate
			serviceType {
				name
				units
			}
			state {
				stateLong
			}
			utility {
				name
			}
		}
	}
	userRfqSettingRestrictionTypes {
		message {
			id
			name
		}
	}
}
`;

export const getUserEditUserDataApiKeys = gql`
query GetUserEditUserDataApiKeys($userId: String!) {
	user(userId: $userId) {
		apiKeys {
			id
			key
			referrer
			description
			addDate
			limit
			allowsGraphQL
			allowsLogin
		}
	}
}
`;

export const getServiceTypeSuppliers = gql`
query GetServiceTypeSuppliers($serviceTypeId: String!) {
	serviceType(serviceTypeId: $serviceTypeId) {
		suppliers(sort: "name") {
			supplierId
			name
		}
	}
}
`;

export const getContractEditAgentChanged = gql`
${fragments.CORE_CONTRACT_EDIT_AGENT}
query GetContractEditAgentChanged($agentId: String!) {
	agent(agentId: $agentId) {
		...CoreContractEditAgentFields
	}
}
`;

export const getSwitchTypes = gql`
query GetSwitchTypes($supplierId: String!, $criteria: SwitchTypeInput) {
	supplier(supplierId: $supplierId) {
		switchTypes(criteria: $criteria) {
			switchTypeCode
			switchDescription
			switchHelp
			moveInTypes {
				value
				text
				allowedDates
			}
		}
	}
}
`;

export const getContractEditData = gql`
${fragments.CORE_CONTRACT_EDIT_AGENT}
query GetContractEditData($contractId: String!) {
	contract(contractId: $contractId) {
		contractId
		contractNum
		agentId
		supplierId
		productId
		serviceTypeId
		status
		effectiveDate
		confirmationDate
		term
		annualUsage
		rate
		margin
		bufferMargin
		commissionRate
		commissionRate2
		commissionRate3
		underwriterId
		switchTypeCode
		switchDate
		rejectReason
		moveInType
		dwellingType
		isResidential
		stateId
		promoCodeId
		previousRate
		commissionStatus
		submissionStatus
		rfqSessionId
		awardDate
		units
		isTaxExempt
		taxExemptReason
		availableStatuses
		isFraudulent
		marginChangeReasonTypeId
		marginChangeReason
		rejectReasonCategoryId
		lostProspectReason
		isGuaranteePayment
		isNewBrokerAuction
		isBlendAndExtend
		blendAndExtendRenewTerm
		addClause
		rejectReasonSubCategoryId
		etfAmount
		hasCustomReceivableRule
		naturalGasUseTypeId
		commissionSplitBufferMargin
		matrixAgentBufferMargin
		salesTax
		planId
		state {
			stateShort
			country {
				symbol
				isoCode
				symbol2
			}
		}
		serviceType {
			units2
			multiplier
			suppliers(sort: "name") {
				supplierId
				name
			}
		}
		agent {
			...CoreContractEditAgentFields
		}
		locations {
			utilityId
			utilityAccountNum
			rateClass
			utility {
				name
				abbreviation
			}
		}
		company {
			name
			isSaas
			licensePlans {
				id
			}
		}
		renewalContract {
			expirationDate
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name asc") {
		message {
			serviceTypeId
			name
			units2
			multiplier
		}
	}
	products(criteria: { isActive: true }, sort: "name asc") {
		message {
			id
			name
			serviceTypeId
		}
	}
	underwriters: users(
		criteria: {
		roleId: "297ed5063d288ec7013d290e2dab003e,297ed5063d288ec7013d290e2dc7003f,8a258005641ce83201641cef2db30000",
		isActive: true
	}, sort: "lname,fname") {
		message {
			userId
			fname
			lname
		}
	}
	marginChangeReasonTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	rejectReasonCategories(sort: "sortOrder") {
		message {
			id
			name
			subCategories {
				id
				name
				supplierId
				rejectReasonAutoFill
			}
		}
	}
	naturalGasUseTypes(sort: "name") {
		message {
			id
			name
		}
	}
}
`;

export const getServiceTypeEditData = gql`
query GetServiceTypeEditData($serviceTypeId: String!) {
	serviceType(serviceTypeId: $serviceTypeId) {
		serviceTypeId
		name
		abbreviation
		description
		isActive
		requiresResidentialEnrollment
		units
		units2
		multiplier
		defaultUsage
		defaultUsageCanada
		sortOrder
	}
}
`;

export const getServiceTypesListData = gql`
query GetServiceTypesListData($sort: String) {
	serviceTypes(sort: $sort) {
		message {
			serviceTypeId
			name
			description
			isActive
			units
			requiresResidentialEnrollment
		}
	}
}
`;

export const getAgents = gql`
query GetAgents {
	agents(sort: "name") {
		message {
			agentId
			name
			isNewBroker
			logins {
				userId
				fname
				middle
				lname
				isPrimary
				addDate
				emails {
					email
				}
			}
		}
	}
}
`;

export const getAlertTypeEditData = gql`
query GetAlertTypeEditData($id: Int!) {
	alertType(id: $id) {
		id
		name
		description
	}
}
`;

export const getAlertTypesListData = gql`
query GetAlertTypesListData($sort: String) {
	alertTypes(sort: $sort) {
		message {
			id
			name
			description
		}
	}
}
`;

export const getSetupMenu = gql`
query GetSetupMenu($criteria: MenuInput, $sort: String) {
	userMenus(criteria: $criteria, sort: $sort) {
		message {
			menuId
			name
			eventName
			queryString
			permissions
			parentId
		}
	}
}
`;

export const checkPromoCodeUnique = gql`
query CheckPromoCodeUnique($slug: String!) {
	promoCodeIsUnique(slug: $slug) {
		slug
		isUnique
	}
}
`;

export const getAgentNotesData = gql`
query GetAgentNotesData($criteria: NoteInput, $sort: String, $max: Int, $offset: Int) {
	notes(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			noteId
			addDate
			subject
			body
			noteType {
				noteTypeId
				name
			}
			user {
				userId
				fname
				lname
			}
		}
		total
		start
		end
	}
}
`;

export const getAgentEditParentChanged = gql`
query GetAgentEditParentChanged($agentId: String!) {
	agent(agentId: $agentId) {
		agentId
		name
		includeBufferMarginForAllSubAgents
		commissionSplitTypeId
		isNewBroker
		commissionRate
		useAgentDiscountProgram
		dropRatio
		logins {
			userId
			fname
			middle
			lname
			isPrimary
			addDate
			emails {
				email
			}
		}
	}
}
`;

export const getAgentEditData = gql`
query GetAgentEditData($agentId: String!) {
	agent(agentId: $agentId) {
		agentId
		brotherId
		parentId
		name
		supportEmail
		countryId
		phoneCountryId
		phone2CountryId
		faxCountryId
		mobileCountryId
		stateId
		phone
		phone2
		phoneExt
		fax
		mobile
		mailServerId
		customerCompany
		supplierCompany
		agentDomainAlias
		businessEntityTypeId
		businessEntityTypeTaxClassificationId
		useReports
		useSupportSystem
		commissionRate
		commissionRate2
		commissionRate3
		residentialCommissionRate
		residentialCommissionRate2
		residentialCommissionRate3
		discountFactor
		address1
		address2
		city
		zipCode
		ssn
		legalTaxName
		businessTaxName
		underwriterId
		commissionCheckMethod
		showTrainingVideos
		showEducation
		isEmployee
		isActive
		isFraudulent
		promoCodeCount
		isEnterprise
		hasFailedPayment
		paymentIntentRequiresActionDate
		stateOther
		electricityResidentialThankYouLink
		gasResidentialThankYouLink
		isInHouseIndirectSale
		showUtilityCheck
		showCapSplitWarning
		negativeBalance
		technicalEmail
		allowAutomaticResidentialConfirmation
		headshotUrl
		isDefaultInHouseIndirectSale
		isVerified
		termsOfServiceAttachmentId
		eCommerceWebsiteId
		showFinancialProfile
		isOnHold
		idDocumentAttachmentId
		isCommissionPaused
		isBundledAgent
		isSaas
		companyId
		accountManagerId
		includeBufferMargin
		includeBufferMarginForAllSubAgents
		isNewBroker
		commissionPayPlanTypeId
		marketOpportunitiesAlertEffectiveDate
		commissionSplitTypeId
		commissionSplitElectricityBufferMargin
		commissionSplitGasBufferMargin
		webHookUrl
		webHookSecret
		darkBackgroundsLogoId
		lightBackgroundsLogoId
		hideContractDetailsFromBrother
		isHighImpactAgent
		matrixElectricityBufferMargin
		matrixGasBufferMargin
		matrixElectricityBufferMarginStaging
		matrixGasBufferMarginStaging
		auctionElectricityBufferMargin
		auctionGasBufferMargin
		auctionElectricityBufferMarginStaging
		auctionGasBufferMarginStaging
		isHighImpactAgentOverride
		useAgentDiscountProgram
		hasUnlimitedSalesCodes
		hasResidentialCustomerPoa
		dropRatio
		maxReversibleCommission
		maxReversibleCommissionOverride
		reversibleCommission
		commissionSplitBufferMarginCommissionRate
		commissionSplitBufferMarginCommissionRate2
		complianceDropPredictionThreshold
		w9AttachmentId
		showCustomerCrm
		showBrokerCrm
		canDoQuickPay
		quickPayRate
		matrixElectricityMarginCap
		matrixGasMarginCap
		coverPageAttachmentId
		useResidualCommissionRate
		residualCommissionRate
		darkBackgroundsLogo {
			logoId
			attachmentId
			name
			attachment {
				name
			}
		}
		lightBackgroundsLogo {
			logoId
			attachmentId
			name
			attachment {
				name
			}
		}
		emailPreferences {
			id
		}
		state {
			stateId
			stateLong
			stateShort
		}
		country {
			id
			name
			code
		}
		phoneCountry {
			id
			name
			code
		}
		phone2Country {
			id
			name
			code
		}
		faxCountry {
			id
			name
			code
		}
		mobileCountry {
			id
			name
			code
		}
		brother {
			agentId
			name
			isNewBroker
			isEmployee
			logins {
				userId
				fname
				middle
				lname
				isPrimary
				addDate
				emails {
					email
				}
			}
		}
		parent {
			agentId
			name
			isNewBroker
			includeBufferMarginForAllSubAgents
			commissionSplitTypeId
			useAgentDiscountProgram
			logins {
				userId
				fname
				middle
				lname
				isPrimary
				addDate
				emails {
					email
				}
			}
		}
		mailServer {
			server
			port
			username
			password
			useSSL
			useTLS
			fromEmail
			mailServerTypeId
			mailServerType {
				id
				name
				server
				port
				useTLS
				useSSL
				fromEmailLabel
				usernameLabel
				passwordLabel
				showFromEmail
				defaultUsername
				hint
			}
		}
		eCommerceWebsite {
			domainName
			contactPhoneNumber
			contactEmail
			contactAddress1
			contactAddress2
			contactCity
			contactStateId
			contactZipCode
			heroLogoAttachmentId
			navbarLogoAttachmentId
			footerLogoAttachmentId
			contactPhoneNumberCountryId
			contactCountryId
			contactStateOther
		}
		attachments {
			attachmentId
			name
			attachmentTypeId
			size
			originalName
		}
		promoCodes(sort: "isDefault desc, isActive desc, slug") {
			id
			agentId
			slug
			description
			isActive
			isDefault
			isResidentialPowerKioskDirectAllowed
			hasContracts
			rateMatrixes {
				id
				utilityId
				agentId
				serviceTypeId
				minUsage
				maxUsage
				rate
			}
		}
		underwriter {
			userId
			fname
			lname
		}
		suppliers {
			supplierId
		}
		educations(criteria: { isGlobal: false }) {
			id
			agentId
			educationTypeId
			subTitle
			thumbnailAttachmentId
			path
			pageId
		}
		logins {
			userId
			fname
			middle
			lname
			isPrimary
			addDate
			emails {
				email
			}
		}
		pauseCommissionReasonNote {
			subject
			body
			agentId
			noteType {
				noteTypeId
				noteTypeCategoryId
			}
		}
		themes {
			id
			agentThemeCategoryId
			primary
			secondary
			tertiary1
			tertiary2
			tertiary3
			logoAttachmentId
			useParentNameAsCompanyNameForProposal
			agentThemeCategory {
				name
			}
		}
	}
}
`;

export const getAgentEditPageData = gql`
query GetAgentEditPageData {
	countries(sort: "sortOrder asc") {
		message {
			id
			name
			code
		}
	}
	states(criteria: { isActive: true }, sort: "stateShort asc") {
		message {
			stateId
			stateShort
			stateLong
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
			units
			multiplier
			utilityServiceTypes {
				utility {
					utilityId
					name
				}
			}
		}
	}
	businessEntityTypes(sort: "name") {
		message {
			id
			name
			businessEntityTypeTaxClassifications(sort: "name") {
				id
				name
			}
		}
	}
	underwriters: users(criteria: { roleId: "8a258005641ce83201641cef2db30000" }, sort: "fname") {
		message {
			userId
			fname
			lname
		}
	}
	accountManagers: users(criteria: { isActive: true, isAccountManager: true }, sort: "fname,lname") {
		message {
			userId
			fname
			lname
			agentId
		}
	}
	upfrontCommissionPayPlans: commissionPayPlans(criteria: { commissionPayPlanTypeId: 2 }) {
		message {
			id
			contractStartDate
			contractEndDate
		}
	}
	commissionPayPlanTypes {
		message {
			id
			description
		}
	}
	residentialSuppliers: suppliers(criteria: { isActive: true, hasAPI: true }) {
		message {
			supplierId
			name
		}
	}
	emailPreferences {
		message {
			id
			name
			slug
			description
			sortOrder
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
			houseAccount {
				agentId
				name
				includeBufferMarginForAllSubAgents
				logins {
					isPrimary
					addDate
					emails {
						email
					}
				}
			}
		}
	}
	mailServerTypes(sort: "sortOrder, name") {
    message {
			id
			name
			server
			port
			useTLS
			useSSL
			fromEmailLabel
			usernameLabel
			passwordLabel
			showFromEmail
			defaultUsername
			hint
		}
	}
	commissionSplitTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	pauseCommissionReasonNoteTypes: noteTypes(criteria: { noteTypeCategoryId: 3 }) {
		message {
			noteTypeId
			name
			description
			noteTypeCategoryId
		}
	}
	agentThemeCategories {
		message {
			id
			name
		}
	}
}
`;

export const getParentAgentInfoPageData = gql`
query GetParentAgentInfoPageData($agentId: String!) {
	agent(agentId: $agentId) {
		agentId
		name
		phone
		phone2
		phoneExt
		fax
		mobile
		address1
		address2
		city
		zipCode
		parentId
		promoCodeCount
		agentBundleCount
		isNewBroker
		hasFailedPayment
		paymentIntentRequiresActionDate
		agentStripeCustomer {
			stripeCustomerId
		}
		state {
			stateShort
			stateLong
		}
		promoCodes {
			slug
		}
		licensePlans {
			id
			name
			years
			parents {
				id
			}
		}
		logins {
			userId
			fname
			middle
			lname
			isPrimary
			addDate
			emails {
				email
			}
		}
	}
}
`;

export const getAgentInfoPageData = gql`
query GetAgentInfoPageData($agentId: String!) {
	agent(agentId: $agentId) {
		agentId
		name
		phone
		mobile
		parentId
		brotherId
		isSaas
		promoCodeCount
		agentBundleCount
		isNewBroker
		phoneCountryId
		mobileCountryId
		hasFailedPayment
		paymentIntentRequiresActionDate
		agentStripeCustomer {
			stripeCustomerId
		}
		licensePlans {
			id
			name
			years
			parents {
				id
			}
		}
		logins {
			userId
			fname
			middle
			lname
			username
			addDate
			lastLogin
			isActive
			agentId
			supplierId
			phone
			isLocked
			isPasswordExpired
			isPrimary
			emails {
				email
			}
		}
	}
}
`;

export const getSidebarData = gql`
query GetUserPageData($userId: String!) {
	user(userId: $userId) {
		isAdmin
		isSaas
		role {
			name
			permissions {
				name
			}
		}
		agent {
			parent {
				useReports
			}
		}
	}
}
`;

export const getAgentAdvancedListPageData = gql`
query GetAgentAdvancedListPageData($criteria: AgentsAdvancedInput!, $sort: String, $max: Int, $offset: Int) {
	agentsAdvanced(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			licensePlans {
				name
			}
			parent {
				agentId
				name
				isNewBroker
			}
			brother {
				agentId
				name
				isNewBroker
			}
			state {
				stateId
				stateShort
			}
			agentId
			brotherId
			parentId
			stateId
			name
			contactName
			email
			phone
			city
			commissionRate
			commissionRate2
			commissionRate3
			hasFailedPayment
			paymentIntentRequiresActionDate
			isActive
			showUtilityCheck
			addDate
			isNewBroker
		}
		total
	}
}
`;

export const logoutUser = gql`
query LogoutUser($tokens: [String!]!) {
	logoutUser(tokens: $tokens)
}
`;

export const impersonateUser = gql`
${fragments.CORE_LOGGEDINUSER_FIELDS}
query ImpersonateUser($userId: String!) {
	impersonateUser(userId: $userId) {
		token
		refreshToken
		loggedInUser: user {
			...CoreLoggedInUserFields
		}
	}
}
`;

export const proposal = gql`
query GenerateProposal($proposal: ProposalInput!) {
	proposal(proposal: $proposal) {
		response
	}
}
`;

export const invoicePdf = gql`
query GenerateInvoicePdf($invoiceBase64: InvoicePdfInput!) {
	invoiceBase64(invoiceBase64: $invoiceBase64) {
		response
	}
}
`;

export const getContractSupplierLoaContractPdfBase64 = gql`
query GetContractSupplierLoaContractPdfBase64($contractId: String!) {
	contractSupplierLoaContractPdfBase64(contractId: $contractId) {
		response
	}
}
`;

export const authenticateUser = gql`
${fragments.CORE_LOGGEDINUSER_FIELDS}
query AuthenticateUser($username: String!, $password: String!) {
	authenticateUser(username: $username, password: $password) {
		token
		refreshToken
		loggedInUser: user {
			...CoreLoggedInUserFields
		}
	}
}
`;

export const getTaskPageUser = gql`
query GetTaskPageUser($userId: String!) {
	user(userId: $userId) {
		userId
		roleId
		agentId
	}
} `;

export const getSalesReportData = gql`
query GetSalesReportData($reportId: String!, $criteria: SalesReportInput) {
	salesReport(reportId: $reportId, criteria: $criteria) {
		year
		month
		termValue
		numOfDeals
	}
}
`;

export const getSalesReportPageData = gql`
query GetSalesReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateShort
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getSalesTeamCommissionReportPageData = gql`
query GetSalesReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
	agents(criteria: { isInHouseIndirectSale: true, isActive: true }) {
		message {
			agentId
			name
		}
	}
}
`;

export const getAgentSalesReportLicenseDetailsData = gql`
query GetAgentSalesReportLicenseDetailsData($criteria: AgentSalesReportLicenseDetailInput, $sort: String, $max: Int, $offset: Int) {
	agentSalesReportLicenseDetails(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			agentId
			agentName
			brotherId
			addDate
			licenseName
			hasApi
			hasTurnKey
			hasAgentBundle
			hasSalesCodeBundle
			isInbound
			isOutbound
		}
		total
	}
	agentSalesReportLicenseDetailStats(criteria: $criteria) {
		licenseFeeSoldAmount
		licenseFeeProjectionNextQuarterAmount
		numLicensesSold
		numLicensesSoldInbound
		numLicensesSoldOutbound
	}
}
`;

export const getAgentSalesReportContractsDashboardData = gql`
query GetAgentSalesReportContractsDashboardData(
	$criteria: AgentSalesReportContractsDashboardInput, $sort: String, $max: Int, $offset: Int
) {
	agentSalesReportContractsDashboard(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		totalEnergyCommission
		contracts {
			message {
				contractId
				contractNum
				customerId
				dbaName
				agentId
				agentName
				brotherId
				isResidential
				serviceTypeId
				commodity
				annualUsage
				units
				effectiveDate
				term
				confirmationDate
				supplierPaymentDate
				supplierPayment
			}
			total
		}
	}
}
`;

export const getQuoteLogsReportPageData = gql`
query GetQuoteLogsReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	utilities(criteria: { isActive: true }, sort: "abbreviation") {
		message {
			utilityId
			abbreviation
		}
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getRateMatrixData = gql`
query GetRateMatrixData($reportId: String!, $reportRequest: RateMatrixInput) {
	rateMatrixReport(reportId: $reportId, criteria: $reportRequest) {
		message {
			rateClass
			count
			minUsage
			maxEffectiveDate
			minAddDate
			maxAddDate
			minEffectiveDate
			zone
			maxUsage
			supplier {
				name
			}
			utility {
				abbreviation
			}
			state {
				stateShort
			}
			serviceType {
				name
			}
		}
	}
}
`;

export const getRateMatrixReportPageData = gql`
query GetRateMatrixReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateShort
			stateLong
		}
	}
	suppliers(criteria: { isActive: true }, sort: "name", max: 0) {
		message {
			supplierId
			name
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
}
`;

export const getSiteTrafficMonitorData = gql`
query GetSiteTrafficMonitorData($reportId: String!, $reportRequest: CustomerAnalyticReportInput) {
	siteTrafficMonitorReport(reportId: $reportId, criteria: $reportRequest) {
		contractId
		zone
		zipCode
		phone
		commodity
		state
		annualUsage
		term
		email
		effectiveDate
		rateClass
		dbaName
		agentName
		supplierName
		addDate
		city
		contractNum
		address1
		utility
		lastCompleteState
	}
}
`;

export const getSiteTrafficMonitorReportPageData = gql`
query GetSiteTrafficMonitorReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getSupplierListReportData = gql`
query GetSupplierListReportData($criteria: SupplierListReportInput) {
	supplierListReportTotals(criteria: $criteria) {
		overallAuctionParticipationRatio
		dailyAuctionParticipationRatio
		optOutRatio
		loeRequestRatio
	}
	supplierListReport(criteria: $criteria) {
		supplierId
		name
		email
		isActive
		overallAuctionParticipationRatio
		dailyAuctionParticipationRatio
		optOutRatio
		loeRequestRatio
		noBidRatio
		numAuctionsWon
		numAuctionsInvited
	}
}
`;

export const getSupplierLatencyReportPageData = gql`
query GetSupplierLatencyReportPageData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			name
			serviceTypeId
			units
		}
	}
}
`;

export const getSupplierLatencyReportData = gql`
query GetSupplierLatencyReportData($criteria: SupplierLatencyReportInput) {
	supplierLatencyReport(criteria: $criteria) {
		supplierId
		name
		email
		serviceTypeId
		commodity
		stateId
		stateShort
		utilityId
		utilityName
		contractNum
		annualUsage
		auctionEndDate
		rfqSessionBidAddDate
		brokerEndDateLatency
		supplierEndDateLatency
		numLocations
	}
}
`;

export const getGuaranteedPaymentsReportData = gql`
query GetGuaranteedPaymentsReportData($criteria: GuaranteedPaymentsReportInput) {
	guaranteedPaymentsReport(criteria: $criteria) {
		message {
			accountAdjustmentId
			amount
			isProcessed
			addDate
			usage
			margin
			agentId
			agentName
			contractId
			contractNum
			customerId
			dbaName
			supplierId
			supplierName
		}
	}
}
`;

export const getGuaranteedPaymentsReportPageData = gql`
query GetGuaranteedPaymentsReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
	suppliers(criteria: { isActive: true }, sort: "name") {
		message {
			supplierId
			name
		}
	}
}
`;

export const getSupplierListReportPageData = gql`
query GetSupplierListReportPageData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			name
			serviceTypeId
			units
		}
	}
}
`;

export const getAuctionStatsReportPageData = gql`
query GetAuctionStatsReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getAgentFinancialProfilesReportData = gql`
query GetAgentFinancialProfileReportData($criteria: AgentFinancialProfileReportInput, $sort: String) {
	agentFinancialProfilesReport(criteria: $criteria, sort: $sort) {
		agentId
		agentName
		parentId
		parentName
		totalTermValue
		totalRemainingTermValue
		reversibleCommission
		negativeBalance
		futureCommissionAmount
		levelOneCommissionUpfrontRate
		levelTwoCommissionUpfrontRate
		levelThreeCommissionUpfrontRate
	}
}
`;

export const getAgentFinancialProfilesReportPageData = gql`
query GetAgentFinancialProfilesReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getAgentFinancialProfileBreakdownReportData = gql`
query AgentFinancialProfileBreakdownReportData($agentId: String!, $companyId: String!) {
	agent(agentId: $agentId) {
		negativeBalance
	}
	agentFinancialProfileBreakdown(agentId: $agentId, companyId: $companyId) {
		futureCommissions {
			contractId
			contractNum
			dbaName
			total
		}
		currentMonthCommissions {
			contractId
			contractNum
			dbaName
			total
		}
		lastMonthCommissions {
			contractId
			contractNum
			dbaName
			total
		}
		reversibleCommissions {
			contractId
			contractNum
			dbaName
			total
		}
	}
}
`;

export const getAgentFinancialProfileBreakdownReportPageData = gql`
query getAgentFinancialProfileBreakdownReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getRateClassPageData = gql`
query GetRateClassPageData {
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
}
`;

export const getUtilitySupplierMapPageData = gql`
query GetUtilitySupplierMapPageData {
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
			countryId
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	countries(sort: "name") {
		message {
			id
			zipCodeFormat
		}
	}
}
`;

export const getServiceTypesUnitBeginData = gql`
query GetServiceTypesUnitBeginData($serviceTypeId: String!) {
	serviceType(serviceTypeId: $serviceTypeId) {
		name
		units
		units2
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	utilities(criteria: { serviceTypeId: $serviceTypeId }, sort: "name") {
		message {
			utilityId
			name
			abbreviation
			utilityStates {
				stateId
				state {
					stateId
					stateLong
				}
			}
		}
	}
}
`;

export const getServiceTypesUnitsData = gql`
query GetServiceTypesUnitsData($serviceTypeId: String) {
	serviceTypeUnits(criteria: { serviceTypeId: $serviceTypeId }) {
		message {
			id
			serviceTypeId
			stateId
			utilityId
			units
			marginUnits
		}
	}
}
`;

export const getCsatReportPageData = gql`
query GetCsatReportPageData($userSurveyCategoryTypeId: Int) {
	ticketCategories(criteria: { isActive: true }, sort: "name") {
		message {
			id
			name
			description
			isActive
		}
	}
	userSurveyCategories(criteria: { userSurveyCategoryTypeId: $userSurveyCategoryTypeId }, sort: "name") {
		message {
			id
			name
		}
	}
}
`;


export const getCsatReportData = gql`
query GetCsatReportData($overallCriteria: UserSurveyInput, $categoryCriteria: UserSurveyInput, $sort: String, $max: Int, $offset: Int) {
	userSurveys(criteria: $categoryCriteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			recommendScore
			respondantType
			scoreReason
			improveReason
			canContact
			bestEmail
			addDate
			userId
			fullName
			userSurveyCategoryId
			ticketId
			ticketCategoryID
			parentBrokerage
			agentId
		}
		total
	}
	overallStats: userSurveyStats(criteria: $overallCriteria) {
		score
		surveysShown
		engagements
		numNotClickedInitialLink
	}
	categoryStats: userSurveyStats(criteria: $categoryCriteria) {
		score
	}
}
`;

export const getNpsReportData = gql`
query GetNpsReportData($criteria: UserSurveyInput, $sort: String, $max: Int, $offset: Int) {
	userSurveys(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			recommendScore
			respondantType
			scoreReason
			improveReason
			canContact
			bestEmail
			addDate
			userId
			fullName
			parentBrokerage
			agentId
		}
		total
	}
	userSurveyStats(criteria: $criteria) {
		score
		surveysShown
		engagements
	}
}
`;

export const getRateClassBeginPageData = gql`
query GetRateClassBeginPageData($utilityId: String!) {
	utility(utilityId: $utilityId) {
		utilityStates {
			state {
				stateId
				stateLong
			}
		}
	}
}
`;

export const getRateClassEditPageData = gql`
query GetRateClassEditPageData($utilityId: String!, $stateId: String!) {
	utility(utilityId: $utilityId) {
		rateClasses(stateId: $stateId) {
			utilityRateClassId
			name
			description
			isDefault
			isActive
			utilityId
			stateId
			isContractDefault
		}
	}
	state(stateId: $stateId) {
		stateLong
	}
}
`;

export const getUtilityHubPageData = gql`
query GetUtilityHubPageData {
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
}
`;

export const getUtilityHubBeginPageData = gql`
query GetUtilityHubBeginPageData($utilityId: String!) {
	utility(utilityId: $utilityId) {
		utilityStates {
			state {
				stateId
				stateLong
			}
		}
	}
}
`;

export const getUtilityHubEditPageData = gql`
query GetUtilityHubEditPageData($utilityId: String!, $stateId: String!) {
	utility(utilityId: $utilityId) {
		hubs(stateId: $stateId) {
			id
			utilityId
			stateId
			hubId
		}
	}
	hubs(sort: "name") {
		message {
			id
			name
		}
	}
	state(stateId: $stateId) {
		stateLong
	}
}
`;

export const getBaseRateEditPageData = gql`
query GetBaseRateEditPageData($utilityId: String!, $serviceTypeId: String!, $stateId: String!) {
	utility(utilityId: $utilityId) {
		rateClasses(stateId: $stateId) {
			name
		}
		zones(stateId: $stateId, serviceTypeId: $serviceTypeId) {
			name
			serviceTypeId
		}
		baseRates(stateId: $stateId, serviceTypeId: $serviceTypeId) {
			id
			baseRate
			utilityId
			stateId
			serviceTypeId
			rateClass
			zone
			effectiveDate
			demandCharge
			adjustmentCharge
			customerCharge
		}
	}
	state(stateId: $stateId) {
		stateLong
	}
	serviceType(serviceTypeId: $serviceTypeId) {
		name
	}
	unit(serviceTypeId: $serviceTypeId, stateId: $stateId, utilityId: $utilityId)
}
`;

export const getServiceFeeEditPageData = gql`
query GetServiceFeeEditPageData($utilityId: String!, $serviceTypeId: String!, $stateId: String!) {
	utility(utilityId: $utilityId) {
		serviceFees(stateId: $stateId, serviceTypeId: $serviceTypeId) {
			id
			utilityId
			stateId
			serviceTypeId
			amount
		}
	}
	state(stateId: $stateId) {
		stateLong
	}
	serviceType(serviceTypeId: $serviceTypeId) {
		name
	}
	unit(serviceTypeId: $serviceTypeId, stateId: $stateId, utilityId: $utilityId)
}
`;

export const getBaseRateBeginPageData = gql`
query GetBaseRateBeginPageData($utilityId: String!) {
	utility(utilityId: $utilityId) {
		utilityStates {
			state {
				stateId
				stateLong
			}
		}
		utilityServiceTypes {
			serviceType {
				serviceTypeId
				name
			}
		}
	}
}
`;

export const getServiceFeeBeginPageData = gql`
query GetServiceFeeBeginPageData($utilityId: String!) {
	utility(utilityId: $utilityId) {
		utilityStates {
			state {
				stateId
				stateLong
			}
		}
		utilityServiceTypes {
			serviceType {
				serviceTypeId
				name
			}
		}
	}
}
`;

export const getRateSettingsPageData = gql`
query getRateSettingsPageData($supplierId: String!) {
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	supplier(supplierId: $supplierId) {
		serviceTypes {
			serviceType {
				serviceTypeId
				name
			}
		}
	}
}
`;

export const getNavbarAgentData = gql`
query GetNavbarAgentData($agentId: String!) {
	agent(agentId: $agentId) {
		phone
		customerCompany
		isEnterprise
		futureCommissionAmount
		totalUpfrontCommissionPaidAmount
		flowedTermValue
		commissionRate
		commissionRate2
		commissionRate3
		negativeBalance
		reversibleCommission
		showFinancialProfile
		parentId
		flowedTermValue
		maxReversibleCommission
		maxReversibleCommissionOverride
		isEmployee
		useAgentDiscountProgram
		dropRatio
		darkDisplayLogo: displayLogo(criteria: { defaultAgentLogoType: DARK, defaultCompanyLogoType: NAVBAR }) {
			attachmentId
		}
		parent {
			customerCompany
			supportEmail
			showFinancialProfile
			useAgentDiscountProgram
			dropRatio
		}
		logins {
			isPrimary
			addDate
			emails {
				email
			}
		}
		company {
			isSaas
			isDecoupled
			licensePlans {
				id
			}
			defaultNavbarLogo {
				attachmentId
				attachment {
					attachmentId
					originalName
					path
				}
			}
			defaultFooterLogo {
				attachmentId
				attachment {
					attachmentId
					originalName
					path
				}
			}
		}
	}
}
`;

export const getNavbarCompanyData = gql`
query GetNavbarCompanyData($companyId: String!) {
	company(companyId: $companyId) {
		defaultNavbarLogo {
			attachmentId
			attachment {
				attachmentId
				originalName
				path
			}
		}
		defaultFooterLogo {
			attachmentId
			attachment {
				attachmentId
				originalName
				path
			}
		}
	}
}
`;

export const getTermsOfService = gql`
query GetTermsOfService {
	company(companyId: "297ed5063d9744bf013d9746afe00001") {
		termsOfServiceAttachment {
			attachmentId
			addDate
		}
	}
}
`;

export const getSupportCallFormData = gql`
query GetSupportCallFormData($userId: String!) {
	user(userId: $userId) {
		fname
		lname
		phone
	}
}
`;

export const getGridConfigData = gql`
query GetGridConfigData($gridId: String!, $userId: String!) {
	user(userId: $userId) {
		defaultUserGridConfigId
	}
	customGridConfigs: userGridConfigs(criteria: { isGlobal: false, gridId: $gridId, userId: $userId }) {
		message {
			id
			name
			gridId
			isGlobal
			isDefault
			states
			sortOrder
		}
	}
	globalGridConfigs: userGridConfigs(criteria: { isGlobal: true, gridId: $gridId }) {
		message {
			id
			name
			gridId
			isGlobal
			isDefault
			states
			sortOrder
		}
	}
}
`;

export const getContractTablePageData = gql`
query GetContractTablePageData {
	suppliers(criteria: { isActive: true }, sort: "name") {
		message {
			supplierId
			name
		}
	}
	states(criteria: { isActive: true }, sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
}
`;

export const getLookupContractSearchData = gql`
query GetLookupContractSearchData($criteria: CustomContractInput, $sort: String, $max: Int, $offset: Int) {
	viewContractDashboard(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			contractId
			contractNum
			status
			term
			serviceTypeId
			supplierId
			expirationDate
			confirmationDate
			effectiveDate
			displayStatus
			commodity
			dbaName
			supplierName
			units
			rate
			margin
			commissionSplitBufferMargin
			commodity
			annualUsage
			agentId
			parentId
			brotherId
			agentName
			parentName
			brotherName
			signedAnnualUsage
			payableAnnualUsage
			depositRequiredAmount
			utilityIdList
			isNewBrokerAuction
			agentIsNewBroker
			commissionRate
			commissionRate2
			commissionRate3
			isResidential
			hideContractDetailsFromBrother
		}
		total
		start
		end
	}
}
`;

export const getContractAdvancedSearchData = gql`
query GetContractSearchAdvancedData($search: String!) {
	contractsAdvanced(search: $search, type: "lucene") {
		message {
			status {
				timems
				rid
			}
			contracts {
				agent {
					name
				}
				customer {
					dbaName
				}
				serviceType {
					name
				}
				supplier {
					name
				}
				lastSession {
					endDate2
				}
				contractId
				contractNum
				displayStatus
				status
				agentId
				customerId
				serviceTypeId
				stateId
				locationAddress
				locationUtilityAccountNum
				locationUtilityNameKey
				locationUtilityReferenceNum
				locationUtilityMeterNum
				isNewBrokerAuction
			}
		}
	}
}
`;

export const getSupplierSearchData = gql`
query GetSupplierSearchData($criteria: CustomRfqSessionInput) {
	viewSupplierDashboard(criteria: $criteria) {
		message {
			rfqSessionId
			startDate
			endDate
			supplierId
			note
			contractId
			contractNum
			contractStatus
			annualUsage
			customerId
			dbaName
			utilityAccountNumList
			utilityIdList
			utilityNameList
			serviceTypeId
			serviceTypeName
			sampleBillAttachmentIdList
			loaAttachmentIdList
			hudAttachmentIdList
			loaEsignIdList
			loaHasEsignList
			loeAttachmentIdList
			loeEsignIdList
			loeHasEsignList
			effectiveDate
			uploadDate
			awardDate
			units
			rfqSessionSupplierId
			isOptOut
			stateId
			agentId
			isSaas
		}
	}
}
`;

export const getContractSearchData = gql`
query GetContractSearchData($criteria: ContractInput, $sort: String) {
	contracts(criteria: $criteria, sort: $sort) {
		message {
			contractId
			serviceTypeId
			stateId
			rfqSessionId
			contractNum
			status
			displayStatus
			term
			expirationDate
			confirmationDate
			annualUsage
			awardDate
			rate
			margin
			commissionSplitBufferMargin
			bufferMargin
			matrixAgentBufferMargin
			units
			signedAnnualUsage
			payableAnnualUsage
			isResidential
			isFraudulent
			externalId
			depositRequiredAmount
			isSupplierApiSubmitted
			isNewBrokerAuction
			salesTax
			supplierId
			customer {
				dbaName
			}
			agent {
				name
				isNewBroker
				parent {
					name
					isNewBroker
				}
				brother {
					name
				}
			}
			serviceType {
				name
			}
			supplier {
				name
			}
			rfqSession {
				endDate2
			}
			locations {
				contractLocationId
				utilityAccountNum
				utility {
					name
				}
			}
		}
		total
		start
		end
	}
}
`;

export const getReportMenu = gql`
query GetReportMenu($criteria: ReportInput, $sort: String) {
	reports(criteria: $criteria, sort: $sort) {
		message {
			reportId
			reportTypeId
			name
			description
			permissions
			eventName
		}
	}
}
`;

export const getSubmittedNotConfirmedReportData = gql`
query GetSubmittedNotConfirmedReportData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getSubmittedNotConfirmedReport = gql`
query GetSubmittedNotConfirmedReport($status: Int) {
	contracts(criteria: { hasGUId: true, status: $status }) {
		message {
			contractId
			contractNum
			externalId
			contractDate
			effectiveDate
			locations {
				utilityAccountNum
			}
			customer {
				dbaName
			}
			agent {
				name
			}
			supplier {
				name
			}
		}
	}
}
`;

export const getContractStatusReportData = gql`
query GetContractStatusReportData($reportId: String!, $roleId: String!) {
	report(reportId: $reportId) {
		name
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	suppliers(criteria: { isActive: true }, sort: "name", max: 0) {
		message {
			supplierId
			name
		}
	}
	utilities(sort: "name", max: 0) {
		message {
			utilityId
			name
		}
	}
	users(criteria: { roleId: $roleId, isActive: true }, sort: "lname, fname", max: 0) {
		message {
			userId
			fname
			middle
			lname
		}
	}
	rejectReasonCategories(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getSupplierPaymentAuditReportData = gql`
query GetSupplierPaymentAuditReportData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
	suppliers(criteria: { isActive: true }, sort: "name", max: 0) {
		message {
			supplierId
			name
		}
	}
}
`;

export const exportAgentListReport = gql`
query ExportAgentListReport($criteria: AgentListReportInput, $sort: String) {
	exportAgentListReport(criteria: $criteria, sort: $sort) {
		response
	}
}
`;

export const exportSupplierListReport = gql`
query ExportSupplierListReport($criteria: SupplierListReportInput) {
	exportSupplierListReport(criteria: $criteria) {
		response
	}
}
`;

export const getLowAuctionPerformanceAgents = gql`
query GetLowAuctionPerformanceAgents($sort: String) {
	lowAuctionPerformanceAgents(sort: $sort) {
		message {
			agentId
			name
			parentId
			parentName
			brotherId
			brotherName
			numAuctions
			closingRatio
			termValue
			numClosedContracts
		}
		total
	}
}
`;

export const getAgentListReport = gql`
query GetAgentListReport($criteria: AgentListReportInput, $sort: String, $max: Int, $offset: Int) {
	agentListReportTotals(criteria: $criteria) {
		numAuctions
		numAuctionsElec
		numAuctionsGas
		closingRatio
		termValue
		termValueMatrix
		termValueAuction
		contractsAwardedNotSigned
		contractsDroppedRatio
	}
	agentListReport(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			agentId
			name
			parentId
			parentName
			numAuctions
			numAuctionsElec
			numAuctionsGas
			numAuctionsKwh
			numAuctionsTherms
			closingRatio
			termValue
			termValueMatrix
			termValueAuction
			contractsAwardedNotSigned
			contractsDroppedRatio
			timeSinceAddedYears
			timeSinceAddedMonths
			isActive
			addDate
		}
		total
	}
}
`;

export const getFaqEducationReports = gql`
query GetFaqEducationReport($criteria: FaqEducationReportInput, $sort: String) {
	faqEducationReport(criteria: $criteria, sort: $sort) {
		message {
			criteria
			frequency
			matches
			terms
			matchedArticles
			clickedArticles
			searchers
		}
		total
	}
}
`;

export const getAuctionMonitorEmailReport = gql`
query GetAuctionMonitorEmailReport($criteria: AuctionMonitorEmailReportInput, $sort: String, $max: Int, $offset: Int) {
	auctionMonitorEmailReportTotals(criteria: $criteria) {
		numSentNeedsRefreshedPricing
		needsRefreshedPricingClickedRatio
		numSentNoBids
		noBidsClickedRatio
		numSentNotVisited
		notVisitedClickedRatio
		numSentNotDownloadedRFP
		notDownloadedRFPClickedRatio
		numSentWaitingOnLOEs
		waitingOnLOEsClickedRatio
		numSentWaitingOnLOAs
		waitingOnLOAsClickedRatio
		numSentWaitingOnSampleBills
		waitingOnSampleBillsClickedRatio
		numSentEmails
		interactedWithEmailRatio
		loginButtonClickedRatio
	}
	auctionMonitorEmailReport(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			supplierName
			numClickedNeedsRefreshedPricingSection
			numSentNeedsRefreshedPricing
			needsRefreshedPricingClickedRatio
			numClickedNoBidsSection
			numSentNoBids
			noBidsClickedRatio
			numClickedNotVisitedSection
			numSentNotVisited
			notVisitedClickedRatio
			numClickedNotDownloadedRFPSection
			numSentNotDownloadedRFP
			notDownloadedRFPClickedRatio
			numClickedWaitingOnLOEsSection
			numSentWaitingOnLOEs
			waitingOnLOEsClickedRatio
			numClickedWaitingOnLOAsSection
			numSentWaitingOnLOAs
			waitingOnLOAsClickedRatio
			numClickedWaitingOnSampleBillsSection
			numSentWaitingOnSampleBills
			waitingOnSampleBillsClickedRatio
			numSentEmails
			interactedWithEmailRatio
			numClickedLoginButton
			numSentLoginButton
			loginButtonClickedRatio
		}
		total
	}
}
`;

export const getAgentReportData = gql`
query GetAgentReportData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
}
`;

export const getFaqEducationReportData = gql`
query GetFaqEducationReportData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getAuctionMonitorEmailReportData = gql`
query GetAuctionMonitorEmailReportData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getEmailAnalyticsReportPageData = gql`
query GetEmailAnalyticsReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getEmailAnalyticsReportData = gql`
query GetEmailAnalyticsReportData($criteria: EmailAnalyticsInput) {
	emailAnalytics(criteria: $criteria) {
		topStats {
			openRate
			clickRate
			unsubscribeRate
		}
		masterStats {
			id
			groupName
			recipientType
			numEmailTypes
			openRate
			clickRate
			unsubscribeRate
			signRate
			hasDetail
			hasOpenRate
			hasClickRate
			hasUnsubscribeRate
			hasSignRate
		}
		detailStats {
			id
			groupName
			recipientType
			detailType
			name
			openRate
			clickRate
			unsubscribeRate
			signRate
		}
	}
}
`;

export const getAgentActivityReportPageData = gql`
query GetAgentActivityReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const sendPricingAnalyticsSupplierSummary = gql`
query SendPricingAnalyticsSupplierSummary($supplierId: String!) {
	sendPricingAnalyticsSupplierSummary(supplierId: $supplierId)
}
`;

export const getAgentActivityStatisticsGeneralReportData = gql`
query GetAgentActivityStatisticsGeneralReportData($criteria: AgentActivityReportInput, $sort: String) {
	agentActivityStatisticsReport(criteria: $criteria, sort: $sort) {
		agentId
		agentName
		parentAgentId
		parentAgentName
		contactName
		phone
		phoneExt
		phoneCountryId
		email
		payableTermValue
		confirmedCommercialContractCount
		averageMargin
		retentionRate
		auctionClosingRatio
		matrixClosingRatio
		uniqueCommercialAccountsCount
		dropRatio
		averageTermLength
		joinedDate
		conversionDate
		deactivationDate
		winDate
		dormantDate
	}
}
`;

export const getAgentActivityStatisticsConversionStats = gql`
query GetAgentActivityStatisticsConversionStats($criteria: AgentActivityStatisticsStatsInput) {
	agentActivityStatisticsConversionStats(criteria: $criteria) {
		monthYearDate
		joined
		converted
		conversionRate
	}
}
`;

export const getAgentActivityStatisticsVelocityStats = gql`
query GetAgentActivityStatisticsVelocityStats($criteria: AgentActivityStatisticsStatsInput) {
	agentActivityStatisticsVelocityStats(criteria: $criteria) {
		monthYearDate
		avgDaysDiff
		nonOutlierAvgDaysDiff
	}
}
`;

export const getAgentActivityStatisticsWinbackStats = gql`
query GetAgentActivityStatisticsWinbackStats($criteria: AgentActivityStatisticsStatsInput) {
	agentActivityStatisticsWinbackStats(criteria: $criteria) {
		monthYearDate
		numWinback
	}
}
`;

export const getAgentActivityStatisticsChurnRateStats = gql`
query GetAgentActivityStatisticsChurnRateStats($criteria: AgentActivityStatisticsStatsInput) {
	agentActivityStatisticsChurnRateStats(criteria: $criteria) {
		monthYearDate
		rate
	}
}
`;

export const getAgentActivityRampingReportData = gql`
query GetAgentActivityRampingReportData($criteria: AgentActivityReportInput, $sort: String) {
	agentActivityRampingReport(criteria: $criteria, sort: $sort) {
		companyId
		companyName
		agentId
		agentName
		parentAgentId
		parentAgentName
		contactName
		phone
		phoneCountryId
		phoneExt
		email
		isActive
		bookingBefore
		bookingNow
		bookingChange
		lastConfirmedDeal
		pctRank
	}
}
`;

export const getAgentActivityEmergingReportData = gql`
query GetAgentActivityEmergingReportData($criteria: AgentActivityReportInput, $sort: String) {
	agentActivityEmergingReport(criteria: $criteria, sort: $sort) {
		companyId
		companyName
		agentId
		agentName
		parentAgentId
		parentAgentName
		contactName
		phone
		phoneCountryId
		phoneExt
		email
		isActive
		totalTermValue
		lastContractDate
		pctRank
	}
}
`;

export const getCommissionAuditReportPageData = gql`
query GetCommissionAuditReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	suppliers(criteria: { isActive: true }, sort: "name", max: 0) {
		message {
			supplierId
			name
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getSupplierCompensationReportPageData = gql`
query GetSupplierCompensationReportPageData($reportId: String!, $contractDate: String) {
	report(reportId: $reportId) {
		name
	}
	serviceTypes(criteria: { isActive: true }, sort: "name", max: 0) {
		message {
			serviceTypeId
			name
		}
	}
	commissionPayPlans(criteria: { contractDate: $contractDate }) {
		message {
			id
			name
			upfrontLag
			upfrontPercentage
			maxUpfrontAmount
			upfrontDateType
			monthlyLag
			hasAnniversaryUpfront
			anniversaryUpfrontDateType
			anniversaryLag
			commissionPayPlanTypeId
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
			isSaas
			isDecoupled
		}
	}
}
`;

export const getCommissionPaidReportData = gql`
query GetCommissionPaidReportData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
	suppliers(criteria: { isActive: true }, sort: "name", max: 0) {
		message {
			supplierId
			name
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getCommissionKpiReportData = gql`
query GetCommissionKpiReportData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
	users(criteria: { isActive: true, isCommissionUser: true }, sort: "fname, lname", max: 0) {
		message {
			userId
			fname
			lname
		}
	}
}
`;

export const getDirectSalePaidCommissionData = gql`
query GetDirectSalePaidCommissionData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getCommissionProcessingIssuesReportData = gql`
query GetCommissionProcessingIssuesReportData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getTaskReportData = gql`
query GetTaskReportData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getRenewalReportData = gql`
query GetRenewalReportData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getUsageModalContractLocationInfoData = gql`
query ContractInfoLocationsData($contractId: String!) {
	contract(contractId: $contractId) {
		plc
		networkPlc
		loadFactor
		networkLoadFactor
		customLoadFactor
		locations(sort: "sortOrder") {
			contractId
			contractLocationId
			stateId
			utilityId
			utilityAccountNum
			utilityReferenceNum
			zipCode
			annualUsage
			address1
			address2
			city
			zipCode
			addDate
			rateClass
			sortOrder
			state {
				stateShort
				stateLong
			}
			utility {
				name
			}
		}
	}
}
`;

export const getContractInfoLocationsData = gql`
query ContractInfoLocationsData($contractId: String!) {
	contract(contractId: $contractId) {
		commissionPayablesSchedules {
			agentId
			contractLocationId
			amount
			usage
			date
			cycleStartDate
			cycleEndDate
			rate
			margin
			totalUsage
			totalAmount
			totalPayout
			dateOffset
			purchasingLayerHistoryId
			exchangeRate
		}
		locations(sort: "sortOrder") {
			contractLocationId
			address1
			address2
			addressLine
			city
			stateId
			zipCode
			utilityId
			utilityAccountNum
			utilityAccountNum2
			utilityNameKey
			utilityMeterNum
			utilityReferenceNum
			annualUsage
			zone
			priorSupplier
			rateClass
			addDate
			status
			dropDate
			reinstatementDate
			statusDescription
			statusReasonTypeId
			isActive
			sortOrder
			personalPropertyLiability
			personalLiability
			schedulePersonalProperty
			deductible
			deliveryTypeId
			etfAmount
			skipStatusNotification
			effectiveDate
			disconnectionNoticeSendDate
			rejectionDate
			commissions {
				commissionId
				agentId
				paidDate
				cycleStartDate
				cycleEndDate
				usage
				margin
				rate
				commissionDate
				amount
				reverseCommissionId
				exchangeRate
				agent {
					name
				}
				type {
					name
				}
				pendingCommission {
					id
					attachmentId
					isGuaranteePayment
				}
			}
			pendingCommissions(sort: "reportDate") {
				id
				meterStartDate
				meterEndDate
				usage
				supplierPayment
				margin
				reversePendingCommissionId
				attachmentId
				isArchived
				isGuaranteePayment
				groupId
				reportDate
				exchangeRate
				commissionType {
					name
				}
			}
			commissionEstimates {
				id
			}
			state {
				stateShort
				stateLong
			}
			utility {
				abbreviation
				accountNumLabel
				accountNumFormat
				showNameKey
				nameKeyFormat
				nameKeyLabel
				showReferenceNum
				referenceNumFormat
				referenceNumLabel
				showMeterNum
				meterNumFormat
				meterNumLabel
			}
			deliveryType {
				name
			}
		}
	}
}
`;

export const getContractLocationAccountUsage = gql`
query GetContractLocationAccountUsage($criteria: AccountUsageInput, $contractLocationId: String!) {
	accountUsages(criteria: $criteria, sort: "period desc") {
		message {
			utilityAccountNum
			utilityReferenceNum
			utilityId
			serviceTypeId
			period
			usage
			meterStartDate
			meterEndDate
		}
	}
	usageForecastModelResults(criteria: { contractLocationId: $contractLocationId }, sort: "month desc") {
		message {
			contractLocationId
			month
			ensembleResult
		}
	}
}
`;

export const getMenuTypeListData = gql`
query GetMenuTypeListData($sort: String) {
	menuTypes(sort: $sort){
		message {
			name
			description
			menuTypeId
		}
	}
}
`;

export const getMenuTypeAddEditData = gql`
query GetMenuTypeListData($menuTypeId: String!) {
	menuType(menuTypeId: $menuTypeId){
		name
		description
		menuTypeId
	}
}
`;

export const getLicensePlanListData = gql`
query GetLicensePlanListData($sort: String) {
	licensePlans(sort: $sort){
		message {
			name
			description
			id
			monthlyFee
			upfrontFee
			years
			licensePlanType {
				name
			}
		}
	}
}
`;

export const getLicensePlanAddEditData = gql`
query GetLicensePlanAddEditData($id: Int!) {
	licensePlan(id: $id){
		id
		name
		description
		stripeId
		monthlyFee
		upfrontFee
		years
		noChargeStripeId
		hasChildren
		licensePlanTypeId
    parents {
			name
			description
			id
		}
		coupons {
			id
			name
			stripeId
			percentage
		}
	}
}
`;

export const getLicensePlanAddEditPageData = gql`
query GetLicensePlanAddEditPageData {
	potentialParents: licensePlans(criteria: { hasParent: false }, sort: "id") {
		message {
			id
			name
			description
			licensePlanTypeId
		}
	}
	licensePlanTypes {
		message {
			id
			name
		}
	}
}
`;

export const getPermissionListData = gql`
query GetPermissionListData($sort: String) {
	permissions(sort: $sort){
		message {
			name
			description
			permissionId
		}
	}
}
`;

export const getPermissionAddEditPageData = gql`
query GetPermissionAddEditPageData {
	roles(sort: "level desc") {
		message {
			roleId
			name
		}
	}
}
`;

export const getPermissionAddEditData = gql`
query GetPermissionListData($permissionId: String!) {
	permission(permissionId: $permissionId){
		name
		description
		permissionId
		roles {
			roleId
		}
	}
}
`;

export const getReportTypeListData = gql`
query GetReportTypeListData($sort: String) {
	reportTypes(sort: $sort){
		message {
			name
			description
			reportTypeId
		}
	}
}
`;

export const getReportTypeAddEditData = gql`
query GetReportTypeListData($reportTypeId: String!) {
	reportType(reportTypeId: $reportTypeId){
		name
		description
		reportTypeId
	}
}
`;

export const getSettingListData = gql`
query GetSettingListData($sort: String) {
	settings(sort: $sort){
		message {
			name
			value
			settingId
		}
	}
}
`;

export const getSettingAddEditData = gql`
query GetSettingListData($settingId: String!) {
	setting(settingId: $settingId){
		name
		value
		settingId
	}
}
`;

export const getRfqSessionOptOutReasonTypeAddEditData = gql`
query GetRfqSessionOptOutReasonTypeAddEditData($id: Int!) {
	rfqSessionOptOutReasonType(id: $id){
		id
		name
		addDate
	}
}
`;

export const getPriceElementListData = gql`
query GetPriceElementListData($sort: String) {
	priceElements(sort: $sort){
		message {
			name
			description
			priceElementId
		}
	}
} `;

export const getPriceElementAddEditPageData = gql`
query GetPriceElementAddEditPageData {
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
}
`;

export const getPriceElementAddEditData = gql`
query GetPriceElementAddEditData($priceElementId: String!) {
	priceElement(priceElementId: $priceElementId){
		itemRatio
		name
		description
		priceElementId
		sortOrder
		isActive
		serviceTypeId
		element
	}
}
`;

export const getReportListData = gql`
query GetReportListData($sort: String) {
	reports(sort: $sort){
		message {
			reportId
			reportTypeId
			name
			eventName
			queryString
			description
			permissions
			sortOrder
			isActive
			reportType{
				name
			}
		}
	}
}
`;

export const getReportAddEditPageData = gql`
query GetReportAddEditPageData {
	permissions(sort: "name") {
		message {
			permissionId
			name
		}
	}
	reportTypes(sort: "name") {
		message {
			reportTypeId
			name
		}
	}
}
`;

export const getReportAddEditData = gql`
query GetReportListData($reportId: String!) {
	report(reportId: $reportId){
		reportId
		reportTypeId
		name
		eventName
		queryString
		description
		permissions
		sortOrder
		isActive
		apiEventName
	}
}
`;

export const getFeedbackPageDataSub = gql`
query GetFeedbackPageDataSub($criteria: FeedbackInput, $sort: String, $max: Int, $offset: Int) {
	feedbacks(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			body
			page
			addDate
			canContact
			isReviewed
			url
			ticketId
			user {
				phone
				fullName
				emails {
					email
				}
				contactMethods {
					id
				}
			}
			type {
				name
			}
			ticket {
				id
				subject
				body
				email
				priority
				addDate
				completeDate
				ticketCategoryId
				completeUserId
				assignedToUserId
				userId
				contractId
				ticketStatusId
				category {
					name
				}
				contactMethods {
					name
				}
				user {
					userId
					fname
					lname
					isActive
					isAdmin
					isSaas
					emails {
						email
					}
					role {
						name
					}
				}
				assignedToUser {
					fname
					lname
				}
				completeUser {
					fname
					lname
				}
				attachments {
					attachmentId
					originalName
					size
					name
					attachmentTypeId
					addDate
					user {
						fname
						lname
						emails {
							email
						}
					}
				}
				comments {
					id
					ticketId
					body
					addDate
					modifiedDate
					isInternal
					user {
						fname
						lname
					}
				}
			}
		}
		total
		start
		end
	}
}
`;

export const getFeedbackPageData = gql`
query GetFeedbackPageData {
	feedbackTypes {
		message {
			id
			name
		}
	}
}
`;

export const getRateMatrixLoaderAuditsData = gql`
query GetRateMatrixLoaderAuditsData($criteria: ViewCompanyRateMatrixLoaderAuditInput) {
	viewCompanyRateMatrixLoaderAudits(criteria: $criteria, sort: "assemblyName") {
		message {
			id
			assemblyName
			description
			isActive
			isAPI
			finishedAt
			amount
			errors
			warnings
			isProcessed
		}
	}
}
`;

export const getRateMatrixLoaderAuditsPageData = gql`
query GetRateMatrixLoaderAuditsPageData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getExtendAuctionReasonAddEditData = gql`
query GetExtendAuctionReasonAddEditData($id: Int!) {
	rfqSessionExtendAuctionReasonType(id: $id) {
		id
		name
		addDate
	}
}
`;

export const getExtendAuctionReasonsListData = gql`
query GetExtendAuctionReasonsListData {
	rfqSessionExtendAuctionReasonTypes(sort: "id") {
		message {
			id
			name
			addDate
		}
	}
}
`;

export const getEmailPreferenceAddEditData = gql`
query GetEmailPreferenceAddEditData($id: Int!) {
	emailPreference(id: $id) {
		id
		name
		description
		slug
		sortOrder
		recipientType
		isUSOnly
		emailCategoryId
		agentEmailPreferenceSetting {
			id
			emailPreferenceTypeId
			emailPreferenceSettingTargetDateOptionId
			holidayTypeId
		}
	}
}
`;

export const getEmailPreferencesListData = gql`
query GetEmailPreferencesListData {
	emailPreferences(sort: "id") {
		message {
			id
			name
			slug
			sortOrder
		}
	}
}
`;

export const getLeadLinkSetupData = gql`
query GetLeadLinkSetupData {
	eclAnnualUsageBrackets(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	eclLoadFactorBrackets(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	utilities(criteria: { isActive: true }, sort: "name") {
		message {
			utilityMarketSettings(criteria: { hasEcl: true }) {
				state {
					stateId
					stateLong
				}
			}
		}
	}
}
`;

export const getEclLoadFactorBracketsAddEditData = gql`
query GetEclLoadFactorBracketsAddEditData($id: Int!) {
	eclLoadFactorBracket(id: $id) {
		id
		name
		minLoadFactor
		maxLoadFactor
		sortOrder
	}
}
`;

export const getEclLoadFactorBracketsListData = gql`
query GetEclLoadFactorBracketsListData {
	eclLoadFactorBrackets {
		message {
			id
			name
			minLoadFactor
			maxLoadFactor
			sortOrder
		}
	}
}
`;

export const getEclPackageAddEditPageData = gql`
query GetEclPackageAddEditPageData {
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	utilities(criteria: { isActive: true }, sort: "name") {
		message {
			utilityMarketSettings(criteria: { hasEcl: true }) {
				state {
					stateId
					stateLong
				}
			}
		}
	}
}
`;


export const getEclPackageAddEditData = gql`
query GetEclPackageAddEditData($id: Int!) {
	eclPackage(id: $id) {
		id
		serviceTypeId
		stateId
		name
		numLeads
		minUsage
		maxUsage
		cost
		stripeId
		imgPath
		distributions {
			id
			numLeadsPercentage
			minUsage
			maxUsage
		}
	}
}
`;

export const getEclPackagesListData = gql`
query GetEclPackagesListData {
	eclPackages {
		message {
			id
			name
			numLeads
			minUsage
			maxUsage
			cost
			serviceType {
				name
				units
			}
			state {
				stateLong
			}
		}
	}
}
`;

export const getEclCouponAddEditData = gql`
query GetEclCouponAddEditData($id: Int!) {
	eclCoupon(id: $id) {
		id
		name
		stripeId
		discountFactor
		numPackagesForDiscount
	}
}
`;

export const getEclCouponsListData = gql`
query GetEclCouponsListData {
	eclCoupons {
		message {
			id
			name
			discountFactor
			numPackagesForDiscount
		}
	}
}
`;

export const getEclAnnualUsageBracketsAddEditData = gql`
query GetEclAnnualUsageBracketsAddEditData($id: Int!) {
	eclAnnualUsageBracket(id: $id) {
		id
		name
		minUsage
		maxUsage
		sortOrder
	}
}
`;

export const getEclAnnualUsageBracketsListData = gql`
query GetEclAnnualUsageBracketsListData {
	eclAnnualUsageBrackets {
		message {
			id
			name
			minUsage
			maxUsage
			sortOrder
		}
	}
}
`;

export const getRateMatrixLoadersListData = gql`
query GetRateMatrixLoadersListData {
	rateMatrixLoaders(sort: "assemblyName") {
		message {
			id
			assemblyName
			description
		}
	}
}
`;

export const getRateMatrixLoadersAddEditData = gql`
query GetRateMatrixLoadersAddEditData($id: Int!) {
	rateMatrixLoader(id: $id) {
		id
		assemblyName
		description
	}
}
`;

export const getRateMatrixLoadersEditPageData = gql`
query GetRateMatrixLoadersEditPageData {
	emailCriterias(criteria: { emailCriteriaTypeId: 4 }, sort: "name") {
		message {
			emailCriteriaId
			name
			subject
			emailAccount {
				companyId
			}
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getRateMatrixRemovalPageData = gql`
query GetRateMatrixRemovalPageData {
	suppliers(criteria: { isActive: true }, sort: "name") {
		message {
			supplierId
			name
			serviceTypes {
				serviceType {
					serviceTypeId
					name
				}
			}
		}
	}
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	utilities(criteria: { isActive: true }, sort: "name") {
		message {
			utilityId
			name
			abbreviation
			isoId
			utilityStates {
				stateId
				utilityId
			}
			utilityServiceTypes {
				serviceTypeId
			}
		}
	}
	isos(sort: "name") {
		message {
			id
			name
		}
	}
}
`;

export const getNew1099VendorsReportPageData = gql`
query GetNew1099VendorsReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getNew1099VendorsReportData = gql`
query GetNew1099VendorsReport($reportId: String!, $criteria: New1099VendorsReportInput) {
	new1099VendorsReport(reportId: $reportId, criteria: $criteria) {
		agentId
		parentId
		name
		contactFname
		contactMiddle
		contactLname
		address1
		address2
		city
		stateId
		zipCode
		stateOther
		phone
		phoneExt
		email
		ssn
		legalTaxName
		businessName
		commissionAmountYTD
		businessEntityType {
			name
		}
		businessEntityTypeTaxClassification {
			name
		}
		parent {
			agentId
			name
		}
		state {
			stateId
			stateShort
		}
	}
}
`;

export const sendUrgentCallRequestEmail = gql`
query SendUrgentCallRequestEmail($urgentCallRequest: UrgentCallRequestInput!) {
	sendUrgentCallRequestEmail(urgentCallRequest: $urgentCallRequest) {
		response
	}
}
`;

export const getCommissionPayPlanFormData = gql`
query GetCommissionPayPlanFormData($contractDate: String, $companyId: String!) {
	commissionPayPlans(criteria: { contractDate: $contractDate }) {
		message {
			id
			name
			description
			upfrontLag
			monthlyLag
			upfrontPercentage
			anniversaryUpfrontDateType
			maxAdvanceMonths
			maxUpfrontAmount
			upfrontDateType
			commissionPayPlanTypeId
		}
	}
	company(companyId: $companyId) {
		lendingModuleSetting {
			maxUpfrontPayment
			maxAdvanceMonths
			maxQuickPayUpfrontPayment
		}
	}
}
`;

export const getEmailCriteriaListPageData = gql`
query GetEmailCriteriaListPageData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	emailCriteriaTypes(sort: "sortOrder,name") {
		message {
			id
			description
		}
	}
}
`;

export const getEmailCriteriaListEmailAccountsData = gql`
query getEmailCriteriaListEmailAccountsData($companyId: String) {
	emailAccounts(criteria: { companyId: $companyId }, sort: "username") {
		message {
			emailAccountId
			username
		}
	}
}
`;

export const getContractCommissionPayPlanFormData = gql`
query GetContractCommissionPayPlanFormData($contractId: String!) {
	contractCommissionPayPlanOptions(contractId: $contractId) {
		percentage
		estTotalCommission
		commissionPayPlanTypeId
		isAdminOnly
		commissionPayPlanType {
			description
			header
			details
		}
	}
}
`;

export const getEdiResponsesLatestUsage = gql`
query GetEdiResponsesLatestUsage($contractId: String!) {
	ediResponsesLatestUsage(contractId: $contractId) {
		utilityAccountNum
		body
	}
}
`;

export const getAuditsListData = gql`
query GetAuditsListData {
	audits {
		message {
			id
			slug
			description
			recipient
			schedule
			isActive
			endpoint
			sendToSupplier
			suppliers {
				name
				supplierId
			}
		}
	}
	suppliers(criteria: { isActive: true }, sort: "name") {
		message {
			supplierId
			name
		}
	}
}
`;

export const auditWorkbook = gql`
query AuditWorkbook($id: Int!, $supplierId: String!) {
	auditWorkbook(id: $id, supplierId: $supplierId)
}
`;

export const getFaqCategoryListData = gql`
query GetFaqCategoryListData {
	faqCategories(sort: "sortOrder") {
		message {
			id
			name
			sortOrder
		}
	}
}
`;

export const getEducationCategoryListData = gql`
query GetEducationCategoryListData {
	educationCategories(sort: "sortOrder") {
		message {
			id
			name
			sortOrder
		}
	}
}
`;

export const getEducationTypeListData = gql`
query GetEducationTypeListData {
	educationTypes(sort: "sortOrder") {
		message {
			id
			name
			sortOrder
		}
	}
}
`;

export const getFaqCategoryData = gql`
query GetFaqCategoryData($id: Int!) {
	faqCategory(id: $id) {
		id
		name
		iconClassName
		sortOrder
	}
}
`;

export const getSlideoutSearchSub = gql`
query GetSlideoutSearchSub(
	$siteSectionSearchCriteria: FaqInput,
	$faqSearchCriteria: FaqInput,
	$educationSearchCriteria: EducationInput
) {
	siteSectionSearchResults: faqSearch(criteria: $siteSectionSearchCriteria) {
		message {
			id
			question
			answer
			brokerRoute
			adminRoute
			faqCategoryId
			amt
			terms
			category {
				name
			}
		}
	}
	faqSearchResults: faqSearch(criteria: $faqSearchCriteria) {
		message {
			id
			question
			answer
			brokerRoute
			adminRoute
			faqCategoryId
			amt
			terms
			category {
				name
			}
		}
	}
	educationSearchResults: educationSearch(criteria: $educationSearchCriteria) {
		message {
			id
			educationTypeId
			thumbnailAttachmentId
			subTitle
			path
			pageId
			amt
			terms
		}
	}
}
`;

export const getFaqSearchSub = gql`
query GetFaqSearchSub($faqSearchCriteria: FaqInput) {
	faqSearchResults: faqSearch(criteria: $faqSearchCriteria) {
		message {
			id
			question
			answer
			brokerRoute
			adminRoute
			faqCategoryId
			amt
			terms
			category {
				name
			}
		}
	}
}
`;

export const getFaqsByCategory = gql`
query GetFaqsByCategory($criteria: FaqInput, $max: Int) {
	faqs(criteria: $criteria, max: $max) {
		message {
			id
			question
			answer
			faqCategoryId
		}
	}
}
`;

export const getEducationCategoryData = gql`
query GetEducationCategoryData($id: Int!) {
	educationCategory(id: $id) {
		id
		name
		iconClassName
		sortOrder
	}
}
`;

export const getEducationTypeData = gql`
query GetEducationTypeData($id: Int!) {
	educationType(id: $id) {
		id
		name
		sortOrder
	}
}
`;

export const getEducationSearchSub = gql`
query GetEducationSearchSub($criteria: EducationInput) {
	educationSearchResults: educationSearch(criteria: $criteria, sort: "addDate desc") {
		message {
			id
			educationTypeId
			subTitle
			thumbnailAttachmentId
			agentId
			path
			addDate
			pageId
			amt
			terms
			topics {
				category {
					id
					name
				}
			}
		}
	}
}
`;

export const getEducationsByCategory = gql`
query GetEducationsByCategory($criteria: EducationInput, $max: Int) {
	educations(criteria: $criteria, max: $max, sort: "addDate desc") {
		message {
			id
			educationTypeId
			subTitle
			thumbnailAttachmentId
			agentId
			path
			addDate
			pageId
			topics {
				category {
					id
					name
				}
			}
		}
	}
}
`;

export const getEducationsByAgent = gql`
query getEducationsByAgent($criteria: EducationInput, $max: Int) {
	educations(criteria: $criteria, max: $max, sort: "addDate desc") {
		message {
			id
			educationTypeId
			subTitle
			thumbnailAttachmentId
			agentId
			path
			addDate
			pageId
			topics {
				category {
					id
					name
				}
			}
		}
	}
}
`;

export const getFaqSearchModalData = gql`
query GetFaqSearchModalData {
	faqs(criteria: { isDefault: true }) {
		message {
			id
			question
			answer
			brokerRoute
			adminRoute
			faqCategoryId
			category {
				name
			}
		}
	}
}
`;

export const getFlowChecksListData = gql`
query GetFlowChecksListData {
	flowChecks {
		message {
			id
			description
			recipient
			schedule
			isActive
			isIndividualRecipientsOnly
			suppliers {
				name
				supplierId
			}
		}
	}
	suppliers(criteria: { isActive: true }, sort: "name") {
		message {
			supplierId
			name
		}
	}
}
`;

export const getAdminConfirmationsData = gql`
query getAdminConfirmationsData {
	suppliers(criteria: { hasConfirmationMapping: true }, sort: "name") {
		message {
			supplierId
			name
		}
	}
}
`;

export const getAdminCommissionsData = gql`
query GetAdminCommissionsData {
	suppliersWithCommissionMapping: suppliers(criteria: { hasCommissionMapping: true, }, sort: "name") {
		message {
			supplierId
			name
		}
	}
	expectedSuppliers: suppliers(criteria: { hasExpectedCommissionReportDate: true, isActive: true }, sort: "name") {
		message {
			supplierId
			name
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getAdminCommissionsContractLocationsData = gql`
query GetAdminCommissionsContractLocationsData($contractId: String) {
	contractLocations(criteria: { contractId: $contractId }, sort: "sortOrder") {
		message {
			contractLocationId
			address1
			address2
			city
			zipCode
			utilityAccountNum
			annualUsage
			state {
				stateShort
			}
		}
	}
}
`;

export const getAdminCommissionsInvoicesPageData = gql`
query GetAdminCommissionsInvoicesPageData($invoiceStatusId: IntString) {
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	invoiceStatuses(criteria: { id: $invoiceStatusId }) {
		message {
			id
			name
		}
	}
} `;

export const getAdminCommissionsInvoiceStatsData = gql`
query GetAdminCommissionsInvoiceStatsData($invoiceStatusId: IntString) {
	invoiceStats(invoiceStatusId: $invoiceStatusId) {
		supplierId
		supplierName
		totalAmount
		numInvoices
		latestSentDate
		latestInvoiceId
		latestInvoiceNum
	}
} `;

export const getAdminCommissionsViewInvoicesData = gql`
query getAdminCommissionsViewInvoicesData($criteria: ViewInvoiceInput) {
	viewInvoices(criteria: $criteria) {
		message {
			id
			invoiceNum
			invoiceStatusId
			supplierId
			supplierName
			serviceTypeId
			commodity
			totalAmount
			noteCount
			addDate
			sentDate
			sender
			supplierEmail
			symbol
			rate
			brokerId
			claimReferenceNumber
		}
		total
		start
		end
	}
} `;

export const getAdminCommissionsInvoiceTotalsData = gql`
query GetAdminCommissionsInvoiceTotalsData($startDate: String, $endDate: String) {
	invoiceTotals(startDate: $startDate, endDate: $endDate) {
		totalReceived
		totalInvoiced
	}
} `;

export const getAdminCommissionsEditInvoiceData = gql`
query GetAdminCommissionsEditInvoiceData($invoiceId: IntString, $supplierId: String!) {
	invoiceItems(criteria: { invoiceId: $invoiceId }) {
		message {
			id
			invoiceId
			description1
			description2
			amount
		}
		total
		start
		end
	}
	supplier(supplierId: $supplierId) {
		supplierId
		name
		address1
		address2
		city
		zipCode
		email
		state {
			stateShort
			country {
				name
				abbreviation
			}
		}
	}
	settings {
		message {
			name
			value
			settingId
		}
	}
	attachments(criteria: { invoiceId: $invoiceId }) {
		message {
			attachmentId
			originalName
			invoiceId
		}
	}
} `;

export const getAdminCommissionsSupplierDropdownData = gql`
query GetAdminCommissionsSupplierDropdownData($criteria: CompanySupplierInput) {
	companySuppliers(criteria: $criteria) {
    message {
			id
			brokerId
			supplierId
      supplier {
				name
				supplierId
				state {
					country {
						id
					}
				}
			}
		}
	}
} `;

export const getContractLocationCommissionContractData = gql`
query GetContractLocationCommissionContractData($contractId: String!) {
	contract(contractId: $contractId) {
		contractId
		contractNum
		units
		status
		isNewBrokerAuction
		agent {
			agentId
			name
			isNewBroker
			parent {
				agentId
				name
				isNewBroker
				logins {
					userId
					fname
					middle
					lname
					isPrimary
					addDate
					emails {
						email
					}
				}
			}
			brother {
				agentId
				name
				logins {
					userId
					fname
					middle
					lname
					isPrimary
					addDate
					emails {
						email
					}
				}
			}
			logins {
				userId
				fname
				middle
				lname
				isPrimary
				addDate
				emails {
					email
				}
			}
		}
		locations(sort: "sortOrder") {
			contractLocationId
			address1
			address2
			city
			zipCode
			utilityAccountNum
			annualUsage
			state {
				stateShort
			}
		}
	}
}
`;

export const getContractLocationPendingCommissionContractData = gql`
query GetContractLocationPendingCommissionContractData($contractId: String!) {
	contract(contractId: $contractId) {
		contractId
		contractNum
		units
		status
		isNewBrokerAuction
		locations(sort: "sortOrder") {
			contractLocationId
			address1
			address2
			city
			zipCode
			utilityAccountNum
			annualUsage
			state {
				stateShort
			}
		}
		state {
			country {
				isoCode
			}
		}
	}
}
`;

export const getCommissionPreviewReportPageData = gql`
query GetCommissionPreviewReportPageData {
	commissionTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	supplierCommissionMaps {
		message {
			id
			sheetName
			headerRow
		}
	}
}
`;

export const getSupplierCommissionAttachmentPreview = gql`
query GetSupplierCommissionAttachmentPreview($id: Int!) {
	supplierCommissionAttachmentPreview(id: $id) {
		customerName
		accountNumber
		meterStartDate
		meterEndDate
		usage
		margin
		supplierPayment
		supplierName
		commodity
		description
		supplierCommissionMapId
		commissionTypeId
		externalId
		externalCommissionId
	}
}
`;

export const getCommissionMappingPageData = gql`
query GetCommissionMappingPageData {
	suppliers(sort: "name") {
		message {
			supplierId
			name
			parentId
		}
	}
	suppliersWithMapping: suppliers(criteria: { hasCommissionMapping: true }, sort: "name") {
		message {
			supplierId
			name
			parentId
		}
	}
	states {
		message {
			stateId
			stateLong
			country {
				abbreviation
			}
		}
	}
	deliveryTypes(sort: "name") {
		message {
			id
			name
		}
	}
	supplierCommissionMaps {
		message {
			id
			supplierId
			brandSupplierId
			description
			sheetName
			headerType
			headerRow
			brandName
			brandNameCol
			customerFNameCol
			customerLNameCol
			accountNumberCol
			meterStartDateCol
			meterEndDateCol
			usageCol
			marginCol
			supplierPaymentCol
			commodityCol
			descriptionCol
			commissionTypeCol
			commissionTypeCol2
			commissionTypeCol3
			defaultCommodity
			defaultDescription
			defaultCommissionTypeName
			usageFactor
			marginFactor
			validationCol
			hasCommercialCommission
			hasResidentialCommission
			externalIdCol
			externalCommissionIdCol
			deliveryTypeId
			supplierCommissionMapStates {
				stateId
			}
			supplierCommissionMapCommissionTypes {
				commissionTypeId
			}
		}
	}
	commissionTypes {
		message {
			id
			name
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
}
`;

export const getSupplierCommissionTypeMapData = gql`
query GetSupplierCommissionTypeMapData {
	suppliersWithMapping: suppliers(criteria: { hasCommissionMapping: true }, sort: "name") {
		message {
			supplierId
			name
			parentId
		}
	}
	commissionTypes {
		message {
			id
			name
		}
	}
	supplierCommissionTypeMaps {
		message {
			id
			supplierCommissionMapId
			commissionTypeId
			commissionTypeColumn
			commissionTypeColumn2
			commissionTypeColumn3
			accountStatus
			supplierCommissionMap {
				id
				supplierId
				description
				commissionTypeCol
				commissionTypeCol2
				commissionTypeCol3
			}
		}
	}
}
`;

export const getContractLocationCommissionModalData = gql`
query GetContractLocationCommissionModalData($commissionId: String!) {
	commission(commissionId: $commissionId) {
		amount
		usage
		margin
		rate
		cycleStartDate
		cycleEndDate
		description
		commissionTypeId
		contractId
		contractLocationId
		agentId
		agent {
			agentId
			name
			logins {
				userId
				fname
				middle
				lname
				isPrimary
				addDate
				emails {
					email
				}
			}
		}
		contract {
			units
			contractNum
			status
			isNewBrokerAuction
			locations {
				contractLocationId
				address1
				address2
				city
				zipCode
				annualUsage
				utilityAccountNum
				state {
					stateShort
				}
			}
			agent {
				agentId
				name
				isNewBroker
				parent {
					agentId
					name
					isNewBroker
					logins {
						userId
						fname
						middle
						lname
						isPrimary
						addDate
						emails {
							email
						}
					}
				}
				brother {
					agentId
					name
					logins {
						userId
						fname
						middle
						lname
						isPrimary
						addDate
						emails {
							email
						}
					}
				}
				logins {
					userId
					fname
					middle
					lname
					isPrimary
					addDate
					emails {
						email
					}
				}
			}
		}
	}
	commissionTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
}
`;

export const getCommissionEarlyPaymentPageData = gql`
query GetCommissionEarlyPaymentPageData {
	commissionTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
}
`;

export const getContractLocationPendingCommissionModalData = gql`
query GetContractLocationPendingCommissionModalData($id: Int!) {
	pendingCommission(id: $id) {
		id
		modifiedDate
		contractId
		contractLocationId
		supplierId
		serviceTypeId
		customerName
		accountNumber
		meterStartDate
		meterEndDate
		usage
		margin
		supplierPayment
		supplierName
		commodity
		description
		isDirectSale
		isProcessed
		isArchived
		warnings
		errors
		addDate
		status
		commissionTypeId
		processTimeType
		contract {
			units
			contractNum
			status
			isNewBrokerAuction
			locations {
				contractLocationId
				address1
				address2
				city
				zipCode
				annualUsage
				utilityAccountNum
				state {
					stateShort
				}
			}
		}
	}
	commissionTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
}
`;

export const getContractCommissionEstimates = gql`
query GetContractCommissionEstimates($contractId: String!) {
	contract(contractId: $contractId) {
		contractNum
		units
		status
		isNewBrokerAuction
		locations(sort: "sortOrder") {
			contractLocationId
			annualUsage
			utilityAccountNum
			address1
			address2
			city
			zipCode
			state {
				stateShort
			}
			commissionEstimates(sort: "date") {
				id
				date
				amount
				commissionTypeId
				meterStartDate
				meterEndDate
				commissionType {
					name
				}
			}
		}
	}
}
`;

export const getContractLocationCommissionEstimates = gql`
query GetContractLocationCommissionEstimates($contractLocationId: String) {
	commissionEstimates(criteria: { contractLocationId: $contractLocationId }, sort: "date") {
		message {
			id
			date
			amount
			contractLocationId
			commissionTypeId
			meterStartDate
			meterEndDate
		}
	}
}
`;

export const getUserAuditTypesListData = gql`
query GetUserAuditTypesListData {
	userAuditTypes {
		message {
			id
			name
		}
	}
}
`;

export const getUserAuditTypesAddEditData = gql`
query GetUserAuditTypesAddEditData($id: Int!) {
	userAuditType(id: $id) {
		id
		name
	}
}
`;

export const getMarginChangeReasonTypesListData = gql`
query GetMarginChangeReasonTypesListData {
	marginChangeReasonTypes(sort: "id") {
		message {
			id
			name
			sortOrder
		}
	}
}
`;

export const getMarginChangeReasonTypesAddEditData = gql`
query GetMarginChangeReasonTypesAddEditData($id: Int!) {
	marginChangeReasonType(id: $id) {
		id
		name
		sortOrder
	}
}
`;

export const getTicketComments = gql`
query GetTicketComments($ticketId: Int) {
	ticketComments(criteria: { ticketId: $ticketId }, sort: "addDate") {
		message {
			id
			ticketId
			body
			addDate
			modifiedDate
			isInternal
			user {
				userId
				fname
				lname
			}
		}
	}
}
`;

export const getCustomerUnsubscribePageData = gql`
query GetCustomerUnsubscribePageData($customerId: String!) {
	customer(customerId: $customerId) {
		contracts(sort: "addDate desc") {
			agentId
		}
	}
}
`;

export const getLeadUnsubscribePageData = gql`
query GetLeadUnsubscribePageData($id: Int!) {
	lead(id: $id) {
		assignedToAgentId
	}
}
`;

export const getMonthlyCommission = gql`
query GetMonthlyCommission($companyId: String!) {
	monthlyCommission(companyId: $companyId) {
		receivableForecastCommission {
			month
			value
		}
		receivableActualCommission {
			month
			value
		}
		payableForecastCommission {
			month
			value
		}
		payableActualCommission {
			month
			value
		}
	}
}
`;

export const getContractBanners = gql`
query GetContractBanners {
	contractBanners {
		taskId
		title
		body
		dueDate
		completeDate
		addDate
		taskTypeId
		contractId
		contract {
			contractNum
			serviceTypeId
			status
			isNewBrokerAuction
			renewalContract {
				contractId
			}
			customer {
				dbaName
			}
		}
		taskType {
			ribbonClass
			ribbonText
			buttonText
			routeType
		}
	}
}
`;

export const getTermValueBookedGraph = gql`
query GetTermValueBookedGraph($companyId: String!) {
	termValueBookedGraph(companyId: $companyId) {
		electricityGraph {
			month
			termValue
		}
		naturalGasGraph {
			month
			termValue
		}
	}
}
`;

export const getOwnerCompanySnapshot = gql`
query GetOwnerCompanySnapshot($companyId: String!) {
	ownerCompanySnapshot(companyId: $companyId) {
		collectionsYTD
		currMonthEstimatedCommission
		lastMonthPaidCommission
		netRevenue
		lifetimeValue
	}
}
`;

export const getOwnerTopAgents = gql`
query GetOwnerTopAgents($companyId: String!) {
	ownerTopAgents(companyId: $companyId) {
		agentName
		month1TotalTermValue
		month1ContractCount
		month2TotalTermValue
		month2ContractCount
		month3TotalTermValue
		month3ContractCount
		totalTotalTermValue
		totalContractCount
	}
}
`;

export const getOwnerTermValueBookedSummary = gql`
query GetOwnerTermValueBookedSummary($companyId: String!) {
	ownerTermValueBookedSummary(companyId: $companyId) {
		totalTermValue
		termValueBookedYTD
		termValueBookedLastMonth
	}
}
`;

export const getOwnerTopSuppliers = gql`
query GetOwnerTopSuppliers($companyId: String!) {
	ownerTopSuppliers(companyId: $companyId) {
		supplierName
		month1TotalTermValue
		month1ContractCount
		month2TotalTermValue
		month2ContractCount
		month3TotalTermValue
		month3ContractCount
		totalTotalTermValue
		totalContractCount
	}
}
`;

export const getOwnerMonthlyRevenue = gql`
query GetOwnerMonthlyRevenue($companyId: String!) {
	ownerMonthlyRevenue(companyId: $companyId) {
		revenueForecastCommission {
			month
			value
		}
		revenueActualCommission {
			month
			value
		}
	}
}
`;

export const getFinanceCompanySnapshot = gql`
query GetFinanceCompanySnapshot($companyId: String!) {
	financeCompanySnapshot(companyId: $companyId) {
		collectionsYTD
		totalTermValueDropped
		netRevenue
	}
}
`;

export const getFinanceCommissionsSummary = gql`
query GetFinanceCommissionsSummary($companyId: String!) {
	financeCommissionsSummary(companyId: $companyId) {
		totalEstimatedCommission
		lastMonthGrossCommission
		currentCommissionBalance
		lastMonthFinalPayment
	}
}
`;

export const getFinanceCommissionsGraph = gql`
query GetFinanceCommissionsGraph($companyId: String!) {
	financeCommissionsGraph(companyId: $companyId) {
		payableForecastCommission {
			month
			value
		}
		payableActualCommission {
			month
			value
		}
	}
}
`;

export const getFinanceTotalValueDroppedReinstated = gql`
query GetFinanceTotalValueDroppedReinstated($companyId: String!) {
	financeTotalValueDroppedReinstated(companyId: $companyId) {
		totalTermValueDropped
		thisMonthTermValueDropped
		accountsDropped
		reinstatedTermValue
	}
}
`;

export const getSalesTeamPerformance = gql`
query GetSalesTeamPerformance($agentId: String) {
	salesTeamPerformance(agentId: $agentId) {
		termValueBookedYTD
		thisMonthTermValueBooked
		nextMonthContractsExpiring
	}
}
`;

export const getSalesYourSnapshot = gql`
query GetSalesYourSnapshot($agentId: String) {
	salesYourSnapshot(agentId: $agentId) {
		termValueBookedYTD
		thisMonthTermValueBooked
		nextMonthContractsExpiring
	}
}
`;

export const getOperationsCompanySnapshot = gql`
query GetOperationsCompanySnapshot($companyId: String!) {
	operationsCompanySnapshot(companyId: $companyId) {
		auctionsEndingToday
		awardedNotSigned
		contractsDropped
		supplierParticipationRatio
		brokerClosingRatio
	}
}
`;

export const getOperationsTermValueBookedSummary = gql`
query GetOperationsTermValueBookedSummary($companyId: String!) {
	operationsTermValueBookedSummary(companyId: $companyId) {
		termValueBookedYTD
		customPricingYTD
		matrixPricingYTD
		totalTermValueDroppedContractsYTD
	}
}
`;

export const getOperationsAuctionsEndingToday = gql`
query GetOperationsAuctionsEndingToday($companyId: String!) {
	operationsAuctionsEndingToday(companyId: $companyId) {
		rfqSessionID
		contractID
		contractNum
		dbaName
		serviceTypeID
		serviceTypeName
		serviceTypeUnits
		stateID
		annualUsage
		suppliersThatHaveBid
		suppliersInvited
	}
}
`;

export const getEclUploadData = gql`
query GetEclUploadData {
	utilities(criteria: { isActive: true }, sort: "name") {
		message {
			utilityId
			name
			abbreviation
			utilityMarketSettings(criteria: { hasEcl: true }) {
				state {
					stateId
					stateShort
					stateLong
				}
			}
			utilityServiceTypes {
				serviceTypeId
				serviceType {
					serviceTypeId
					name
					isActive
				}
			}
		}
	}
}
`;

export const getCommercialEcls = gql`
query GetCommercialEcls($criteria: CommercialEclInput, $sort: String, $max: Int, $offset: Int) {
	eclsCommercial(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			accountUuid
			utilityId
			serviceTypeId
			isReserved
			dbaName1
			dbaName2
			address1
			address2
			city
			stateId
			stateShort
			zipCode
			serviceAddress
			billingAddress1
			billingAddress2
			billingCity
			billingStateId
			billingStateShort
			billingZipCode
			billingAddress
			isUsingDefaultService
			utilityAccountNum
			agentId
			note
			hasAssociatedContract
			annualUsage
			plc
			networkPlc
			loadFactor
			totalAccounts
		}
		total
	}
}
`;

export const getEclHomePageData = gql`
query GetEclHomePageData {
	utilities(criteria: { isActive: true }) {
		message {
			utilityId
			name
			abbreviation
			utilityMarketSettings(criteria: { hasEcl: true }) {
				state {
					stateId
					stateShort
					stateLong
				}
			}
			utilityServiceTypes {
				serviceTypeId
			}
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			name
			serviceTypeId
			units
		}
	}
	eclAnnualUsageBrackets {
		message {
			id
			name
			minUsage
			maxUsage
			sortOrder
		}
	}
	eclLoadFactorBrackets {
		message {
			id
			name
			minLoadFactor
			maxLoadFactor
			sortOrder
		}
	}
	eclCoupons(sort: "numPackagesForDiscount") {
		message {
			id
			name
			discountFactor
			numPackagesForDiscount
		}
	}
}
`;

export const getAvailableEclPackagesData = gql`
query GetAvailableEclPackagesData($criteria: AvailableEclPackageInput!) {
	availableEclPackages(criteria: $criteria) {
		message {
			id
			name
			commodity
			units
			stateLong
			numLeads
			minUsage
			maxUsage
			originalCost
			actualCost
			imgPath
			numPackages
			discountFactor
			eclCouponId
			eclCount
		}
	}
}
`;

export const getRejectReasonCategoryAddEditData = gql`
query GetRejectReasonCategoryAddEditData($id: Int!) {
	rejectReasonCategory(id: $id) {
		id
		name
		sortOrder
		subCategories {
			id
			name
			supplierId
			rejectReasonAutoFill
		}
	}
}
`;

export const getRejectReasonCategoryAddEditPageData = gql`
query GetRejectReasonCategoryAddEditPageData {
	suppliers {
		message {
			supplierId
			name
		}
	}
}
`;

export const getRejectReasonCategoriesListData = gql`
query GetRejectReasonCategoriesListData {
	rejectReasonCategories {
		message {
			id
			name
			sortOrder
		}
	}
}
`;

export const getBusinessPipelineReportPageData = gql`
query GetBusinessPipelineReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getBusinessPipelineReportData = gql`
query GetBusinessPipelineReportData($reportId: String!, $criteria: BusinessPipelineReportInput!, $sort: String, $max: Int, $offset: Int) {
	businessPipelineReport(reportId: $reportId, criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			date
			awarded
			confirmed
			ended
			extended1
			extended2
			extended3
			initiated
			rejected
			released
			signed
			quote
			pending
			fraud
			dropped
			pendingdeposit
		}
		total
	}
}
`;

export const getBusinessPipelineReportDetailData = gql`
query GetBusinessPipelineReportData($reportId: String!, $criteria: BusinessPipelineReportInput!, $sort: String) {
	businessPipelineReport(reportId: $reportId, criteria: $criteria, sort: $sort) {
		message {
			date
			commodity
			isRenewal
			agentName
			awarded
			confirmed
			ended
			extended1
			extended2
			extended3
			initiated
			rejected
			released
			signed
			quote
			pending
			fraud
			dropped
			pendingdeposit
		}
		total
	}
}
`;

export const getBusinessPipelineFutureProjectionReportData = gql`
query GetBusinessPipelineReportData($criteria: BusinessPipelineFutureProjectionInput) {
	actualFutureRenewalTermValue(criteria: $criteria) {
		termValue {
			month
			termValue
		}
	}
	estimatedFutureRenewalTermValue(criteria: $criteria) {
		directSaleTermValue {
			month
			termValue
		}
		legacyBrokerTermValue {
			month
			termValue
		}
		newBrokerTermValue {
			month
			termValue
		}
		totalTermValue {
			month
			termValue
		}
	}
	expectedProcurementBasedOnNewBusiness(criteria: $criteria) {
		directSaleTermValue {
			month
			contractCount
			termValue
		}
		legacyBrokerTermValue {
			month
			contractCount
			termValue
		}
		newBrokerTermValue {
			month
			contractCount
			termValue
		}
		totalTermValue {
			month
			contractCount
			termValue
		}
	}
	renewalRatio(criteria: $criteria) {
		yearlyTermValueRatio
		rolling13MonthContractsRatio
		rolling13MonthTermValueRatio
		contractsRatio {
			month
			percentage
		}
		termValueRatio {
			month
			percentage
		}
	}
}
`;

export const getRebuildEsignDocumentModalData = gql`
query GetRebuildEsignDocumentModalData($criteria: NoteTypeInput) {
	noteTypes(criteria: $criteria) {
		message {
			noteTypeId
			name
		}
	}
}
`;

export const getFraudulentReasonModalData = gql`
query GetFraudulentReasonModalData($criteria: NoteTypeInput) {
	noteTypes(criteria: $criteria) {
		message {
			noteTypeId
			name
		}
	}
}
`;

export const getSupplierResidentialConfirmationsData = gql`
query GetSupplierResidentialConfirmationsData($criteria: CustomContractInput, $sort: String, $max: Int, $offset: Int) {
	viewContractDashboard(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			contractId
			contractNum
			term
			effectiveDate
			contractDate
			dbaName
			externalId
			status
			confirmationDate
			utilityAccountNumList
			depositRequiredAmount
			isNewBrokerAuction
			agentIsNewBroker
		}
		total
		start
		end
	}
} `;

/* eslint-disable max-len */
export const getPurchasingLayerIndicativeHedgedPrices = gql`
query GetPurchasingLayerIndicativeHedgedPrices($criteria: PurchasingLayerIndicativeHedgedPriceInput, $sort: String, $max: Int, $offset: Int) {
	purchasingLayerIndicativeHedgedPrices(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			emailId
			contractId
			effectiveDate
			term
			price
			quantity
			addDate
		}
		total
		start
		end
	}
}
`;
/* eslint-enable max-len */

export const getContractPurchasingLayer = gql`
query GetContractPurchasingLayer($contractId: String!, $today: String) {
	contract(contractId: $contractId) {
		contractId
		contractNum
		displayStatus
		units
		rate
		annualUsage
		term
		agentId
		customerId
		effectiveDate
		expirationDate
		serviceTypeId
		productId
		stateId
		status
		awardDate
		confirmationDate
		salesTax
		supplierId
		bufferMargin
		margin
		commissionSplitBufferMargin
		matrixAgentBufferMargin
		suggestedSensitivity
		state {
			country {
				symbol
			}
		}
		customer {
			dbaName
			email
			phone
			phoneExt
			phoneCountryId
			mobile
			mobileCountryId
			address1
			address2
			city
			state {
				stateShort
			}
			zipCode
		}
		supplier {
			name
			showIndicativeHedgedPrices
		}
		serviceType {
			name
		}
		locations {
			stateId
			utilityId
			rateClass
			zone
			utility {
				abbreviation
			}
			state {
				stateShort
			}
		}
		usePurchasingLayerStartDate
		purchasingLayerMarket {
			date
			ancillary
			capacity
			energy
			congestion
			serviceFee
			nymex
			basis
			total
		}
		monthlyContractPrice {
			date
			price
			componentPrice
			hedgedPrice
			componentHedgedPrice
		}
		agent {
			name
			isNewBroker
			phone
			phoneExt
			phoneCountryId
			agentDomainAlias
			logins {
				userId
				isActive
				fname
				middle
				lname
				isPrimary
				phone
				phoneCountryId
				mobile
				mobileCountryId
				addDate
				emails {
					email
				}
			}
			accountManager {
				fname
				lname
			}
			parent {
				agentDomainAlias
			}
		}
		company {
			domainAlias
		}
		purchasingLayerPurchaseHistories {
			id
			date
			effectiveDate
			price
			margin
			bufferMargin
			quantity
			term
		}
		purchasingLayer {
			id
			defensiveSensitivityPercentage
			opportunistSensitivityPercentage
			defensiveQuantityPercentage
			opportunistQuantityPercentage
			targetPrice
			strips {
				meanPrice
			}
		}
		purchasingLayerAudits(criteria: { addDate: $today }) {
			wholesaleRate
		}
	}
	viewPurchasingLayerAudits(criteria: { contractId: $contractId, showlayeredPurchasingAlerts: true }) {
		message {
			contractId
			date
			startDate
			endDate
			rate
			defensiveTriggerCount
			opportunistTriggerCount
		}
	}
}
`;

export const getLayeredPurchasingActionsPageData = gql`
query GetLayeredPurchasingActionsPageData($noteTypeCategoryId: Int) {
	noteTypes(criteria: { noteTypeCategoryId: $noteTypeCategoryId }) {
		message {
			noteTypeId
			name
			description
			noteTypeCategoryId
		}
	}
}
`;

export const getLayeredPurchasingActionsDataSub = gql`
query GetLayeredPurchasingActionsDataSub($criteria: ViewPurchasingLayerActionInput, $sort: String, $max: Int, $offset: Int) {
	viewPurchasingLayerActions(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			message
			details
			noteTypeId
			noteTypeName
			noteTypeDescription
			noteTypeImageIconPrefix
			noteTypeImageIcon
			addDate
		}
	}
}
`;

export const getLayeredPurchasingHedgePriceRequestPreviewModalData = gql`
query GetLayeredPurchasingHedgePriceRequestPreviewModalData($id: Int!) {
	purchasingLayerHedgePriceRequest(id: $id) {
		details
	}
}
`;

export const getContractPerformance = gql`
query GetContractPerformance($contractId: String!) {
	contract(contractId: $contractId) {
		contractId
		contractNum
		displayStatus
		units
		annualUsage
		term
		agentId
		customerId
		expirationDate
		serviceTypeId
		isSaas
		productId
		stateId
		customer {
			dbaName
		}
		supplier {
			name
		}
		serviceType {
			name
		}
		agent {
			agentDomainAlias
			accountManager {
				fname
				lname
			}
			parent {
				agentDomainAlias
			}
		}
		company {
			domainAlias
		}
		contractPerformance {
			contractRate
			utilityRates {
				rate
				date
			}
			marketPerformance{
				date
				ancillary
				capacity
				energy
				congestion
				electricityBypass
				serviceFee
				nymex
				basis
				total
			}
			utilitySavings
			marketSavings
			previousContractSavings
			contractPrices {
				date
				price
				hedgedPrice
			}
		}
	}
}
`;

export const getRfqSessionSupplierDashboardData = gql`
query GetRfqSessionSupplierDashboardData($rfqSessionSupplierId: String!) {
	rfqSessionSupplier(rfqSessionSupplierId: $rfqSessionSupplierId) {
		rfqSession {
			contract {
				companyId
			}
		}
	}
}
`;

export const getCommissionProcessPageData = gql`
query GetCommissionProcessPageData {
	pendingCommissionIssueTypes {
		message {
			id
			name
			allowOverride
		}
	}
	pendingCommissionIssueSubjectTypes(criteria: { isActive: true }, sort: "name") {
		message {
			id
			name
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	suppliers(criteria: { isActive: true }, sort: "name", max: 0) {
		message {
			supplierId
			name
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	commissionPayPlans(criteria: { isActive: true }, sort: "name") {
		message {
			id
			name
		}
	}
	commissionTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
}
`;

export const getPostProcessingPageData = gql`
query GetPostProcessingPageData {
	suppliers(criteria: { isActive: true }, sort: "name", max: 0) {
		message {
			supplierId
			name
		}
	}
	receivableAuditIssueTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	receivableAuditIssueOperationStatusTypes {
		message {
			id
			name
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getJobsListData = gql`
query GetJobsListData($sort: String) {
	jobs(sort: $sort) {
		message {
			id
			name
			description
			url
			schedule
			isActive
			addDate
		}
	}
}
`;

export const getJobEditData = gql`
query GetJobEditData($id: Int!) {
	job(id: $id) {
		id
		name
		description
		url
		schedule
		isActive
	}
}
`;

export const getPendingCommissionTimeLogsListData = gql`
query GetPendingCommissionTimeLogsListData($criteria: PendingCommissionTimeLogInput!, $sort: String) {
	pendingCommissionTimeLogs(criteria: $criteria, sort: $sort) {
		message {
			id
			startDate
			endDate
			addDate
			userId
		}
	}
}
`;

export const getDeliveryTypesListData = gql`
query GetDeliveryTypesListData($sort: String) {
	deliveryTypes(sort: $sort) {
		message {
			id
			name
			sortOrder
		}
	}
}
`;

export const getDeliveryTypeEditData = gql`
query GetDeliveryTypeEditData($id: Int!) {
	deliveryType(id: $id) {
		id
		name
		sortOrder
	}
}
`;

export const getNightlyProcessLogReportData = gql`
query GetNightlyProcessLogReportData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getNightlyProcessLogReport = gql`
query GetNightlyProcessLogReport($reportId: String!, $criteria: NightlyProcessLogReportInput!, $sort: String, $max: Int, $offset: Int) {
	nightlyProcessLogReport(reportId: $reportId, criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			stepName
			startDate
			endDate
			duration
			addDate
		}
		total
		start
		end
	}
}
`;

export const getAgentMarketOpportunities = gql`
query GetAgentMarketOpportunities($agentId: String!) {
	agent(agentId: $agentId) {
		marketOpportunities {
			utilityName
			stateShort
		}
	}
}
`;

export const getAgentCommissionPayPlanOptionsListPageData = gql`
query GetAgentCommissionPayPlanOptionsListPageData {
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	suppliers(sort: "name") {
		message {
			supplierId
			name
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const viewAgentCommissionPayPlanOptions = gql`
query ViewAgentCommissionPayPlanOptions($agentId: String!) {
	agent(agentId: $agentId) {
		agentId
		name
		commissionRate
		commissionRate2
		commissionRate3
		discountFactor
		commissionSplitElectricityBufferMargin
		commissionSplitGasBufferMargin
		residentialCommissionRate
		residentialCommissionRate2
		residentialCommissionRate3
		commissionSplitBufferMarginCommissionRate
		commissionSplitBufferMarginCommissionRate2
		isNewBroker
		maxReversibleCommission
		maxReversibleCommissionOverride
		canDoQuickPay
		quickPayRate
		useResidualCommissionRate
		residualCommissionRate
		overrideCommissionPayPlanTypeId
		isSaas
		commissionSplitTypeId
		subAgents {
			maxReversibleCommission
			maxReversibleCommissionOverride
		}
		parent {
			commissionSplitTypeId
			maxReversibleCommission
			maxReversibleCommissionOverride
		}
		commissionPayPlanTypes {
			id
			description
		}
		agentAllowedCommissionPayPlanTypes {
			commissionPayPlanTypeId
		}
		viewAgentCommissionPayPlanOptions {
			id
			commissionPayPlanTypeId
			lag
			isOverride
			rate
		}
		logins {
			isPrimary
			addDate
			emails {
				email
			}
		}
	}
}
`;

export const getSupplierAllowedCommissionPayPlansListPageData = gql`
query GetSupplierAllowedCommissionPayPlansListPageData {
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	suppliers(sort: "name") {
		message {
			supplierId
			name
		}
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	commissionPayPlanTypes(sort: "id") {
		message {
			id
			description
		}
	}
}
`;

export const viewSupplierAllowedCommissionPayPlanTypes = gql`
query ViewSupplierAllowedCommissionPayPlanTypes($criteria: ViewSupplierAllowedCommissionPayPlanTypeInput, $sort: String) {
	viewSupplierAllowedCommissionPayPlanTypes(criteria: $criteria, sort: $sort) {
		message {
			id
			companyId
			supplierName
			supplierId
			commodity
			serviceTypeId
			supplierUpfrontPercentage
			receivablePayPlanName
			commissionPayPlanTypeName
			commissionPayPlanTypeId
		}
	}
}
`;

export const getCommissionPayablesEditModalData = gql`
query GetCommissionPayablesEditModalData($contractId: String!) {
	commissionPayables(criteria: { contractId: $contractId, isProcessed: false }) {
		message {
			date
			releaseDate
			amount
			usage
			cycleStartDate
			cycleEndDate
		}
	}
}
`;

export const getCommissionEditModalData = gql`
query GetCommissionEditModalData($contractId: String!) {
	commissions(criteria: { contractId: $contractId, isPaid: true }) {
		message {
			commissionId
			amount
			usage
			paidDate
		}
	}
}
`;

export const getCommissionData = gql`
query GetCommissionData {
	commissionTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	commissionPayableIssueTypes(sort: "sortOrder") {
		message {
			id
			name
			sortOrder
		}
	}
	payableIssueTypes(sort: "sortOrder") {
		message {
			id
			name
			sortOrder
		}
	}
}
`;

export const getCommissionPayableIssueTypes = gql`
query GetCommissionPayableIssueTypes {
	commissionPayableIssueTypes(sort: "sortOrder") {
		message {
			id
			name
			sortOrder
		}
	}
}
`;

export const getSupplierAllowedCommissionPayPlansRules = gql`
query GetSupplierAllowedCommissionPayPlansRules($criteria: SupplierCommissionRuleInput) {
	supplierCommissionRules(criteria: $criteria) {
		message {
			commissionCutoff
			upfrontPercentage
			upfrontLag
			monthlyLag
			upfrontDateType
			showUpfrontRequest
			hasAnniversaryUpfront
			anniversaryUpfrontDateType
			minUpfrontTermFactor
			maxUpfrontTermFactor
			discountFactor
			hasResidentialUpfront
			residentialPayLag
			residentialPayAmount
			residentialResidualAmount
			residentialMargin
			marginCap
			marginSplit
			hasCommercial
			hasResidential
			residentialDescription
			commercialDescription
			contractStartDate
			contractEndDate
			maxAdvanceMonths
			maxUpfrontAmount
			maxUpfrontAnnualUsage
			lagCutOffDay
			states {
				stateShort
			}
			serviceType {
				name
			}
			supplier {
				name
			}
		}
	}
}
`;

export const getAgentUpcomingRenewalsPageData = gql`
query GetAgentUpcomingRenewalsPageData($agentId: String!) {
	agent(agentId: $agentId) {
		agentId
		isInHouseIndirectSale
		subAgents {
			agentId
			name
			showLeadLink
			isNewBroker
		}
		brothers {
			agentId
			isActive
		}
	}
}
`;

export const getAgentUpcomingRenewals = gql`
query GetAgentUpcomingRenewals($agentId: String!) {
	agentUpcomingRenewals(agentId: $agentId) {
		totalTermValue
		months {
			date
			numTotalContracts
			termValue
		}
	}
}
`;

export const getCompanyUpcomingRenewals = gql`
query GetCompanyUpcomingRenewals($companyId: String!) {
	companyUpcomingRenewals(companyId: $companyId) {
		totalTermValue
		months {
			date
			numTotalContracts
			termValue
		}
	}
}
`;

export const getForecastedPayablesSummary = gql`
query GetForecastedPayablesSummary($criteria: ForecastedSummaryInput) {
	forecastedPayablesSummary(criteria: $criteria) {
		months {
			date
			payment
		}
	}
}
`;

export const getForecastedReceivablesSummary = gql`
query GetForecastedReceivablesSummary($criteria: ForecastedSummaryInput) {
	forecastedReceivablesSummary(criteria: $criteria) {
		months {
			date
			payment
		}
	}
}
`;

export const getActualPayablesSummary = gql`
query GetActualPayablesSummary($criteria: ActualSummaryInput) {
	actualPayablesSummary(criteria: $criteria) {
		months {
			date
			payment
		}
	}
}
`;

export const getActualReceivablesSummary = gql`
query GetActualReceivablesSummary($criteria: ActualSummaryInput) {
	actualReceivablesSummary(criteria: $criteria) {
		months {
			date
			payment
		}
	}
}
`;

export const getUnpaidPayables = gql`
query GetUnpaidPayables($criteria: UnpaidPayablesInput!, $sort: String, $max: Int, $offset: Int) {
	unpaidPayables(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			result {
				id
				commissionIds
				commissionIdRates
				accountAdjustmentIds
				agentName
				dbaName
				contractId
				contractNum
				utilityAccountNum
				confirmedAnnualUsage
				date
				payablePlan
				amount
				commissionTypeId
				commissionTypeName
				contractLocationId
				cycleStartDate
				cycleEndDate
				margin
				usage
				rate
				units
				symbol
				description
				commissionPayableIssueTypeId
				payableIssues {
					id
					name
					description
					payableIssueTypeId
					payableIssueType {
						id
						name
						description
					}
				}
				payableIssueNames
				commissionPayableStatusTypeId
				commissionPayableStatusTypeName
				addressLine
				commissionPayableId
				receivableUsage
				accountUsage
				ruleId
				ruleTierId
				accountUsageCheckId
				receivableUsageCheckId
				issues
			}
			filterOptions {
				agents {
					agentId
					agentName
				}
			}
			stats {
				totalAmount
			}
		}
		total
		start
		end
	}
}
`;

export const getCustomerEngagementEmailsPageData = gql`
query GetCustomerEngagementEmailsPageData($criteria: ViewEmailPreferenceInput) {
	viewEmailPreferences(criteria: $criteria) {
		message {
			id
			name
			description
			slug
			sortOrder
			emailPreferenceSettingId
			emailCategoryId
			emailCategoryName
			emailCategoryDescription
			emailPreferenceTypeId
			emailPreferenceType
			emailPreferenceSettingTargetDateOptionId
			targetDateTypeOption
			sendDelayDirection
			sendDelay
			sendFrequency
			holidayTypeId
			holidayType
			agentId
			hasSections
		}
	}
}
`;

export const getCustomerEngagementEmailsEditPageData = gql`
query GetCustomerEngagementEmailsEditPageData($id: Int!, $agentEmailPreferenceSettingCriteria: AgentEmailPreferenceSettingInput) {
	emailPreference(id: $id) {
		id
		name
		agentEmailPreferenceSetting(criteria: $agentEmailPreferenceSettingCriteria) {
			id
			emailPreferenceTypeId
			emailPreferenceSettingTargetDateOptionId
			sendDelay
			sendFrequency
			holidayTypeId
			agentId
		}
		emailSections {
			id
			emailSectionTypeId
			sortOrder
			displayName
			helpText
			isOptional
			emailSectionGroupId
			defaultAgentEmailSection {
				id
				content
				attachmentId
				attachment {
					attachmentId
					originalName
				}
			}
			emailSectionVariables {
				displayName
			}
			emailSectionGroup {
				displayName
				sortOrder
			}
		}
	}
	holidayTypes {
		message {
			id
			name
		}
	}
}
`;

export const getCustomerEngagementEmailsEditData = gql`
query GetCustomerEngagementEmailsEditData($emailSectionId: IntString!, $agentId: String) {
	agentEmailSections(criteria: { emailSectionId: $emailSectionId, agentId: $agentId }) {
		message {
			id
			content
			attachmentId
			emailSectionId
			attachment {
				attachmentId
				originalName
			}
		}
	}
}
`;

export const getCustomerEngagementEmailPreview = gql`
query GetCustomerEngagementEmailPreview($emailPreferenceId: Int!, $agentEmailSections: [AgentEmailSectionInput!]!) {
	emailPreview(emailPreferenceId: $emailPreferenceId, agentEmailSections: $agentEmailSections)
}
`;

export const sendCustomerEngagementEmailPreview = gql`
query SendCustomerEngagementEmailPreview($email: String!, $emailPreferenceId: Int!, $agentEmailSections: [AgentEmailSectionInput!]!) {
	sendEmailPreview(email: $email, emailPreferenceId: $emailPreferenceId, agentEmailSections: $agentEmailSections)
}
`;

export const getEmailPreferenceAddEditPageData = gql`
query getEmailPreferenceAddEditPageData {
	emailPreferenceTypes {
		message {
			id
			name
		}
	}
	emailPreferenceSettingTargetDateOptions {
		message {
			id
			emailPreferenceTypeId
			name
		}
	}
	holidayTypes {
		message {
			id
			name
		}
	}
	emailCategories {
		message {
			id
			name
		}
	}
}
`;

export const getViewEmailPreferenceData = gql`
query getViewEmailPreferenceData($id: Int) {
	viewEmailPreferences(criteria: { id: $id }) {
		message {
			id
			name
			description
			slug
			emailPreferenceSettingId
			emailPreferenceTypeId
			emailPreferenceType
			emailPreferenceSettingTargetDateOptionId
			targetDateTypeOption
			sendDelayDirection
			sendDelay
			sendFrequency
			holidayTypeId
			holidayType
		}
	}
}
`;

export const getCustomerEngagementEmailsAgentEmailPreferences = gql`
query GetCustomerEngagementEmailsAgentEmailPreferences($agentId: String!) {
	agent(agentId: $agentId) {
		emailPreferences {
			id
		}
	}
}
`;

export const getEmailPreferenceSettingTargetDateOptions = gql`
query GetEmailPreferenceSettingTargetDateOptions($criteria: EmailPreferenceSettingTargetDateOptionInput) {
	emailPreferenceSettingTargetDateOptions(criteria: $criteria, sort: "sortOrder") {
		message {
			id
			name
			sendDelayDirection
		}
	}
}
`;

export const getComplianceMonitorDashboardContractsData = gql`
query GetComplianceMonitorDashboardContractsData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
	commissionPayPlanTypes(sort: "id") {
		message {
			id
			description
		}
	}
}
`;

export const getViewComplianceMonitorContracts = gql`
query GetViewComplianceMonitorContracts($criteria: ViewComplianceMonitorContractInput, $sort: String, $max: Int, $offset: Int) {
	viewComplianceMonitorContracts(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			contractId
			contractNum
			dbaName
			agentId
			status
			displayStatus
			serviceTypeId
			commodity
			effectiveDate
			term
			margin
			units
			commissionPayPlanTypeId
			commissionPayPlanTypeName
			termValue
			riskScore
			riskScoreDisplay
			isoCode
			totalCommissionPaid
		}
		total
		start
		end
	}
}
`;

export const exportComplianceMonitorContracts = gql`
query ExportComplianceMonitorContracts($criteria: ViewComplianceMonitorContractInput, $sort: String) {
	exportComplianceMonitorContracts(criteria: $criteria, sort: $sort) {
		response
	}
}
`;

export const getNoteTypePageData = gql`
query GetNoteTypePageData {
	noteTypeCategories {
		message {
			id
			name
		}
	}
}
`;

export const getComplianceMonitorDashboardAgentsData = gql`
query GetComplianceMonitorDashboardAgentsData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	serviceTypes(criteria: { isActive: true }, sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
}
`;

export const getComplianceMonitorContractDetailsModalData = gql`
query GetComplianceMonitorContractDetailsModalData($contractId: String!, $agentId: String, $noteTypeCategoryId: Int) {
	complianceMonitorAgents(criteria: { agentId: $agentId }) {
		message {
			name
			dropRatio
			isOnHold
		}
	}
	notes(criteria: { contractId: $contractId, noteTypeCategoryId: $noteTypeCategoryId }) {
		message {
			subject
			body
			addDate
		}
	}
	noteTypes(criteria: { noteTypeCategoryId: $noteTypeCategoryId }, sort: "name") {
		message {
			noteTypeId
			name
			noteTypeCategoryId
		}
	}
}
`;

export const getComplianceMonitorAgents = gql`
query GetComplianceMonitorAgents($criteria: ComplianceMonitorAgentInput, $sort: String, $max: Int, $offset: Int) {
	complianceMonitorAgents(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			agentId
			name
			isOnHold
			quoted
			quotedPercentage
			signed
			signedPercentage
			confirmed
			confirmedPercentage
			dropped
			droppedPercentage
			rejected
			rejectedPercentage
		}
		total
		start
		end
	}
}
`;

export const exportComplianceMonitorAgents = gql`
query ExportComplianceMonitorAgents($criteria: ComplianceMonitorAgentInput, $sort: String) {
	exportComplianceMonitorAgents(criteria: $criteria, sort: $sort) {
		response
	}
}
`;

export const getComplianceMonitorAgentDetailsModalData = gql`
query GetComplianceMonitorAgentDetailsModalData($agentId: String!) {
	complianceMonitorAgentDetails(agentId: $agentId) {
		termValue {
			confirmedTermValue
			flowedTermValue
			droppedTermValue
			rejectedTermValue
			reversibleCommission
			futureCommissionAmount
			totalCommissionPaid
			totalCommissionClawedBack
		}
		averages {
			commodity
			units
			margin
			term
			paymentType
			lagTime
		}
	}
}
`;

export const getCommissionsPaymentPageData = gql`
query GetCommissionsPaymentPageData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	commissionPayableStatusTypes {
		message {
			id
			name
		}
	}
	payableIssueTypes {
		message {
			id
			name
		}
	}
	commissionTypes {
		message {
			id
			name
		}
	}
	commissionPayPlanTypes {
		message {
			id
			description
		}
	}
	pendingCommissionIssueSubjectTypes(criteria: { isActive: true, isPayableWarning: true }, sort: "name") {
		message {
			id
			name
		}
	}
}
`;

export const getCommissionPayablesPageData = gql`
query GetCommissionPayablesPageData {
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
	commissionTypes {
		message {
			id
			name
		}
	}
	commissionPayPlanTypes {
		message {
			id
			description
		}
	}
	commissionPayableStatusTypes {
		message {
			id
			name
		}
	}
}
`;

export const getBankAccountAddEditFormData = gql`
query GetBankAccountAddEditFormData($agentId: String) {
	bankAccounts(criteria: { agentId: $agentId }) {
		message {
			id
			bankName
			routingNum
			accountNum
			swiftCode
			bankAccountTypeId
			bankAccountVendorTypeId
			isInvalid
			bankAccountType {
				name
			}
			bankAccountVendorType {
				name
			}
		}
	}
	bankAccountTypes {
		message {
			id
			name
		}
	}
	bankAccountVendorTypes {
		message {
			id
			name
		}
	}
}
`;

export const getEmailCriteriaTypeEditData = gql`
query GetEmailCriteriaTypeEditData($id: Int!) {
	emailCriteriaType(id: $id) {
		id
		name
		description
		sortOrder
	}
}
`;

export const getEmailCriteriaTypesListData = gql`
query GetEmailCriteriaTypesListData($sort: String) {
	emailCriteriaTypes(sort: $sort) {
		message {
			id
			name
			description
		}
	}
}
`;

export const getAgentEngagementOverviewPageData = gql`
query GetAgentEngagementOverviewPageData($criteria: ViewAgentEngagementEventInput) {
	agentEngagementEventCategories(criteria: { isActive: true }, sort: "name") {
		message {
			id
			name
			imageIconPrefix
			imageIcon
		}
	}
	viewAgentEngagementEvents(criteria: $criteria) {
		message {
			id
			contractId
			contractNum
			ticketId
			agentId
			agentEngagementEventCategoryId
			addDate
			agentName
			agentAddDate
			isEnterprise
			parentId
			parentName
			parentContactName
			parentPhone
			parentEmail
			eventDetail
			followUpDate
			isActivated
			agentTotalTermValue
			lastConfirmationDate
			lastDroppedDate
			contractStatus
			isResidential
			numContracts
			contractTotalTermValue
			hasAction
		}
	}
}
`;

export const getAgentEngagementEventDetailModalData = gql`
query GetAgentEngagementEventDetailModalData($id: Int!) {
	viewAgentEngagementEvent(id: $id) {
		id
		agentEngagementEventCategoryId
		agentEngagementEventStatusId
		contractId
		contractNum
		ticketId
		addDate
		agentId
		agentName
		agentAddDate
		parentId
		parentName
		parentContactName
		parentPhone
		parentEmail
		eventDetail
		followUpDate
		isActivated
		agentTotalTermValue
		lastConfirmationDate
		lastDroppedDate
		noteTypes {
			noteTypeId
			isSuggested
			noteType {
				noteTypeId
				name
				description
				details
				noteTypeCategoryId
			}
		}
		agentEngagementEventContracts(sort: "termValue desc") {
			contractId
			termValue
			unpurchasedQuantity
			contract {
				contractNum
				expirationDate
				annualUsage
				serviceTypeId
				latestAuctionCompleteDate
				units
				agent {
					agentId
					name
				}
				rfqSession {
					endDate2
				}
			}
		}
	}
}
`;

export const getAgentEngagementEventDetailModalActionsData = gql`
query GetAgentEngagementEventDetailModalActionsData($noteTypeCategoryId: Int, $agentId: String) {
	viewAgentEngagementEventActions(criteria: { noteTypeCategoryId: $noteTypeCategoryId, agentId: $agentId }) {
		message {
			id
			message
			details
			subTitle
			noteTypeId
			addDate
			agentEngagementEventId
			agentEngagementEventAddDate
			agentEngagementEventCategoryName
			noteTypeName
			noteTypeImageIconPrefix
			noteTypeImageIcon
		}
	}
}
`;

export const getAgentEngagementEventArchivePageData = gql`
	query GetAgentEngagementEventArchivePageData {
	agentEngagementEventCategories(sort: "sortOrder") {
			message {
			id
			name
		}
	}
}
`;

export const getAgentEngagementEventArchiveData = gql`
	query GetAgentEngagementEventArchiveData($criteria: ViewAgentEngagementEventInput, $sort: String, $max: Int, $offset: Int) {
	viewAgentEngagementEvents(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
			message {
			id
			agentEngagementEventCategoryName
			addDate
			agentId
			agentName
			parentContactName
			parentPhone
			parentEmail
		}
		total
	}
}
`;

export const getAgentEngagementDashboardEmailsPageData = gql`
	query GetAgentEngagementDashboardEmailsPageData($criteria: ViewEmailPreferenceInput) {
	viewEmailPreferences(criteria: $criteria) {
			message {
			id
			name
			description
			slug
			sortOrder
			emailPreferenceSettingId
			emailCategoryId
			emailCategoryName
			emailCategoryDescription
			emailPreferenceTypeId
			emailPreferenceType
			emailPreferenceSettingTargetDateOptionId
			targetDateTypeOption
			sendDelayDirection
			sendDelay
			sendFrequency
			holidayTypeId
			holidayType
			agentId
			hasSections
		}
	}
}
`;

export const getAgentEngagementEmailsEditPageData = gql`
query GetAgentEngagementEmailsEditPageData($id: Int!, $agentEmailPreferenceSettingCriteria: AgentEmailPreferenceSettingInput) {
	emailPreference(id: $id) {
		id
		name
		agentEmailPreferenceSetting(criteria: $agentEmailPreferenceSettingCriteria) {
			id
			emailPreferenceTypeId
			emailPreferenceSettingTargetDateOptionId
			sendDelay
			sendFrequency
			holidayTypeId
			agentId
		}
		emailSections {
			id
			emailSectionTypeId
			sortOrder
			displayName
			helpText
			isOptional
			emailSectionGroupId
			defaultAgentEmailSection {
				id
				content
				attachmentId
				attachment {
					attachmentId
					originalName
				}
			}
			emailSectionVariables {
				displayName
			}
			emailSectionGroup {
				displayName
				sortOrder
			}
		}
	}
	holidayTypes {
		message {
			id
			name
		}
	}
}
`;

export const getAgentEngagementEmailsEditData = gql`
query GetAgentEngagementEmailsEditData($emailSectionId: IntString!, $agentId: String) {
	agentEmailSections(criteria: { emailSectionId: $emailSectionId, agentId: $agentId }) {
		message {
			id
			content
			attachmentId
			emailSectionId
			attachment {
				attachmentId
				originalName
			}
		}
	}
}
`;

export const getAgentEngagementEmailPreview = gql`
query GetAgentEngagementEmailPreview($toAgentId: String, $emailPreferenceId: Int!, $agentEmailSections: [AgentEmailSectionInput!]!) {
	emailPreview(toAgentId: $toAgentId, emailPreferenceId: $emailPreferenceId, agentEmailSections: $agentEmailSections)
}
`;

export const sendAgentEngagementEmailPreview = gql`
query SendAgentEngagementEmailPreview(
	$email: String!, $toAgentId: String, $emailPreferenceId: Int!, $agentEmailSections: [AgentEmailSectionInput!]!
) {
	sendEmailPreview(email: $email, toAgentId: $toAgentId, emailPreferenceId: $emailPreferenceId, agentEmailSections: $agentEmailSections)
}
`;

export const getAgentEngagementEmailsAgentEmailPreferences = gql`
query GetAgentEngagementEmailsAgentEmailPreferences($agentId: String!) {
	agent(agentId: $agentId) {
		emailPreferences {
			id
		}
	}
}
`;

export const getAdminFlowChecksData = gql`
query GetAdminFlowChecksData {
	suppliers(criteria: { hasFlowCheckMapping: true }, sort: "name") {
		message {
			supplierId
			name
		}
	}
}
`;


export const listPendingFlowChecksQueue = gql`
query ListPendingFlowChecksQueue($criteria: PendingFlowCheckInput) {
	pendingFlowChecks(criteria: $criteria) {
		message {
			pendingFlowCheckStatusId
		}
	}
}
`;

export const listPendingFlowChecks = gql`
${fragments.VIEW_PENDING_FLOW_CHECK_FIELDS}
query ListPendingFlowChecks($criteria: ViewPendingFlowCheckInput, $sort: String, $max: Int, $offset: Int) {
	viewPendingFlowChecks(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			...ViewPendingFlowCheckFields
		}
		total
		start
		end
	}
}
`;

export const getReferralExchangeDashboardPageData = gql`
query GetReferralExchangeDashboardPageData {
	referralServices(sort: "name") {
		message {
			id
			name
		}
	}
	states(criteria: { countryId: 230 }, sort: "stateShort") {
		message {
			stateId
			stateShort
			stateLong
		}
	}
}
`;

export const getReferralExchangeDashboardAgentReferralServices = gql`
${fragments.CORE_VIEW_AGENT_REFERRAL_SERVICE_FIELDS}
query GetReferralExchangeDashboardAgentReferralServices($criteria: ViewAgentReferralServiceInput, $sort: String) {
	viewAgentReferralServices(criteria: $criteria, sort: $sort) {
		message {
			...CoreViewAgentReferralServiceFields
		}
		total
		start
		end
	}
}
`;

export const getReferralExchangeDetailsPageData = gql`
${fragments.CORE_VIEW_AGENT_REFERRAL_SERVICE_FIELDS}
query GetReferralExchangeDetailsPageData($id: Int!) {
	viewAgentReferralService(id: $id) {
		...CoreViewAgentReferralServiceFields
		otherAgentReferralServices {
			...CoreViewAgentReferralServiceFields
		}
	}
}
`;

export const sendAgentReferralServiceRequest = gql`
query SendAgentReferralServiceRequest($agentReferralServiceId: Int!, $criteria: AgentReferralServiceRequestInput!) {
	sendAgentReferralServiceRequest(agentReferralServiceId: $agentReferralServiceId, criteria: $criteria) {
		response
	}
}
`;

export const getReactivationTicket = gql`
query GetReactivationTicket {
	reactivationTicket {
		id
	}
}
`;

export const getUtilityMarketSettingPageData = gql`
query GetUtilityMarketSettingPageData($utilityId: String!) {
	utility(utilityId: $utilityId) {
		utilityStates {
			state {
				stateId
				stateLong
			}
		}
		utilityServiceTypes {
			serviceType {
				serviceTypeId
				name
			}
		}
	}
}
`;

export const getUtilityMarketSettingEditPageData = gql`
query GetUtilityMarketSettingEditPageData($criteria: UtilityMarketSettingInput) {
	utilityMarketSettings(criteria: $criteria) {
		message {
			id
			utilityId
			stateId
			serviceTypeId
			hasEcl
			requireLoaForPricingSession
			requireLoeForPricingSession
			requireHudForPricingSession
			requireBillForPricingSession
			requireLoaOrBillOrManyLocations
			isActive
		}
	}
}
`;

export const getUtilityEditPageData = gql`
query GetUtilityEditPageData($utilityId: String!) {
	utility(utilityId: $utilityId) {
		name
	}
}
`;

export const getUtilityAuctionIsoData = gql`
query GetUtilityAuctionIsoData($utilityId: String!) {
	utility(utilityId: $utilityId) {
		isoId
	}
}
`;

export const getUtilityZoneEditData = gql`
query GetUtilityZoneEditData($utilityId: String!) {
	utility(utilityId: $utilityId) {
		zones {
			name
			description
			isActive
			stateId
			utilityId
			serviceTypeId
		}
	}
}
`;

export const getUtilityZoneEditPageData = gql`
query GetUtilityZoneEditPageData {
	states(sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	serviceTypes(sort: "name") {
		message {
			serviceTypeId
			name
		}
	}
}
`;

export const getContractSendSignedContractModalData = gql`
query GetContractSendSignedContractModalData($contractId: String!) {
	contract(contractId: $contractId) {
		contractId
		contractNum
		serviceTypeId
		stateId
		effectiveDate
		annualUsage
		productId
		term
		greenPercentage
		supplierId
		units
		margin
		bufferMargin
		commissionSplitBufferMargin
		matrixAgentBufferMargin
		submissionStatus
		status
		isNewBrokerAuction
		rateAddDate
		supplierCommissionRule {
			marginSplit
		}
		serviceType {
			multiplier
		}
		locations {
			utilityId
			rateClass
			zone
		}
		attachments {
			attachmentTypeId
			pageCount
			esignDocument {
				document {
					attachment {
						pageCount
					}
				}
			}
		}
	}
}
`;

export const getRateMatrixLoaderEditCompanySettingsPageData = gql`
query GetRateMatrixLoaderEditCompanySettingsPageData($rateMatrixLoaderId: Int!) {
	emailCriterias(criteria: { emailCriteriaTypeId: 4 }, sort: "name") {
		message {
			emailCriteriaId
			name
			subject
			emailAccount {
				companyId
			}
		}
	}
	rateMatrixLoader(id: $rateMatrixLoaderId) {
		isAPI
		showUrl
		showUsername
		showPassword
		showClientId
		showClientSecret
		showAccessKeyId
		showToken
		showPromoCode
		showAgentId
		showSystemId
		showInterfaceId
		showPaymentUrl
		showRateUrl
		showAccessTokenUrl
		showBrokerCode
		showEncryptionKey
		showEncryptionIV
	}
	companies(sort: "name") {
		message {
			companyId
			name
		}
	}
}
`;

export const getCompanyRateMatrixLoaderEditData = gql`
query GetCompanyRateMatrixLoaderEditData($rateMatrixLoaderId: Int, $companyId: String) {
	companyRateMatrixLoaders(criteria: { rateMatrixLoaderId: $rateMatrixLoaderId, companyId: $companyId }) {
		message {
			id
			rateMatrixLoaderId
			companyId
			isActive
			activateAlerts
			modifiedDate
			emailCriteriaId
			emailAttachmentName
			emailAttachmentSheetName
			url
			username
			password
			clientId
			clientSecret
			accessKeyId
			token
			promoCode
			agentId
			systemId
			interfaceId
			paymentUrl
			rateUrl
			accessTokenUrl
			brokerCode
			encryptionKey
			encryptionIV
		}
	}
}
`;

export const getRateMatrixLoaderGeneralData = gql`
query GetRateMatrixLoaderGeneralData($id: Int!) {
	rateMatrixLoader(id: $id) {
		id
		assemblyName
		description
		isAPI
		showUrl
		showUsername
		showPassword
		showClientId
		showClientSecret
		showAccessKeyId
		showToken
		showPromoCode
		showAgentId
		showSystemId
		showInterfaceId
		showPaymentUrl
		showRateUrl
		showAccessTokenUrl
		showBrokerCode
		showEncryptionKey
		showEncryptionIV
	}
}
`;

export const getLeadDashboardDataSub = gql`
${fragments.CORE_VIEW_LEAD_FIELDS}
query GetLeadDashboardDataSub($criteria: ViewLeadInput, $sort: String, $max: Int, $offset: Int) {
	viewLeads(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			...CoreViewLeadFields
		}
		total
	}
}
`;

export const getLeadDuplicatesDashboardDataSub = gql`
${fragments.CORE_VIEW_LEAD_FIELDS}
query GetLeadDuplicatesDashboardDataSub($criteria: ViewLeadDuplicatesDashboardInput, $sort: String, $max: Int, $offset: Int) {
	viewLeadDuplicatesDashboard(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			...CoreViewLeadFields
		}
		total
	}
}
`;

export const getBrokerLeadDashboardPageData = gql`
query GetLeadDashboardPageData($leadTypeId: Int, $userId: String, $gridId: String) {
	states(criteria: { countryId: 230 }, sort: "stateLong") {
		message {
			stateId
			stateLong
			stateShort
		}
	}
	leadCategories(sort: "name") {
		message {
			id
			name
		}
	}
	agents(criteria: { isInHouseIndirectSale: true, isActive: true }, sort: "name") {
		message {
			agentId
			name
			parentId
			lightBackgroundsLogoId
			mailServerId
			parent {
				lightBackgroundsLogoId
				mailServerId
			}
		}
	}
	leadStatuses(criteria: { leadTypeId: $leadTypeId }, sort: "sortOrder") {
		message {
			id
			name
			leadStatusCategoryId
			cssClass
			details
			leadStatusReasonTypes(sort: "sortOrder") {
				id
				name
				isNoteRequired
			}
		}
	}
	leadStatusReasonTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	leadStatusCategories(criteria: { leadTypeId: $leadTypeId }, sort: "sortOrder") {
		message {
			id
			name
			canAddTo
			isActive
			leadStatuses {
				id
				details
			}
		}
	}
	userGridConfigs(criteria: { isGlobal: false, gridId: $gridId, userId: $userId }) {
		message {
			id
			name
			gridId
			isGlobal
			isDefault
			states
			sortOrder
		}
	}
}
`;

export const getLeadDashboardPageData = gql`
query GetLeadDashboardPageData($agentId: String!, $leadTypeId: Int, $userId: String, $gridId: String) {
	states(criteria: { countryId: 230 }, sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	agent(agentId: $agentId) {
		agentId
		name
		lightBackgroundsLogoId
		mailServerId
		parent {
			lightBackgroundsLogoId
			mailServerId
		}
		subAgents(sort: "name") {
			agentId
			name
			lightBackgroundsLogoId
			mailServerId
			parent {
				lightBackgroundsLogoId
				mailServerId
			}
		}
	}
	leadStatuses(criteria: { leadTypeId: $leadTypeId }, sort: "sortOrder") {
		message {
			id
			name
			leadStatusCategoryId
			cssClass
			details
			leadStatusReasonTypes(sort: "sortOrder") {
				id
				name
				isNoteRequired
			}
		}
	}
	leadStatusReasonTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	leadStatusCategories(criteria: { leadTypeId: $leadTypeId }, sort: "sortOrder") {
		message {
			id
			name
			canAddTo
			isActive
			leadStatuses {
				id
				details
			}
		}
	}
	userGridConfigs(criteria: { isGlobal: false, gridId: $gridId, userId: $userId }) {
		message {
			id
			name
			gridId
			isGlobal
			isDefault
			states
			sortOrder
		}
	}
}
`;

export const getLeadDuplicatesDashboardPageData = gql`
query GetLeadDuplicatesDashboardPageData($agentId: String!, $leadTypeId: Int) {
	states(criteria: { countryId: 230 }, sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	agent(agentId: $agentId) {
		agentId
		name
		lightBackgroundsLogoId
		mailServerId
		parent {
			lightBackgroundsLogoId
			mailServerId
		}
		subAgents(sort: "name") {
			agentId
			name
			lightBackgroundsLogoId
			mailServerId
			parent {
				lightBackgroundsLogoId
				mailServerId
			}
		}
	}
	leadStatuses(criteria: { leadTypeId: $leadTypeId }, sort: "sortOrder") {
		message {
			id
			name
			leadStatusCategoryId
			cssClass
			details
			leadStatusReasonTypes(sort: "sortOrder") {
				id
				name
				isNoteRequired
			}
		}
	}
	leadStatusReasonTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	leadStatusCategories(criteria: { leadTypeId: $leadTypeId }, sort: "sortOrder") {
		message {
			id
			name
			canAddTo
			isActive
			leadStatuses {
				id
				details
			}
		}
	}
} `;

export const getBrokerLeadDuplicatesDashboardPageData = gql`
query GetBrokerLeadDuplicatesDashboardPageData($leadTypeId: Int) {
	states(criteria: { countryId: 230 }, sort: "stateLong") {
		message {
			stateId
			stateLong
		}
	}
	leadCategories(sort: "name") {
		message {
			id
			name
		}
	}
	agents(criteria: { isInHouseIndirectSale: true, isActive: true }, sort: "name") {
		message {
			agentId
			name
			lightBackgroundsLogoId
			mailServerId
			parentId
			parent {
				lightBackgroundsLogoId
				mailServerId
			}
		}
	}
	leadStatuses(criteria: { leadTypeId: $leadTypeId }, sort: "sortOrder") {
		message {
			id
			name
			leadStatusCategoryId
			cssClass
			details
			leadStatusReasonTypes(sort: "sortOrder") {
				id
				name
				isNoteRequired
			}
		}
	}
	leadStatusReasonTypes(sort: "sortOrder") {
		message {
			id
			name
		}
	}
	leadStatusCategories(criteria: { leadTypeId: $leadTypeId }, sort: "sortOrder") {
		message {
			id
			name
			canAddTo
			isActive
			leadStatuses {
				id
				details
			}
		}
	}
} `;

export const getLeadDashboardStatsPageData = gql`
query GetLeadDashboardStatsPageData($leadTypeId: Int) {
	leadStats(criteria: { leadTypeId: $leadTypeId }) {
		duplicatesTotal
		iceBoxedReminderTotal
	}
}
`;

export const getLeadAddEditModalData = gql`
query GetLeadAddEditModalData($noteTypeCategoryId: Int) {
	noteTypes(criteria: { noteTypeCategoryId: $noteTypeCategoryId }, sort: "sortOrder") {
		message {
			noteTypeId
			name
			description
			noteTypeCategoryId
		}
	}
	leadBusinessTypes(sort: "name") {
		message {
			id
			name
		}
	}
	leadCategories(sort: "name") {
		message {
			id
			name
		}
	}
	countries(criteria: { isActive: true }, sort: "name") {
		message {
			id
			name
			code
		}
	}
}
`;

export const getLeadAddEditModalLeadData = gql`
query GetLeadAddEditModalLeadData($leadId: IntString) {
	leadContacts(criteria: { leadId: $leadId }, sort: "isPrimary desc") {
		message {
			id
			fName
			lName
			mobile
			mobileCountryId
			phone
			phoneCountryId
			phoneExt
			email
			isPrimary
			jobTitle
		}
	}
	leadDuplicates(criteria: { duplicateLeadId: $leadId, isDismissed: false }) {
		message {
			id
			leadId
			duplicateLeadId
		}
	}
}
`;

export const getLeadAddEditModalLeadReminderData = gql`
query GetLeadAddEditModalLeadReminderData($leadId: IntString) {
	leadReminders(criteria: { leadId: $leadId, isDismissed: false }) {
		message {
			id
			message
			date
			addDate
		}
	}
}
`;

export const getLeadAddEditModalLeadEmailCampaignData = gql`
query GetLeadAddEditModalLeadEmailCampaignData($leadId: IntString) {
	leadEmailCampaigns(criteria: { leadId: $leadId, isDismissed: false, hasUpcomingSchedule: true }) {
		message {
			leadEmailCampaignSchedules {
				date
			}
		}
	}
}
`;

export const getLeadDuplicatesDashboardLeadDuplicates = gql`
query GetLeadDuplicatesDashboardLeadDuplicates($criteria: LeadDuplicateInput) {
	leadDuplicates(criteria: $criteria) {
		message {
			id
			leadId
			duplicateLeadId
		}
	}
}
`;

export const getLeadAddEditModalLeadActionsDataSub = gql`
query GetLeadAddEditModalLeadActionsDataSub($criteria: ViewLeadActionInput, $sort: String, $max: Int, $offset: Int) {
	viewLeadActions(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			id
			message
			details
			date
			noteTypeId
			noteTypeName
			noteTypeDescription
			noteTypeImageIconPrefix
			noteTypeImageIcon
			addDate
			leadCalendarMeeting {
				recipients
			}
			leadEmailCampaign {
				isDismissed
				leadEmailCampaignSchedules {
					date
				}
			}
		}
		total
	}
}
`;

export const getLeadDuplicatesModalData = gql`
query getLeadDuplicatesModalData($id: Int!) {
	leadDuplicate(id: $id) {
		systemLead {
			id
			dbaName
			address1
			address2
			city
			stateShort
			zipCode
			leadBusinessTypeName
			leadCategoryName
			leadSource
			website
			linkedInUrl
			leadContacts {
				isPrimary
				fName
				lName
				mobile
				mobileCountryId
				phone
				phoneCountryId
				phoneExt
				email
			}
		}
		duplicateLead {
			id
			dbaName
			address1
			address2
			city
			stateShort
			zipCode
			leadBusinessTypeName
			leadCategoryName
			leadSource
			website
			linkedInUrl
			leadContacts {
				isPrimary
				fName
				lName
				mobile
				mobileCountryId
				phone
				phoneCountryId
				phoneExt
				email
			}
		}
	}
}
`;

export const getLeadDuplicatesDashboardDetailData = gql`
${fragments.CORE_VIEW_LEAD_FIELDS}
query GetLeadDuplicatesDashboardDetailData($id: IntString) {
	leadDuplicates(criteria: { leadId: $id, isDismissed: false }) {
		message {
			duplicateLead {
				...CoreViewLeadFields
			}
		}
	}
}
`;

export const getLeadActivityReportPageData = gql`
query GetLeadActivityReportPageData($reportId: String!) {
	report(reportId: $reportId) {
		name
	}
}
`;

export const getLeadActivityReportTotalsData = gql`
query GetLeadActivityReportTotalsData($reportId: String!, $leadCriteria: LeadActivityReportTotalsInput, $agentCriteria: AgentInput) {
	leadActivityReportTotals(reportId: $reportId, criteria: $leadCriteria) {
		total
		numActive
		numActiveRatio
		numSigned
		numSignedRatio
		numEnrolled
		numEnrolledRatio
		numUnqualified
		numUnqualifiedRatio
	}
	agents(criteria: $agentCriteria) {
		message {
			agentId
			name
			subAgents {
				agentId
				name
			}
		}
	}
}
`;

export const getLeadActivityReportData = gql`
query GetLeadActivityReportData($reportId: String!, $criteria: LeadActivityReportInput) {
	leadActivityReport(reportId: $reportId, criteria: $criteria) {
		leadStatusName
		leadStatusCssClass
		total
		ratio
	}
}
`;

export const getLayeredPurchasingDashboardKpiData = gql`
query GetLayeredPurchasingDashboardKpiData {
	purchasingLayerDashboardKpiStats {
		year
		serviceTypeId
		hedgedPercentage
	}
}
`;

export const getLayeredPurchasingDashboardContractsPageData = gql`
query GetLayeredPurchasingDashboardContractsPageData($companyId: String!) {
	viewPurchasingLayerContractsFilterOptions(criteria: { companyId: $companyId }) {
		dbaNames
		suppliers {
			supplierId
			name
			hasTriggered
		}
		serviceTypes {
			serviceTypeId
			name
		}
	}
}
`;

export const getLayeredPurchasingDashboardContractsData = gql`
query GetLayeredPurchasingDashboardContractsData($criteria: ViewPurchasingLayerContractInput, $sort: String, $max: Int, $offset: Int) {
	viewPurchasingLayerContracts(criteria: $criteria, sort: $sort, max: $max, offset: $offset) {
		message {
			contractId
			contractNum
			customerId
			companyId
			dbaName
			serviceTypeId
			commodity
			supplierId
			supplierName
			annualUsage
			units
			percentageHedgedUnfulfilled
			alertTriggered
			targetPriceTriggered
		}
		total
	}
}
`;

export const getHedgePriceRequestContractsBySupplier = gql`
query GetLayeredPurchasingDashboardContractsData($supplierId: String) {
	viewPurchasingLayerContracts(criteria: { supplierId: $supplierId, hasTriggered: true }) {
		message {
			contractId
			contractNum
			purchasingLayerId
			companyId
			supplierId
			supplierName
			dbaName
			commodity
			annualUsage
			units
			effectiveDate
			expirationDate
			percentageHedgedUnfulfilled
		}
	}
}
`;

export const getLayeredPurchasingHedgePriceRequestPreview = gql`
query GetLayeredPurchasingHedgePriceRequestPreview($criteria: HedgePriceRequestInput) {
	hedgePriceRequestPreview(criteria: $criteria)
}
`;

export const sendHedgePriceRequest = gql`
query SendHedgePriceRequest($criteria: HedgePriceRequestInput) {
	sendHedgePriceRequest(criteria: $criteria) {
		response
	}
}
`;

export const getLeadEmailCampaignSelectModalData = gql`
query GetLeadEmailCampaignSelectModalData($criteria: EmailCampaignInput) {
	emailCampaigns(criteria: $criteria, sort: "name") {
		message {
			id
			name
			description
			emailCampaignTypeId
			emailPreferences(sort: "sortOrder") {
				id
			}
		}
	}
}
`;

export const getLeadEmailCampaignEmailPreview = gql`
query GetLeadEmailCampaignEmailPreview($emailPreferenceId: Int!, $leadEmailSections: [LeadEmailSectionInput]) {
	emailPreview(emailPreferenceId: $emailPreferenceId, leadEmailSections: $leadEmailSections)
}
`;

export const getLeadEmailCampaignCampaignSettingsPageData = gql`
query GetLeadEmailCampaignCampaignSettingsPageData($emailCampaignId: Int, $leadId: IntString!) {
	emailPreferences(criteria: { emailCampaignId: $emailCampaignId }, sort: "sortOrder") {
		message {
			id
			emailSections {
				pugName
				defaultLeadEmailSection {
					content
				}
				leadEmailSection(leadId: $leadId) {
					content
				}
			}
		}
	}
	leadContacts(criteria: { leadId: $leadId }) {
		message {
			fName
			lName
			email
		}
	}
}
`;

export const getLeadEmailCampaignCustomizeEmailsPageData = gql`
query GetLeadEmailCampaignCustomizeEmailsPageData($id: Int!, $leadId: IntString!) {
	emailPreference(id: $id) {
		id
		emailSections {
			id
			emailSectionTypeId
			sortOrder
			displayName
			pugName
			helpText
			isOptional
			emailSectionGroupId
			defaultLeadEmailSection {
				id
				content
				attachmentId
				attachment {
					attachmentId
					originalName
				}
			}
			emailSectionVariables {
				displayName
			}
			emailSectionGroup {
				displayName
				sortOrder
			}
			leadEmailSection(leadId: $leadId) {
				id
				content
				attachmentId
				attachment {
					attachmentId
					originalName
				}
			}
		}
	}
}
`;

export const getLeadEmailCampaignSendingSchedule = gql`
query GetLeadEmailCampaignSendingSchedule($startDate: String!, $numEmails: Int!) {
	leadEmailCampaignSendingSchedule(startDate: $startDate, numEmails: $numEmails)
}
`;

export const getLeadEmailCampaignReviewCampaignPageData = gql`
query GetLeadEmailCampaignReviewCampaignPageData($emailCampaignId: Int, $leadId: IntString!) {
	emailPreferences(criteria: { emailCampaignId: $emailCampaignId }, sort: "sortOrder") {
		message {
			id
			emailSections {
				pugName
				defaultLeadEmailSection {
					content
				}
				leadEmailSection(leadId: $leadId) {
					content
				}
			}
		}
	}
	leadContacts(criteria: { leadId: $leadId }) {
		message {
			fName
			lName
			email
		}
	}
}
`;

export const getLeadEmailCampaign = gql`
query GetLeadEmailCampaign($id: Int!) {
	leadEmailCampaign(id: $id) {
		recipients
		emailCampaignId
		overrideAnchorDate
		emailCampaign {
			emailCampaignTypeId
		}
		leadEmailCampaignSchedules {
			id
			date
			emailPreferenceId
		}
	}
}
`;

export const getTopBannerAlertLearnMoreModalData = gql`
query GetTopBannerAlertLearnMoreModalData {
	countries(criteria: { isActive: true }, sort: "name") {
		message {
			id
			name
			code
		}
	}
}
`;

export const getTinyMceCategories = gql`
query GetTinyMceCategories {
	tinyMceTemplates(criteria: { categoryId: "uncategorized" }, sort: "title") {
		message {
			id
			title
		}
	}
	tinyMceCategories(sort: "title") {
		message {
			id
			title
			items(sort: "title") {
				id
				title
			}
		}
	}
}
`;

export const getTinyMceTemplate = gql`
query GetTinyMceTemplate($id: IntString!) {
	tinyMceTemplate(id: $id) {
		id
		title
		content
	}
}
`;
