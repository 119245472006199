import * as moment from 'moment-timezone';

import { LeadContact } from './lead-contact';
import { State } from './state';

export class Lead {
	public id: number;
	public dbaName: string;
	public address1: string;
	public address2: string;
	public city: string;
	public stateId: string;
	public zipCode: string;
	public website: string;
	public linkedInUrl: string;
	public leadBusinessTypeId: number;
	public priority: number;
	public tierLevel: number;
	public leadStatusId: number;
	public leadStatusReasonTypeId: number;
	public leadStatusReasonNote: string;
	public profileDescription: string;
	public leadTypeId: number;
	public assignedToAgentId: string;
	public followUpDate: Date;
	public followUpNoteId: string;
	public isIceBoxed: boolean;
	public agentId: string; // null for broker lead
	public leadCategoryId: number;
	public leadSource: string;

	public leadContacts: LeadContact[];
	public state: State;

	constructor(lead?: Lead) {
		Object.assign(this, lead);

		this.followUpDate = this.followUpDate
			? new Date(moment(this.followUpDate).format('MMM DD, YYYY 00:00:00 a')) : this.followUpDate;

		this.leadContacts = this.leadContacts ? this.leadContacts.map(l => new LeadContact(l)) : this.leadContacts;
	}

	get hasLeadBasic(): boolean {
		return !!this.dbaName;
	}

	get hasLeadExtended(): boolean {
		return [this.address1, this.city, this.stateId, this.zipCode].every(Boolean);
	}
}
